import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyUser } from '../../api/adapters/auth';
import {
    getPendingRoyalty,
    getWithdrawnRoyalty,
} from '../../api/adapters/royalty';
import { getMyProfile, updateProfilePic } from '../../api/adapters/user';
import { toggleLoader } from '../../redux/actions/loader';
import { updateUser } from '../../redux/actions/user';
import { notify } from '../../utils/common.helper';
import { getCookie } from '../../utils/cookies.helper';
import { copyToClipboard, trimEthereumAddress } from '../../utils/helper';

// COMPONENTS
import FollowerFollowing from '../../components/FollowerFollowing/FollowerFollowing';
import EditProfilePopup from '../../components/Modal/EditProfilePopup';
import FollowingFollowersPopup from '../../components/Modal/FollowingFollowersPopup';
import VerifyAccountPopup from '../../components/Modal/VerifyAccountPopup';
import ProfileBanner from '../../components/ProfileBanner/ProfileBanner';
import RoyaltyCard from '../../components/RoyaltyCard/RoyaltyCard';

// IMAGES
import CloneIcon from '../../assets/images/clone-icon.svg';
import VerifyIcon from '../../assets/images/verify-icon.svg';

function MyProfile() {
    const [tabsActive, setTabsActive] = useState('my-royalty-earning');
    const [isVerifyPopupOpen, setIsVerifyPopupOpen] = useState(false);
    const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = useState(false);
    const [isFollowsPopupOpen, setIsFollowsPopupOpen] = useState(false);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const tabsList = [
        { name: 'My Royalty Earning', key: 'my-royalty-earning' },
        { name: 'Royalty History', key: 'royalty-history' },
    ];

    const { mutate: mutateEditProfile } = useMutation({
        mutationFn: (fd) => updateProfilePic(fd),
        onSuccess: (data) => {
            const updatedUserData = {
                ...user,
                profilePicUrl: data?.data?.data?.sProfilePicUrl,
                coverPicUrl: data?.data?.data?.sCoverPicUrl,
            };

            dispatch(updateUser({ ...updatedUserData }));
        },
        onError: (error) => {
            notify('error', error);
        },
    });

    const fileOnChangeProfile = (event) => {
        if (event.target.files.length > 0) {
            let formData = new FormData();

            formData.append('image', event.target.files[0]);
            formData.append('sFieldName', 'sProfilePicUrl');
            mutateEditProfile(formData);
        }
    };
    const fileOnChangeCover = (event) => {
        if (event.target.files.length > 0) {
            let formData = new FormData();

            formData.append('image', event.target.files[0]);
            formData.append('sFieldName', 'sCoverPicUrl');
            mutateEditProfile(formData);
        }
    };

    const { data: userDetails, refetch: refetchUser } = useQuery({
        queryKey: ['userDetails'],
        queryFn: () =>
            getMyProfile().then((res) => {
                // setTimeout(() => {
                //     dispatch(toggleLoader(false));
                // }, 300);
                return res.data.data;
            }),
    });

    const { data: pendingRoyalty, refetch: refetchPending } = useQuery({
        queryKey: ['pendingRoyalty'],
        queryFn: () =>
            getPendingRoyalty({ start: 0, length: 1000 }).then((res) => {
                return res.data.data;
            }),
        enabled: !!userDetails,
    });

    const { data: withdrawRoyalty } = useQuery({
        queryKey: ['withdrawRoyalty'],
        queryFn: () =>
            getWithdrawnRoyalty({ start: 0, length: 1000 }).then((res) => {
                return res.data.data;
            }),
        enabled: !!userDetails,
    });

    const { mutate: mutateVerifyUser } = useMutation({
        mutationFn: (data) => verifyUser(data),
        onSuccess: (data) => {
            dispatch(toggleLoader(false));
            setIsVerifyPopupOpen(false);
            notify('success', data?.data?.message);
        },
        onError: (error) => {
            notify('error', error);
        },
    });

    useEffect(() => {
        if (getCookie('_userData')) {
            setIsVerifyPopupOpen(true);
            dispatch(toggleLoader(true));
            mutateVerifyUser({ userData: getCookie('_userData') });
        }
    }, []);
    return (
        <section>
            <ProfileBanner
                isOwnProfile={true}
                fileOnChangeProfile={fileOnChangeProfile}
                fileOnChangeCover={fileOnChangeCover}
            />
            <div className='container pt-20 pb-20 sm:pt-14'>
                <div className='flex gap-10 justify-between border-b-2 border-b-dark-20 pb-14 md:flex-col md:gap-5 sm:pb-10'>
                    <div className='max-w-3xl md:max-w-none'>
                        <h1 className='main-heading-h1 text-darkGray'>
                            {user.userName}
                            {userDetails?.oUser?.isUserVerified && (
                                <img
                                    src={VerifyIcon}
                                    alt='VerifyIcon'
                                    className='w-11 inline-block ml-2'
                                />
                            )}
                        </h1>
                        <div className='my-8 sm:my-5 inline-flex gap-5 lg:flex-wrap'>
                            <button
                                className='btn-secondary bg-purple border-purple text-black text-base  md:text-sm sm:text-xs'
                                onClick={async (e) => {
                                    try {
                                        let data = await copyToClipboard(
                                            user?.walletAddress
                                        );

                                        if (data)
                                            notify(
                                                'success',
                                                'Wallet address copied to clipboard'
                                            );
                                    } catch (error) {
                                        console.log(error);
                                    }
                                }}
                            >
                                {user?.walletAddress &&
                                    trimEthereumAddress(user?.walletAddress, 5)}
                                <img
                                    src={CloneIcon}
                                    alt='CloneIcon'
                                    className='ml-2'
                                />
                            </button>
                            <div className='btn-secondary bg-dark-10 text-black border-transparent hover:bg-dark-10 text-base font-medium'>
                                {user.email}
                            </div>
                        </div>
                        {user?.bio && (
                            <div>
                                <h6 className='text-sm text-black font-bold mb-3 mt-5 md:mt-0'>
                                    BIO
                                </h6>
                                <p className='text-sm text-dark-90'>
                                    {user?.bio}
                                </p>
                            </div>
                        )}
                    </div>
                    <div>
                        <div className='flex justify-end gap-6 mb-5 md:justify-start sm:gap-12 sm:mb-5'>
                            <FollowerFollowing
                                follower={userDetails?.oUser?.aFollowers.length}
                                following={
                                    userDetails?.oUser?.aFollowing.length
                                }
                                sUserName={userDetails?.oUser?.sUserName}
                                aFollowers={userDetails?.oUser?.aFollowers}
                                aFollowing={userDetails?.oUser?.aFollowing}
                                refetchUser={refetchUser}
                                isMyProfile={true}
                            />
                        </div>
                        {/* <div className='flex justify-end min-w-[380px] md:justify-start sm:min-w-full'>
                            <button
                                className='btn-secondary'
                                onClick={() => setIsVerifyPopupOpen(true)}
                            >
                                Verify account
                            </button>
                            <button
                                className='btn-border ml-5'
                                onClick={() => setIsEditProfilePopupOpen(true)}
                            >
                                Edit profile
                            </button>
                        </div> */}
                    </div>
                </div>
                <div className='py-14 lg:py-12 sm:py-10'>
                    <ul className='flex flex-wrap gap-8 md:gap-5 md:gap-y-2'>
                        {tabsList.length &&
                            tabsList.map((item, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={() => setTabsActive(item.key)}
                                    >
                                        <span
                                            className={`text-base inline-block font-bold text-dark-60 cursor-pointer duration-300 relative after:h-[3px] after:absolute after:-bottom-2 after:left-0 after:duration-300 after:bg-gradient after:opacity-0 after:w-full hover:text-black hover:after:opacity-100 md:text-sm ${tabsActive === item.key
                                                    ? '!text-black after:opacity-100'
                                                    : ''
                                                }`}
                                        >
                                            {item.name}
                                            {item.count && (
                                                <span className='ml-2 px-2 py-1.5 text-xs text-white inline-block bg-black rounded-lg font-bold sm:text-xxs sm:py-1 sm:px-1.5 sm:align-bottom'>
                                                    {item.count}
                                                </span>
                                            )}
                                        </span>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
                {tabsActive === 'my-royalty-earning' &&
                    pendingRoyalty?.length ? (
                    <div className='grid grid-cols-3 gap-5 flex-wrap 2xl:grid-cols-2 md:grid-cols-1'>
                        {pendingRoyalty?.map((item, i) => (
                            <RoyaltyCard
                                key={i}
                                data={item}
                                refetch={refetchPending}
                            />
                        ))}
                    </div>
                ) : (
                    tabsActive === 'my-royalty-earning' && (
                        <div className='flex justify-center items-center h-96'>
                            <h4 className='text-2xl font-bold text-black'>
                                No Pending Royalty
                            </h4>
                        </div>
                    )
                )}
                {tabsActive === 'royalty-history' && withdrawRoyalty?.length ? (
                    <div className='grid grid-cols-3 gap-5 flex-wrap 2xl:grid-cols-2 md:grid-cols-1'>
                        {withdrawRoyalty?.map((item, i) => (
                            <RoyaltyCard
                                key={i}
                                data={item}
                                isWithdraw={true}
                            />
                        ))}
                    </div>
                ) : (
                    tabsActive === 'royalty-history' && (
                        <div className='flex justify-center items-center h-96'>
                            <h4 className='text-2xl font-bold text-black'>
                                No Withdrawn Royalty
                            </h4>
                        </div>
                    )
                )}

                <VerifyAccountPopup
                    isPopupOpen={isVerifyPopupOpen}
                    setIsPopupOpen={setIsVerifyPopupOpen}
                />
                {isEditProfilePopupOpen ? (
                    <EditProfilePopup
                        isPopupOpen={isEditProfilePopupOpen}
                        setIsPopupOpen={setIsEditProfilePopupOpen}
                        refetch={refetchUser}
                    />
                ) : null}
                <FollowingFollowersPopup
                    isPopupOpen={isFollowsPopupOpen}
                    setIsPopupOpen={setIsFollowsPopupOpen}
                />
            </div>
        </section>
    );
}

export default MyProfile;
