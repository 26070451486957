import { apiClient } from '../client/api-client';

export const getMyProfile = async () => {
    let url = `/user/get-my-profile`;
    return await apiClient({ url, method: 'GET' });
};

export const getUserProfile = async (username) => {
    let url = `/user/user/${username}`;
    return await apiClient({ url, method: 'GET' });
};

export const editProfile = async (data) => {
    let url = `/user/edit-profile`;
    return await apiClient({ url, method: 'PATCH', data });
};

export const updateProfilePic = async (data) => {
    let url = `/user/upload-profile`;
    return await apiClient({ url, method: 'POST', data });
};

export const follow = async (data) => {
    let url = `/user/follow`;
    return await apiClient({ url, method: 'PATCH', data });
};

export const unFollow = async (data) => {
    let url = `/user/unfollow`;
    return await apiClient({ url, method: 'PATCH', data });
};

export const getFollowFollowers = async (data) => {
    let url = `/user/get-followers`;
    return await apiClient({ url, method: 'POST', data });
};

export const addSubscriber = async (data) => {
    let url = `/user/subscribers/add`;
    return await apiClient({ url, method: 'POST', data });
};
