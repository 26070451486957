/*---------------------------------------------*
This file requires import of following js cdns:
- jQuery (v3.6.0)
- web3.js (v1.7.4)
- dataTable (v1.10.18)
- ejs (v3.1.8)
- tilt.js

- chains.web3.js
*----------------------------------------------*/

import moment from "moment";
import CHAINS_WEB3 from "../configs/web3-chains";

import { ethers } from "ethers";

export const chainSymbolById = function (id) {
  return CHAINS_WEB3[id]?.nativeCurrency?.symbol;
};

export const trimEthereumAddress = function (sWalletAddress, len) {
  return (
    sWalletAddress.substring(0, len) +
    "..." +
    sWalletAddress.substring(sWalletAddress.length - len)
  );
};

export const copyToClipboard = async function (text) {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
};

export const valueMultiply = function (val1, val2) {
  return toEther(String(Number(val1) * Number(toWei(val2))));
};

export const toUsd = function (val1, val2) {
  return Number(val1) * Number(val2);
};

export const findFromObjectArray = function (arr, key, value) {
  return arr.find((item) => {
    return item[key] === value;
  });
};

/* web3 */

export const toEther = function (amount, decimals = 18) {
  // return web3_core.utils.fromWei(amount.toString(), 'ether');
  return ethers.utils.formatUnits(amount.toString(), decimals);
};

export const toWei = function (amount, decimals = 18) {
  return ethers.utils.parseUnits(amount?.toString(), decimals)?.toString();
};

export const toWeb3ContractObject = function (web3_obj, address, abi) {
  return new web3_obj.eth.Contract(abi, address);
};

export const addTiltEffect = function (ele) {
  ele.tilt({
    maxTilt: 5,
    glare: true,
    maxGlare: 0.5,
  });
};

export const cloneObject = function (object) {
  const newData = {};
  const keys = Object.keys(object);
  for (let i = 0; i < keys.length; i += 1) {
    const eType = object[keys[i]] ? object[keys[i]].constructor : "normal";
    switch (eType) {
      case "normal":
        newData[keys[i]] = object[keys[i]];
        break;
      case Array:
        newData[keys[i]] = this.cloneArray(object[keys[i]]);
        break;
      case Object:
        newData[keys[i]] = this.cloneObject(object[keys[i]]);
        break;
      default:
        newData[keys[i]] = object[keys[i]];
        break;
    }
  }
  return newData;
};

export const cloneArray = function (array) {
  const newData = [];
  for (let i = 0; i < array.length; i += 1) {
    const eType = array[i] ? array[i].constructor : "normal";
    switch (eType) {
      case "normal":
        newData.push(array[i]);
        break;
      case Array:
        newData.push(this.cloneArray(array[i]));
        break;
      case Object:
        newData.push(this.cloneObject(array[i]));
        break;
      default:
        newData.push(array[i]);
        break;
    }
  }
  return newData;
};

export const formatNumber = function (number) {
  return number >= 1 && number < 10 ? `0${number}` : number;
};

export const parseRevertReason = (errorMessage) => {
  // Assuming the revert reason is always formatted the same way
  let matches = errorMessage.match(
    /reverted with the following reason:\nMedia:\s*(.*)/
  );
  if (!matches) {
    matches = errorMessage.match(
      /reverted with the following reason:\nMarket:\s*(.*)/
    );
  }

  if (matches && matches[1]) {
    // Return just the revert reason
    return matches[1];
    // If the format is not recognized, return the entire error message
  } else {
    return errorMessage;
  }
};

export const isValidName = function (sName) {
  const reName = /^[a-zA-Z ]{1,25}$/;
  return reName.test(sName);
};

export const isUserName = function (sName) {
  const reName = /^[a-zA-Z][a-zA-Z0-9_.]{2,14}$/;
  return reName.test(sName);
};

export const isValidStringLength = function (sString) {
  return sString.trim().length > 0 && sString.trim().length <= 100;
};

export const shortenAddress = (hexString, length = 8) => {
  const shortenedBody = hexString.slice(0, length);
  const formattedString = shortenedBody + "..";
  return formattedString;
};

export const isEmail = function (email) {
  const regeX = /[a-z0-9._%+-]+@[a-z0-9-]+[.]+[a-z]{2,5}$/;
  return regeX.test(email);
};

export const parseDate = function (date) {
  const parsedTime = moment(date);
  const currentTime = moment();
  let displayTime;

  // Check if the time difference is more than 24 hours
  if (currentTime.diff(parsedTime, "hours") > 24) {
    displayTime = parsedTime.format("DD/MM/YYYY");
  } else {
    displayTime = parsedTime.fromNow();
  }

  return displayTime;
};

export const formateDate = function (date) {
  const formattedDateString = moment(date).format("MMM D, YYYY · hh:mm A");
  return formattedDateString;
};

export const formatNumberWithK = function (num) {
  if (num >= 1000) {
    return (num / 1000).toFixed(2) + "K"; // For thousands, format as K
  } else if (num >= 1 && num < 10) {
    return "0" + num.toString(); // Add leading zero for numbers less than 10
  } else {
    return num.toString(); // Return the number as a string for other cases
  }
};

export const trailingAddress = function (sWalletAddress, len) {
  return sWalletAddress.substring(0, len) + "...";
};

export const customCurrencyFormat = function (number, isPrice = false) {
  // Format the number using the Indian numbering system
  let formattedNumber = new Intl.NumberFormat("en-IN", {
    style: isPrice ? "currency" : undefined,
    currency: isPrice ? "USD" : undefined,
    minimumFractionDigits: 0,
  }).format(number);

  return formattedNumber;
};
export const toFixedNumber = function (number, decimal = 18) {
  if (String(number).includes("e")) {
    return parseFloat(number).toFixed(decimal).replace(/0+$/, "");
  }
  return number;
};
export const goBack = (navigate, location) => {
  // Check if there's history to go back to
  console.log("location", location);
  if (location.key !== "default") {
    navigate(-1);
  } else {
    // Handle the case when there's no history (e.g., navigate to a default route)
    navigate("/", { replace: true });
  }
};

export function MDY(dateString) {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const dateObj = new Date(dateString);
  const formattedDate = dateObj.toLocaleDateString("en-US", options);

  // Extract day, month, and year from formatted date
  const parts = formattedDate.split(" ");
  const day = parts[1].replace(",", "");
  const month = parts[0];
  const year = parts[2];

  return `${month} ${day}, ${year}`;
}
