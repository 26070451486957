import { Disclosure, RadioGroup, Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import CHAINS_WEB3 from '../../configs/web3-chains';

// IMAGES
import bscIcon from '../../assets/images/Binance-icon.svg';
import bobaIcon from '../../assets/images/Boba-icon.svg';
import ethIcon from '../../assets/images/Ethereum-icon.svg';
import maticIcon from '../../assets/images/Polygon-icon.svg';
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import { getToken } from '../../utils/cookies.helper';

function Filter({ category, filters, setFilters, categoryIdList, refetch }) {
    const [blockchain, setBlockchain] = useState(filters.blockChain);
    const [status, setStatus] = useState(filters.status);
    const [token, setToken] = useState('All');
    const [type, setType] = useState(filters.type);
    const categoryList = [
        {
            sName: 'All NFTs',
            key: 'all-nfts',
        },
        ...category,
    ];

    const iconList = {
        Ethereum: ethIcon,
        Binance: bscIcon,
        'Polygon-zkEVM': maticIcon,
        Polygon: maticIcon,
        Boba: bobaIcon,
    };

    const tokenLIst = [
        'All',
        'ETH',
        'USDC',
        'OMI',
        'EPIKO',
        'SPIZ',
        'MATIC',
        'BOBA',
        'BNB',
    ];

    useEffect(() => {
        setStatus(filters.status);
        setBlockchain(filters.blockChain);
        setToken(filters.token);
        setType(filters.type);
        if (!filters.categories.length) {
            categoryIdList.forEach((item) => {
                const checkbox = document.getElementById(`category-${item}`);
                if (checkbox) checkbox.checked = false;
            });
        }
    }, [filters]);

    return (
        <div className='w-[400px] pr-12 2xl:w-96 xl:w-80 lg:w-72 lg:pr-9  md:w-full md:pt-16 md:mb-10 md:pr-0 xs:pt-12 xs:mb-0'>
            <Disclosure
                as='div'
                className='border-b-2 border-dark-40 duration-300'
            >
                {({ open }) => (
                    <>
                        <Disclosure.Button className='flex w-full justify-between items-center py-6 text-left text-lg font-bold text-black focus:outline-none lg:text-md sm:py-4'>
                            <span>Status</span>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={`mr-2 ${
                                    open ? 'rotate-180 transform' : ''
                                }`}
                            ></img>
                        </Disclosure.Button>
                        <Transition
                            show={open}
                            enter='transition duration-300 ease-out'
                            enterFrom='transform -transition-y-8 opacity-0'
                            enterTo='transform transition-y-0 opacity-100'
                            leave='transition duration-300 ease-out'
                            leaveFrom='transform transition-y-0 opacity-100'
                            leaveTo='transform -transition-y-8 opacity-0'
                        >
                            <Disclosure.Panel className='pb-6 text-sm'>
                                <RadioGroup
                                    value={status}
                                    onChange={(e) => {
                                        setStatus(e);
                                        setFilters({
                                            ...filters,
                                            status: e,
                                        });
                                    }}
                                    className='flex flex-wrap gap-x-2 gap-2.5'
                                >
                                    <RadioGroup.Option value='All'>
                                        {({ checked }) => (
                                            <span
                                                className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                    checked
                                                        ? 'bg-black text-white'
                                                        : 'text-black bg-dark-10 '
                                                }`}
                                            >
                                                All
                                            </span>
                                        )}
                                    </RadioGroup.Option>
                                    <RadioGroup.Option value='List'>
                                        {({ checked }) => (
                                            <span
                                                className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                    checked
                                                        ? 'bg-black text-white'
                                                        : 'text-black bg-dark-10 '
                                                }`}
                                            >
                                                On Sale
                                            </span>
                                        )}
                                    </RadioGroup.Option>
                                    <RadioGroup.Option value='On Hold'>
                                        {({ checked }) => (
                                            <span
                                                className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                    checked
                                                        ? 'bg-black text-white'
                                                        : 'text-black bg-dark-10 '
                                                }`}
                                            >
                                                Not On Sale
                                            </span>
                                        )}
                                    </RadioGroup.Option>
                                    {getToken() && (
                                        <>
                                            <RadioGroup.Option value='Created'>
                                                {({ checked }) => (
                                                    <span
                                                        className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                            checked
                                                                ? 'bg-black text-white'
                                                                : 'text-black bg-dark-10 '
                                                        }`}
                                                    >
                                                        Created
                                                    </span>
                                                )}
                                            </RadioGroup.Option>
                                            <RadioGroup.Option value='Owned'>
                                                {({ checked }) => (
                                                    <span
                                                        className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                            checked
                                                                ? 'bg-black text-white'
                                                                : 'text-black bg-dark-10 '
                                                        }`}
                                                    >
                                                        Owned
                                                    </span>
                                                )}
                                            </RadioGroup.Option>
                                            <RadioGroup.Option value='Liked'>
                                                {({ checked }) => (
                                                    <span
                                                        className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                            checked
                                                                ? 'bg-black text-white'
                                                                : 'text-black bg-dark-10 '
                                                        }`}
                                                    >
                                                        Liked
                                                    </span>
                                                )}
                                            </RadioGroup.Option>
                                        </>
                                    )}
                                </RadioGroup>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>

            {/* BlockChain */}
            <Disclosure
                as='div'
                className='border-b-2 border-dark-40 duration-300'
            >
                {({ open }) => (
                    <>
                        <Disclosure.Button className='flex w-full justify-between items-center py-6 text-left text-lg font-bold text-black focus:outline-none lg:text-md sm:py-4'>
                            <span>Blockchain</span>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={`mr-2 ${
                                    open ? 'rotate-180 transform' : ''
                                }`}
                            ></img>
                        </Disclosure.Button>
                        <Transition
                            show={open}
                            enter='transition duration-300 ease-out'
                            enterFrom='transform -transition-y-8 opacity-0'
                            enterTo='transform transition-y-0 opacity-100'
                            leave='transition duration-300 ease-out'
                            leaveFrom='transform transition-y-0 opacity-100'
                            leaveTo='transform -transition-y-8 opacity-0'
                        >
                            <Disclosure.Panel className='pb-6 text-sm'>
                                <RadioGroup
                                    value={blockchain}
                                    onChange={(e) => {
                                        setBlockchain(e);
                                        setFilters({
                                            ...filters,
                                            blockChain: e,
                                        });
                                    }}
                                    className='flex flex-wrap gap-x-2 gap-2.5'
                                >
                                    <RadioGroup.Option value='All'>
                                        {({ checked }) => (
                                            <span
                                                className={`px-6 py-3 flex text-sm font-bold rounded cursor-pointer text-center md:py-2 md:px-3 md:w-auto ${
                                                    checked
                                                        ? 'bg-black text-white'
                                                        : 'text-black bg-dark-10 '
                                                }`}
                                            >
                                                All
                                            </span>
                                        )}
                                    </RadioGroup.Option>
                                    {Object.keys(CHAINS_WEB3).map((element) => {
                                        return (
                                            <RadioGroup.Option
                                                key={element}
                                                value={element}
                                            >
                                                {({ checked }) => (
                                                    <span
                                                        className={`px-5 py-3 flex text-sm font-bold rounded cursor-pointer text-center md:py-2 md:px-3 md:w-auto ${
                                                            checked
                                                                ? 'bg-black text-white'
                                                                : 'text-black bg-dark-10 '
                                                        }`}
                                                    >
                                                        <img
                                                            src={
                                                                iconList[
                                                                    CHAINS_WEB3[
                                                                        element
                                                                    ].chainName
                                                                ]
                                                            }
                                                            alt={
                                                                CHAINS_WEB3[
                                                                    element
                                                                ].chainName
                                                            }
                                                            className={`mr-1 w-5 ${
                                                                checked
                                                                    ? 'invert'
                                                                    : ''
                                                            }`}
                                                        />
                                                        {
                                                            CHAINS_WEB3[element]
                                                                .chainName
                                                        }
                                                    </span>
                                                )}
                                            </RadioGroup.Option>
                                        );
                                    })}
                                </RadioGroup>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>

            {/* Category */}
            <Disclosure
                as='div'
                className='border-b-2 border-dark-40 duration-300'
            >
                {({ open }) => (
                    <>
                        <Disclosure.Button className='flex w-full justify-between items-center py-6 text-left text-lg font-bold text-black focus:outline-none lg:text-md sm:py-4'>
                            <span>Categories</span>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={`mr-2 ${
                                    open ? 'rotate-180 transform' : ''
                                }`}
                            ></img>
                        </Disclosure.Button>
                        <Transition
                            show={open}
                            enter='transition duration-300 ease-out'
                            enterFrom='transform -transition-y-8 opacity-0'
                            enterTo='transform transition-y-0 opacity-100'
                            leave='transition duration-300 ease-out'
                            leaveFrom='transform transition-y-0 opacity-100'
                            leaveTo='transform -transition-y-8 opacity-0'
                        >
                            <Disclosure.Panel className='pb-6 text-sm'>
                                {categoryList.map((option, optionIdx) => {
                                    return (
                                        <div
                                            key={optionIdx}
                                            className='flex items-center mb-2'
                                        >
                                            <input
                                                id={`category-${
                                                    option._id || 'all'
                                                }`}
                                                name={`${option.sName}`}
                                                defaultValue={option.sName}
                                                type='checkbox'
                                                defaultChecked={filters.categories?.includes(
                                                    option._id
                                                )}
                                                className='h-[18px] w-[18px] rounded border-2 border-dark-80 text-primary focus:ring-transparent'
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        if (!option._id) {
                                                            categoryIdList.forEach(
                                                                (item) => {
                                                                    const checkbox =
                                                                        document.getElementById(
                                                                            `category-${item}`
                                                                        );
                                                                    checkbox.checked = true;
                                                                }
                                                            );
                                                            setFilters({
                                                                ...filters,
                                                                categories:
                                                                    categoryIdList,
                                                            });
                                                        } else if (
                                                            !filters.categories?.includes(
                                                                option._id
                                                            )
                                                        ) {
                                                            filters.categories?.push(
                                                                option._id
                                                            );
                                                            setFilters({
                                                                ...filters,
                                                                categories:
                                                                    filters.categories,
                                                            });
                                                        }
                                                    } else {
                                                        if (!option._id) {
                                                            setFilters({
                                                                ...filters,
                                                                categories: [],
                                                            });
                                                        }

                                                        const checkbox =
                                                            document.getElementById(
                                                                'category-all'
                                                            );
                                                        checkbox.checked = false;

                                                        setFilters({
                                                            ...filters,
                                                            categories:
                                                                filters.categories.filter(
                                                                    (item) =>
                                                                        item !==
                                                                        option._id
                                                                ),
                                                        });
                                                    }
                                                }}
                                            />
                                            <label
                                                htmlFor={`category-${
                                                    option._id || 'all'
                                                }`}
                                                className='ml-3 min-w-0 flex-1 text-base font-medium text-dark-80'
                                            >
                                                {option.sName}
                                            </label>
                                        </div>
                                    );
                                })}
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>

            {/* Token */}
            <Disclosure
                as='div'
                className='border-b-2 border-dark-40 duration-300'
            >
                {({ open }) => (
                    <>
                        <Disclosure.Button className='flex w-full justify-between items-center py-6 text-left text-lg font-bold text-black focus:outline-none lg:text-md sm:py-4'>
                            <span>Token</span>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={`mr-2 ${
                                    open ? 'rotate-180 transform' : ''
                                }`}
                            ></img>
                        </Disclosure.Button>
                        <Transition
                            show={open}
                            enter='transition duration-300 ease-out'
                            enterFrom='transform -transition-y-8 opacity-0'
                            enterTo='transform transition-y-0 opacity-100'
                            leave='transition duration-300 ease-out'
                            leaveFrom='transform transition-y-0 opacity-100'
                            leaveTo='transform -transition-y-8 opacity-0'
                        >
                            <Disclosure.Panel className='pb-6 text-sm'>
                                <RadioGroup
                                    value={filters.token}
                                    onChange={(e) => {
                                        setFilters({
                                            ...filters,
                                            token: e,
                                        });
                                    }}
                                    className='flex flex-wrap gap-x-2 gap-2.5'
                                >
                                    {tokenLIst.map((item) => {
                                        return (
                                            <RadioGroup.Option
                                                value={item}
                                                key={item}
                                            >
                                                {({ checked }) => (
                                                    <span
                                                        className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                            checked
                                                                ? 'bg-black text-white'
                                                                : 'text-black bg-dark-10 '
                                                        }`}
                                                    >
                                                        {item}
                                                    </span>
                                                )}
                                            </RadioGroup.Option>
                                        );
                                    })}
                                </RadioGroup>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>

            {/* Type */}
            <Disclosure
                as='div'
                className='border-b-2 border-dark-40 duration-300'
            >
                {({ open }) => (
                    <>
                        <Disclosure.Button className='flex w-full justify-between items-center py-6 text-left text-lg font-bold text-black focus:outline-none lg:text-md sm:py-4'>
                            <span>Type</span>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={`mr-2 ${
                                    open ? 'rotate-180 transform' : ''
                                }`}
                            ></img>
                        </Disclosure.Button>
                        <Transition
                            show={open}
                            enter='transition duration-300 ease-out'
                            enterFrom='transform -transition-y-8 opacity-0'
                            enterTo='transform transition-y-0 opacity-100'
                            leave='transition duration-300 ease-out'
                            leaveFrom='transform transition-y-0 opacity-100'
                            leaveTo='transform -transition-y-8 opacity-0'
                        >
                            <Disclosure.Panel className='pb-6 text-sm'>
                                <RadioGroup
                                    value={type}
                                    onChange={(e) => {
                                        setType(e);
                                        setFilters({
                                            ...filters,
                                            type: e,
                                        });
                                    }}
                                    className='flex flex-wrap gap-x-2 gap-2.5'
                                >
                                    <RadioGroup.Option value='All'>
                                        {({ checked }) => (
                                            <span
                                                className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                    checked
                                                        ? 'bg-black text-white'
                                                        : 'text-black bg-dark-10 '
                                                }`}
                                            >
                                                All
                                            </span>
                                        )}
                                    </RadioGroup.Option>
                                    <RadioGroup.Option value='ERC721'>
                                        {({ checked }) => (
                                            <span
                                                className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                    checked
                                                        ? 'bg-black text-white'
                                                        : 'text-black bg-dark-10 '
                                                }`}
                                            >
                                                Single Edition
                                            </span>
                                        )}
                                    </RadioGroup.Option>
                                    <RadioGroup.Option value='ERC1155'>
                                        {({ checked }) => (
                                            <span
                                                className={`px-6 py-3 text-sm font-bold rounded cursor-pointer block text-center md:py-2 md:px-3 md:w-auto ${
                                                    checked
                                                        ? 'bg-black text-white'
                                                        : 'text-black bg-dark-10 '
                                                }`}
                                            >
                                                Multiple Edition
                                            </span>
                                        )}
                                    </RadioGroup.Option>
                                </RadioGroup>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>

            {/* Price */}
            <Disclosure
                as='div'
                className='border-b-2 border-dark-40 duration-300'
            >
                {({ open }) => (
                    <>
                        <Disclosure.Button className='flex w-full justify-between items-center py-6 text-left text-lg font-bold text-black focus:outline-none lg:text-md sm:py-4'>
                            <span>Price</span>
                            <img
                                src={CollapseIcon}
                                alt='CollapseIcon'
                                className={`mr-2 ${
                                    open ? 'rotate-180 transform' : ''
                                }`}
                            ></img>
                        </Disclosure.Button>
                        <Transition
                            show={open}
                            enter='transition duration-300 ease-out'
                            enterFrom='transform -transition-y-8 opacity-0'
                            enterTo='transform transition-y-0 opacity-100'
                            leave='transition duration-300 ease-out'
                            leaveFrom='transform transition-y-0 opacity-100'
                            leaveTo='transform -transition-y-8 opacity-0'
                        >
                            <Disclosure.Panel className='pb-6 text-sm'>
                                <div className='flex items-center gap-3.5'>
                                    <div>
                                        <input
                                            type='text'
                                            value={filters?.price?.min}
                                            name=''
                                            placeholder='Min'
                                            className='bg-dark-10 border w-full py-3 px-3 rounded border-dark-30 text-sm text-center font-bold focus:outline-none focus:ring-0  focus:border-dark-70'
                                            onChange={(e) => {
                                                setFilters({
                                                    ...filters,
                                                    price: {
                                                        ...filters.price,
                                                        min:
                                                            e.target.value >= 0
                                                                ? e.target.value
                                                                : '',
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='text-sm text-center font-bold text-dark-60'>
                                        to
                                    </div>
                                    <div>
                                        <input
                                            type='text'
                                            value={filters?.price?.max}
                                            name=''
                                            placeholder='Max'
                                            className='bg-dark-10 border w-full py-3 px-3 rounded border-dark-30 text-sm text-center font-bold focus:outline-none focus:ring-0  focus:border-dark-70'
                                            onChange={(e) => {
                                                setFilters({
                                                    ...filters,
                                                    price: {
                                                        ...filters.price,
                                                        max:
                                                            e.target.value >= 0
                                                                ? e.target.value
                                                                : '',
                                                    },
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className='bg-dark-10 py-3 px-5 rounded text-sm text-center font-bold '>
                                        USD
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <button
                                        type='button'
                                        className='bg-dark-10 text-sm text-black font-bold sm:text-sm block w-full p-3 rounded-xl'
                                        onClick={() => {
                                            if (
                                                filters.price.min &&
                                                filters.price.max
                                            ) {
                                                refetch();
                                            }
                                        }}
                                    >
                                        APPLY
                                    </button>
                                </div>
                            </Disclosure.Panel>
                        </Transition>
                    </>
                )}
            </Disclosure>
        </div>
    );
}

export default Filter;
