import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getContactDetails, sendQuery } from '../../api/adapters/asset-common';
import { toggleLoader } from '../../redux/actions/loader';
import { goBack, isEmail } from '../../utils/helper';

// COMPONENTS
import Input from '../../components/FormControl/Input';
import TextArea from '../../components/FormControl/TextArea';

// IMAGES
import LocationIcon from '../../assets/images/location-icon.svg';
import EmailIcon from '../../assets/images/mail-icon.svg';
import ArrowIcon from '../../assets/images/next-arrow.svg';
import ButtonLoader from '../../components/Loader/ButtonLoader';
import { notify } from '../../utils/common.helper';

function ContactUs() {
    const [inputValue, setInputValue] = useState({
        sFirstName: '',
        sLastName: '',
        sEmail: '',
        sSubject: '',
        sMessage: '',
    });
    const [error, setError] = useState({
        sFirstName: '',
        sLastName: '',
        sEmail: '',
        sSubject: '',
        sMessage: '',
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useNavigate();

    const { data: contactDetail } = useQuery({
        queryKey: ['contactDetail'],
        queryFn: () =>
            getContactDetails().then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                return res.data.data;
            }),
    });
    const { mutate: mutateSendQuery, isPending } = useMutation({
        mutationFn: (data) => sendQuery(data),
        onSuccess: (data) => {
            notify('success', 'Query sent successfully');
            setInputValue({
                sFirstName: '',
                sLastName: '',
                sEmail: '',
                sSubject: '',
                sMessage: '',
            });
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong');
        },
    });

    const validate = () => {
        return new Promise((resolve, reject) => {
            const errors = {
                sFirstName: !inputValue.sFirstName
                    ? 'First Name is required'
                    : inputValue.sFirstName.length > 25
                        ? 'First Name should be less than 25 characters'
                        : '',
                sLastName: !inputValue.sLastName
                    ? 'Last Name is required'
                    : inputValue.sLastName.length > 25
                        ? 'Last Name should be less than 25 characters'
                        : '',
                sEmail: !inputValue.sEmail
                    ? 'Email is required'
                    : !isEmail(inputValue.sEmail)
                        ? 'Email address is invalid'
                        : '',
                sSubject: inputValue.sSubject ? '' : 'Subject is required',
                sMessage: inputValue.sMessage ? '' : 'Message is required',
            };

            const isFormError = !Object.values(errors).some((value) => value);

            setError(errors);
            isFormError ? resolve({ ...errors }) : reject({ ...errors });
        });
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {
            await validate();
            mutateSendQuery(inputValue);
        } catch (error) { }
    };

    return (
        <section className='section-space-small'>
            <div className='container'>
                <div className='text-center relative'>
                    <h1 className='main-heading-h2 sm:mt-3'>Contact Us</h1>
                    <button
                        className='group btn-border absolute top-6 left-0 lg:top-5 md:top-3 sm:-top-8 sm:py-2'
                        onClick={(e) => goBack(navigate, location)}
                    >
                        <img
                            src={ArrowIcon}
                            alt='ArrowIcon'
                            className='rotate-180 invert mr-2 group-hover:invert-0 sm:w-5'
                        />
                        Back
                    </button>
                </div>
                <div className='mt-16 md:mt-10 sm:mt-8'></div>
            </div>
            <div className='bg-primary/10 mb-32 mt-36 md:py-10 md:my-0'>
                <div className='container'>
                    <div className='flex gap-24 px-28 items-center 2xl:gap-20 2xl:px-20 xl:px-0 xl:gap-12 md:flex-col'>
                        <div className='bg-white border border-dark-40 p-8 rounded-2xl -my-20 w-1/2 xl:w-2/3 md:my-0 md:w-full sm:p-5'>
                            <h4 className='text-3xl font-darker font-black text-dark-90 2xl:text-2.5xl md:text-2xl'>
                                Send Your Query
                            </h4>
                            <div className='mt-8 md:mt-0'>
                                <div className='flex gap-x-5 sm:flex-col'>
                                    <div className='mt-6 w-1/2 sm:w-full'>
                                        <Input
                                            placeholder='First name'
                                            label='First name'
                                            id='sFirstName'
                                            value={inputValue.sFirstName}
                                            changeHandler={(e) => {
                                                if (e.target.value.length > 25)
                                                    return;
                                                setInputValue((prev) => ({
                                                    ...prev,
                                                    sFirstName: e.target.value,
                                                }));
                                            }}
                                            error={error.sFirstName}
                                        />
                                    </div>
                                    <div className='mt-6 w-1/2 sm:w-full'>
                                        <Input
                                            placeholder='Last name'
                                            label='Last name'
                                            id='sLastName'
                                            value={inputValue.sLastName}
                                            changeHandler={(e) => {
                                                if (e.target.value.length > 25)
                                                    return;
                                                setInputValue((prev) => ({
                                                    ...prev,
                                                    sLastName: e.target.value,
                                                }));
                                            }}
                                            error={error.sLastName}
                                        />
                                    </div>
                                </div>
                                <div className='mt-6'>
                                    <Input
                                        placeholder='user@mail.com'
                                        label='Email'
                                        id='sEmail'
                                        value={inputValue.sEmail}
                                        changeHandler={(e) => {
                                            if (e.target.value.length > 50)
                                                return;
                                            setInputValue((prev) => ({
                                                ...prev,
                                                sEmail: e.target.value,
                                            }));
                                        }}
                                        error={error.sEmail}
                                    />
                                </div>
                                <div className='mt-6'>
                                    <Input
                                        placeholder='Enter Subject'
                                        label='Subject'
                                        id='sSubject'
                                        value={inputValue.sSubject}
                                        changeHandler={(e) => {
                                            if (e.target.value.length > 100)
                                                return;
                                            setInputValue((prev) => ({
                                                ...prev,
                                                sSubject: e.target.value,
                                            }));
                                        }}
                                        error={error.sSubject}
                                    />
                                </div>
                                <div className='mt-6'>
                                    <TextArea
                                        placeholder=''
                                        label='Message'
                                        id='sMessage'
                                        value={inputValue.sMessage}
                                        changeHandler={(e) => {
                                            if (e.target.value.length > 250)
                                                return;
                                            setInputValue((prev) => ({
                                                ...prev,
                                                sMessage: e.target.value,
                                            }));
                                        }}
                                        error={error.sMessage}
                                    />
                                </div>
                                <div className='mt-6'>
                                    <button
                                        className='btn-primary z-0'
                                        onClick={submitHandler}
                                        disabled={isPending}
                                    >
                                        {isPending && <ButtonLoader />}
                                        SEND MESSAGE
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-14 md:w-full md:gap-10'>
                            <div className='flex items-center'>
                                <div className='bg-primary/20 rounded-2xl p-4 flex-shrink-0 mr-4 sm:p-3'>
                                    <img src={EmailIcon} alt='EmailIcon' />
                                </div>
                                <div>
                                    <h6 className='text-2xl text-black font-darker font-black lg:text-xl'>
                                        Email
                                    </h6>
                                    <Link
                                        to={`mailto:${contactDetail?.sEmail}`}
                                        className='text-base text-dark-70 font-bold duration-300 hover:text-black lg:text-sm'
                                    >
                                        {contactDetail?.sEmail}
                                    </Link>
                                </div>
                            </div>
                            {/* <div className='flex items-center'>
                                <div className='bg-primary/20 rounded-2xl p-4 flex-shrink-0 mr-4 sm:p-3'>
                                    <img src={PhoneIcon} alt='PhoneIcon' />
                                </div>
                                <div>
                                    <h6 className='text-2xl text-black font-darker font-black lg:text-xl'>
                                        Call
                                    </h6>
                                    <Link
                                        to={`tel:${contactDetail?.nContactNumber}`}
                                        className='text-base text-dark-70 font-bold duration-300 hover:text-black lg:text-sm'
                                    >
                                        {contactDetail?.nContactNumber}
                                    </Link>
                                </div>
                            </div> */}
                            <div className='flex items-center'>
                                <div className='bg-primary/20 rounded-2xl p-4 flex-shrink-0 mr-4 sm:p-3'>
                                    <img
                                        src={LocationIcon}
                                        alt='LocationIcon'
                                    />
                                </div>
                                <div>
                                    <h6 className='text-2xl text-black font-darker font-black lg:text-xl'>
                                        Location
                                    </h6>
                                    <p className='text-base text-dark-70 font-bold lg:text-sm'>
                                        {contactDetail?.sLocation}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactUs;
