import React, { useEffect } from 'react';

const FacebookChatPlugin = () => {
    useEffect(() => {
        // Load the Facebook Chat Plugin code
        window.fbAsyncInit = function () {
            // eslint-disable-next-line no-undef
            FB.init({
                xfbml: true,
                version: 'v19.0',
            });
        };

        // Append the SDK script to the document
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src =
                'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    return (
        <div
            className='fb-customerchat'
            attribution='biz_inbox'
            page_id='324602671706270'
        />
    );
};

export default FacebookChatPlugin;
