import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getFaq } from '../../api/adapters/asset-common';
import { useNavigate } from 'react-router-dom';

// COMPONENTS
import FaqsList from '../../components/Faqs/FaqsList';

// IMAGES
import ArrowIcon from '../../assets/images/next-arrow.svg';
import { goBack } from '../../utils/helper';

function Faqs() {
    const navigate = useNavigate();
    const location = useNavigate();

    const { data: faqsDetails } = useQuery({
        queryKey: ['faqsDetails'],
        queryFn: () => getFaq().then((res) => res.data.data),
    });
    // const faqsDetails = [
    //     {
    //         sQuestion: 'How to start as a seller?',
    //         sAnswer:
    //             'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
    //     },
    //     {
    //         sQuestion: 'What happens when there are no orders?',
    //         sAnswer:
    //             'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
    //     },
    //     {
    //         sQuestion: 'Why are you getting rejected shares?',
    //         sAnswer:
    //             'll the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this ',
    //     },
    //     {
    //         sQuestion: 'What is the NFTs reward system?',
    //         sAnswer:
    //             'll the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
    //     },
    //     {
    //         sQuestion: 'Which servers are available?',
    //         sAnswer:
    //             'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
    //     },
    //     {
    //         sQuestion: 'Which miners are supported?',
    //         sAnswer:
    //             'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
    //     },
    //     {
    //         sQuestion: 'Keep It Short, Simple, and to the Point.',
    //         sAnswer:
    //             'All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.',
    //     },
    // ];

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center relative'>
                    <h1 className='main-heading-h2 sm:mt-3'>FAQs</h1>
                    <button
                        className='group btn-border absolute top-6 left-0 lg:top-5 md:top-3 sm:-top-8 sm:py-2'
                        onClick={(e) => goBack(navigate, location)}
                    >
                        <img
                            src={ArrowIcon}
                            alt='ArrowIcon'
                            className='rotate-180 invert mr-2 group-hover:invert-0 sm:w-5'
                        />
                        Back
                    </button>
                </div>
                <div className='mt-16 md:mt-10 sm:mt-8'>
                    {faqsDetails?.length &&
                        faqsDetails.map((item, i) => {
                            return <FaqsList data={item} key={i} />;
                        })}
                </div>
            </div>
        </section>
    );
}

export default Faqs;
