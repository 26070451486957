export const shortBy = [
    { name: 'Newest', value: 'N' },
    { name: 'Oldest', value: 'O' },
    { name: 'Recently Created', value: 'RC' },
    { name: 'Most Viewed', value: 'MV' },
    { name: 'Price (High to Low)', value: 'HL' },
    { name: 'Price (Low to High)', value: 'LH' },
];

export const supportedFileTypes = {
    collection: '.jpg,.jpeg,.png,.gif',
    NFT: 'audio/*,video/*,image/*,.gif,.glb',
    profile: 'image/*,.gif',
    coverPic: 'image/*',
};
