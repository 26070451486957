// Config for testnet
// const CHAINS_WEB3 = {
//   11155111: {
//     // chainId: '0xaaab87', // 5
//     chainId: "0xaa36a7", // 11155111
//     chainName: "Ethereum",
//     nativeCurrency: {
//       name: "Ether",
//       symbol: "ETH",
//       decimals: 18,
//     },
//     rpcUrls: ["https://eth-sepolia.g.alchemy.com/v2/demo"],
//     blockExplorerUrls: ["https://sepolia.etherscan.io"],
//   },

//   97: {
//     chainId: "0x61", // 97
//     chainName: "Binance",
//     nativeCurrency: {
//       name: "BNB",
//       symbol: "BNB",
//       decimals: 18,
//     },
//     rpcUrls: ["https://data-seed-prebsc-2-s1.binance.org:8545"],
//     blockExplorerUrls: ["https://testnet.bscscan.com"],
//   },
//   80001: {
//     chainId: "0x13881", // 80001
//     chainName: "Polygon",
//     nativeCurrency: {
//       name: "Mumbai",
//       symbol: "MATIC",
//       decimals: 18,
//     },
//     rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
//     blockExplorerUrls: ["https://mumbai.polygonscan.com"],
//   },
//   1442: {
//     chainId: "0x5a2", // 1442
//     chainName: "Polygon-zkEVM",
//     nativeCurrency: {
//       name: "Polygon zkEVM",
//       symbol: "ETH",
//       decimals: 18,
//     },
//     rpcUrls: ["https://rpc.public.zkevm-test.net	"],
//     blockExplorerUrls: ["https://explorer.public.zkevm-test.net"],
//   },
//   2888: {
//     chainId: "0x5a2", // 1442
//     chainName: "Boba",
//     nativeCurrency: {
//       name: "Boba Testnet",
//       symbol: "BOBA",
//       decimals: 18,
//     },
//     rpcUrls: ["https://goerli.boba.network"],
//     blockExplorerUrls: ["https://testnet.bobascan.com"],
//   },
// };

// Config for mainnet
const CHAINS_WEB3 = {
  1: {
    chainId: "0x1", // 5
    chainName: "Ethereum",
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://ethereum-rpc.publicnode.com"],
    blockExplorerUrls: ["https://etherscan.io/"],
  },
  56: {
    chainId: "0x38", // 56
    chainName: "Binance",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  137: {
    chainId: "0x89", // 137
    chainName: "Polygon",
    nativeCurrency: {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  1101: {
    chainId: "0x44d", // 1101
    chainName: "Polygon-zkEVM",
    nativeCurrency: {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-zkevm.blockpi.network/v1/rpc/public"],
    blockExplorerUrls: ["https://zkevm.polygonscan.com"],
  },
  288: {
    chainId: "0x120", // 288
    chainName: "Boba",
    nativeCurrency: {
      name: "Boba",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.boba.network"],
    blockExplorerUrls: ["https://bobascan.com"],
  },
};

export default CHAINS_WEB3;
