import { Dialog } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { addCollection } from '../../../api/adapters/collection';
import { notify } from '../../../utils/common.helper';

// COMPONENTS
import FileUpload from '../../FormControl/FileUpload';
import Input from '../../FormControl/Input';
import Modal from '../Modal';

// IMAGES
import CloseIcon from '../../../assets/images/close-icon.svg';
import { supportedFileTypes } from '../../../constants/platform-constants';

function AddCollection(props) {
    const { isPopupOpen, setIsPopupOpen, refetch } = props;
    const [inputValue, setInputValue] = useState({ title: '' });
    const [error, setError] = useState({ title: '', image: '' });
    const [selectedFile, setSelectedFile] = useState();

    const queryClient = useQueryClient();
    function fileOnChange(event) {
        if (event.target.files[0].size > 50000000) {
            notify('error', 'File size should be less than or equal to 50MB');
            return;
        }

        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    }

    const { mutate } = useMutation({
        mutationFn: (data) => addCollection(data),
        onSuccess: (data) => {
            notify('success', data.data.message);
            setInputValue('');
            setSelectedFile();
            setIsPopupOpen(false);
            // refetch();
            queryClient.invalidateQueries('collections');
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong');
            setSelectedFile();
        },
    });

    const handleSubmit = () => {
        const formError = {
            title: inputValue.title ? '' : 'Please enter collection name',
            image: selectedFile ? '' : 'Please select collection image',
        };

        setError((prev) => ({ ...prev, ...formError }));

        const isFormError = !Object.values(formError).some((value) => value);

        if (isFormError) {
            const data = new FormData();
            data.append('image', selectedFile);
            data.append('sName', inputValue.title);
            return mutate(data);
        }
    };

    return (
        <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
            <div className='flex justify-between items-start'>
                <Dialog.Title
                    as='h3'
                    className='text-3xl font-black font-darker text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2'
                >
                    Create Collection
                </Dialog.Title>
                <button
                    className='focus-visible:outline-none'
                    onClick={() => {
                        setIsPopupOpen(false);

                        setSelectedFile();
                        setInputValue({ title: '' });
                        setError({ title: '', image: '' });
                    }}
                >
                    <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                </button>
            </div>

            <div>
                <div className='mt-7'>
                    <Input
                        placeholder='Enter Collection Title'
                        label='Collection Title'
                        id='title'
                        isRequired={true}
                        changeHandler={(e) =>
                            setInputValue((prev) => ({
                                ...prev,
                                title: e.target.value,
                            }))
                        }
                        value={inputValue.title}
                        type='text'
                        error={error.title}
                    />
                </div>
                <div className='mt-7'>
                    <FileUpload
                        label='Collection Image'
                        id='collection_image'
                        selectedFile={selectedFile}
                        onChange={fileOnChange}
                        error={error.image}
                        supportedFileTypes={supportedFileTypes.collection}
                    />
                </div>
                <div className='mt-7'>
                    <button
                        className='btn-secondary w-full'
                        onClick={handleSubmit}
                    >
                        CREATE
                    </button>
                    <button
                        className='btn-border w-full mt-4'
                        onClick={() => {
                            setIsPopupOpen(false);
                            setSelectedFile();
                            setInputValue({ title: '' });
                            setError({ title: '', image: '' });
                        }}
                    >
                        CANCEL
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default AddCollection;
