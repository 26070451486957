import { Dialog, Listbox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAccount,
    useContractRead,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import _contracts_web3 from '../../configs/contracts.web3';
import { CONFIG } from '../../configs/platform.config';
import { notify } from '../../utils/common.helper';
import { parseRevertReason, toWei } from '../../utils/helper';

// COMPONENTS
import DateCalculator from '../FormControl/DateCalculator';
import Input from '../FormControl/Input';
import RadioButton from '../FormControl/RadioButton';
import ButtonLoader from '../Loader/ButtonLoader';
import Modal from './Modal';

// IMAGES
import moment from 'moment';
import CloseIcon from '../../assets/images/close-icon.svg';

function PutNftOnSalePopup(props) {
    const {
        isPopupOpen,
        setIsPopupOpen,
        nftQuantity,
        nftAddress,
        nftId,
        nftChainId,
        refetchNft,
        refetchActivity,
        nftType,
    } = props;

    const [inputValue, setInputValue] = useState({
        nftPrice: '',
        nftQuantity: '',
        auctionTime: '',
    });
    const [error, setError] = useState({
        nftPrice: '',
        nftQuantity: '',
        auctionTime: '',
    });
    const [putOnSale, setPutOnSale] = useState('sale');
    const [selectedToken, setSelectedToken] = useState({
        name: 'ETH',
        value: '0x0000000000000000000000000000000000000000',
        decimals: 18,
    });
    const [isEnabled, setIsEnabled] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const { status, switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            if (isEnabled) putNftOnSale();
            else setIsEnabled(true);
        },
    });
    const { address } = useAccount();
    const { chain } = useNetwork();
    const navigate = useNavigate();

    const putOnSaleOptions = [
        {
            name: 'Put on fixed sale',
            value: 'sale',
        },
        {
            name: 'Put on timed auction',
            value: 'auction',
        },
    ];

    // Contract call to put NFT on sale
    const {
        data,
        write: putOnSaleWrite,
        isLoading,
    } = useContractWrite({
        address: _contracts_web3.addresses[nftChainId]?.media,
        abi: _contracts_web3.abis.media,
        functionName: 'putOnSell',
        account: address,
        onError(error) {
            setIsApproved(false);
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const { isLoading: isSaleLoading, isSuccess: isSaleSuccess } =
        useWaitForTransaction({
            hash: data?.hash,
        });

    useEffect(() => {
        if (isSaleSuccess) {
            setIsPopupOpen(false);
            setInputValue({
                nftPrice: '',
                nftQuantity: '',
            });
            if (nftType === 'ERC1155') navigate('/my-nft');
            refetchNft();
            refetchActivity?.();
            notify('success', 'Sale created successfully');
        }
    }, [isSaleSuccess]);

    // Contract Call to put nft on auction
    const {
        data: auctionData,
        write: putOnAuctionWrite,
        isLoading: isAuctionLoading,
    } = useContractWrite({
        address: _contracts_web3.addresses[nftChainId]?.media,
        abi: _contracts_web3.abis.media,
        functionName: 'putOnAuction',
        account: address,
        onError(error) {
            setIsApproved(false);
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const {
        isLoading: isAuctionTransactionLoading,
        isSuccess: isAuctionSuccess,
    } = useWaitForTransaction({
        hash: auctionData?.hash,
    });

    useEffect(() => {
        if (isAuctionSuccess) {
            setIsPopupOpen(false);
            setInputValue({
                nftPrice: '',
                nftQuantity: '',
            });
            refetchNft();
            refetchActivity?.();
            notify('success', 'Auction created successfully');
        }
    }, [isAuctionSuccess]);

    // Check if NFT is approved for sale on marketplace
    const { isLoading: isGetApproveLoading, isFetching } = useContractRead({
        address: nftAddress,
        abi: _contracts_web3.abis[nftType],
        functionName: nftType === 'ERC721' ? 'getApproved' : 'isApprovedForAll',
        enabled: isEnabled,
        chainId: nftChainId,
        args:
            nftType === 'ERC721'
                ? [nftId]
                : [address, _contracts_web3.addresses[nftChainId]?.media],
        onSuccess(data) {
            const isERC721 = nftType === 'ERC721';
            const isMediaMatch =
                typeof data === 'boolean' || data === true
                    ? false
                    : data?.toLowerCase() ===
                    _contracts_web3.addresses[
                        nftChainId
                    ]?.media.toLowerCase();

            if ((isERC721 && isMediaMatch) || data === true) {
                setIsApproved(true);

                putNftOnSale();
            } else {
                approveWrite({
                    args: [
                        _contracts_web3.addresses[nftChainId]?.media,
                        isERC721 ? nftId : true,
                    ],
                });
            }

            setIsEnabled(false);
        },
        onError(error) {
            console.log(error);
            setIsEnabled(false);
        },
    });

    // Approve NFT for sale on marketplace
    const {
        data: approveData,
        write: approveWrite,
        isLoading: isApproveLoading,
    } = useContractWrite({
        address: nftAddress,
        abi: _contracts_web3.abis?.[nftType],
        functionName: nftType === 'ERC721' ? 'approve' : 'setApprovalForAll',
        account: address,
        chainId: nftChainId,
        onError(error) {
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const {
        isLoading: isApproveTransactionLoading,
        isSuccess: isApproveTransactionSuccess,
    } = useWaitForTransaction({
        hash: approveData?.hash,
    });

    useEffect(() => {
        if (isApproveTransactionSuccess) {
            setIsApproved(true);

            putNftOnSale();
        }
    }, [isApproveTransactionSuccess]);

    const handleApprove = async () => {
        if (!validator()) return;
        if (nftChainId !== chain.id) {
            await switchNetwork(nftChainId);
            if (status === 'success') {
                setIsEnabled(true);
            }
        } else {
            setIsEnabled(true);
        }
    };

    function validator() {
        let isFormValid = true;
        const error = {};
        if (!inputValue.nftPrice) {
            error.nftPrice = 'Please enter price';
            isFormValid = false;
        } else if (inputValue.nftPrice <= 0) {
            error.nftPrice = 'Price must be greater than 0';
            isFormValid = false;
        } else if ((nftChainId === 5 || nftChainId === 1) && !selectedToken) {
            error.nftPrice = 'Please select selling token';
            isFormValid = false;
        } else {
            error.nftPrice = '';
        }

        if (!inputValue.nftQuantity) {
            error.nftQuantity = 'Please enter quantity';
            isFormValid = false;
        } else if (inputValue.nftQuantity < 1) {
            error.nftQuantity = 'Quantity must be greater than or equal to 1';
            isFormValid = false;
        } else if (inputValue.nftQuantity > nftQuantity) {
            error.nftQuantity = `Quantity must be less than or equal to ${nftQuantity}`;
            isFormValid = false;
        } else {
            error.nftQuantity = '';
        }

        if (
            putOnSale === 'auction' &&
            inputValue.auctionTime <= moment().unix()
        ) {
            error.auctionTime = 'Please select future time';
            isFormValid = false;
        } else {
            error.auctionTime = '';
        }

        setError(error);
        return isFormValid;
    }

    function putNftOnSale() {
        const price = toWei(inputValue.nftPrice, selectedToken?.decimals);

        if (putOnSale === 'sale')
            putOnSaleWrite({
                args: [
                    nftAddress,
                    selectedToken.value,
                    nftId,
                    price,
                    inputValue.nftQuantity,
                ],
            });
        else {
            putOnAuctionWrite({
                args: [
                    nftAddress,
                    selectedToken.value,
                    nftId,
                    price,
                    inputValue.nftQuantity,
                    0,
                    inputValue.auctionTime,
                ],
            });
        }
    }

    return (
        <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
            <div className='flex justify-between items-start'>
                <Dialog.Title
                    as='h3'
                    className='text-3xl font-black font-darker text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2'
                >
                    Put NFT On Sale
                </Dialog.Title>
                <button
                    className='focus-visible:outline-none'
                    onClick={() => {
                        if (
                            isLoading ||
                            isSaleLoading ||
                            isAuctionLoading ||
                            isAuctionTransactionLoading
                        ) {
                            return setIsPopupOpen(false);
                        }

                        setInputValue({
                            nftPrice: '',
                            nftQuantity: '',
                        });
                        setError({
                            nftPrice: '',
                            nftQuantity: '',
                        });
                        setIsPopupOpen(false);
                    }}
                >
                    <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                </button>
            </div>
            <div className='mt-10'>
                <RadioButton
                    value={putOnSale}
                    onChange={setPutOnSale}
                    optionList={putOnSaleOptions}
                />
            </div>
            <div className='mt-6 relative'>
                <Input
                    placeholder='Enter Quantity'
                    label='Enter Quantity'
                    id='nftQuantity'
                    changeHandler={(e) => {
                        setInputValue((prev) => ({
                            ...prev,
                            nftQuantity:
                                e.target.value >= 0 ? e.target.value : '',
                        }));
                    }}
                    type={'number'}
                    value={inputValue.nftQuantity}
                    error={error.nftQuantity}
                    isRequired={true}
                />
            </div>
            <div className='mt-6 relative'>
                <Input
                    placeholder='Enter Price'
                    label='Enter Price'
                    id='nftPrice'
                    changeHandler={(e) => {
                        setInputValue((prev) => ({
                            ...prev,
                            nftPrice: e.target.value >= 0 ? e.target.value : '',
                        }));
                    }}
                    type={'number'}
                    value={inputValue.nftPrice}
                    error={error.nftPrice}
                    isRequired={true}
                    textLimit={20}
                />
                {(nftChainId === 1 || nftChainId === 5) && (
                    <div className='absolute right-0 top-10 -mt-0.5 z-10'>
                        <Listbox
                            defaultValue={selectedToken}
                            onChange={setSelectedToken}
                        >
                            <div className='relative'>
                                <Listbox.Button className='w-full flex justify-between items-center relative cursor-pointer py-2 px-2 text-left text-sm font-bold text-dark-60 focus:outline-none xs:w-full'>
                                    <span className='block truncate'>
                                        {selectedToken?.name ? (
                                            <span className='text-black'>
                                                {selectedToken?.name}
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                    <span className='pointer-events-none inset-y-0 pr-0'>
                                        <img
                                            src={CollapseIcon}
                                            alt='CollapseIcon'
                                            className='mr-0'
                                        ></img>
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                                    enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                                    enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 translate-y-0'
                                    leaveTo='transform opacity-0 translate-y-2'
                                >
                                    <Listbox.Options className='dropdown mt-1 w-40 text-base right-0 focus:outline-none sm:text-sm'>
                                        {CONFIG.supportedTokens?.map(
                                            (option, optionIdx) => (
                                                <Listbox.Option
                                                    key={optionIdx}
                                                    className={({ active }) =>
                                                        `relative text-base font-medium cursor-pointer py-3 pl-5 pr-4 hover:bg-primary/20 ${active
                                                            ? 'bg-primary/20'
                                                            : ''
                                                        }`
                                                    }
                                                    value={option}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span className='block truncate'>
                                                                {option.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            )
                                        )}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                )}
            </div>
            {putOnSale === 'auction' && (
                <div className='mt-6'>
                    <label className='block text-sm font-semibold text-dark-60'>
                        Select Auction End Time
                    </label>
                    <DateCalculator
                        setInputValue={setInputValue}
                        error={error.auctionTime}
                    />
                </div>
            )}
            <div className='mt-6'>
                <button
                    className='btn-secondary w-full'
                    onClick={handleApprove}
                    disabled={
                        isLoading ||
                        isSaleLoading ||
                        isAuctionTransactionLoading ||
                        isAuctionLoading ||
                        isApproveLoading ||
                        isApproveTransactionLoading ||
                        isGetApproveLoading ||
                        isFetching
                    }
                >
                    {(isLoading ||
                        isSaleLoading ||
                        isAuctionTransactionLoading ||
                        isAuctionLoading ||
                        isApproveLoading ||
                        isApproveTransactionLoading ||
                        isGetApproveLoading ||
                        isFetching) && <ButtonLoader />}
                    CONFIRM
                </button>

                <button
                    className='btn-border w-full mt-4'
                    onClick={() => setIsPopupOpen(false)}
                >
                    CANCEL
                </button>
            </div>
        </Modal>
    );
}

export default PutNftOnSalePopup;
