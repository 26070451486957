import { useQuery } from '@tanstack/react-query';
import { ethers } from 'ethers';
import React from 'react';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { getSearch } from '../../api/adapters/asset-common';
import { smallCardSettings } from '../../configs/slider.config';
import { toggleLoader } from '../../redux/actions/loader';

// COMPONENTS
import CardMedium from '../../components/CardMedium/CardMedium';
import CardSmall from '../../components/CardSmall/CardSmall';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { trimEthereumAddress } from '../../utils/helper';

// IMAGES
import { Tooltip } from 'react-tooltip';

function Asset() {
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(window.location.search);
    const searchQuery = queryParams.get('q');

    const { data: searchData } = useQuery({
        queryKey: ['searchData', searchQuery],
        queryFn: () =>
            getSearch(searchQuery, {
                start: 0,
                length: 1000,
            }).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                return res.data.data;
            }),
    });

    return (
        <>
            {searchData?.aNFT.length || searchData?.aUser.length ? (
                <>
                    <section>
                        <div className='container section-space-small'>
                            <div className='text-center'>
                                <h2 className='main-heading-h2 -mt-7 md:-mt-2'>
                                    Search results for{' '}
                                    <q
                                        data-tooltip-id='my-tooltip'
                                        data-tooltip-content={searchQuery}
                                    >
                                        {ethers.utils.isAddress(searchQuery)
                                            ? trimEthereumAddress(
                                                searchQuery,
                                                6
                                            )
                                            : searchQuery}
                                    </q>
                                </h2>
                                {ethers.utils.isAddress(searchQuery) && (
                                    <Tooltip
                                        place='bottom'
                                        type='dark'
                                        effect='float'
                                        id='my-tooltip'
                                    />
                                )}
                            </div>
                            <section className=''>
                                {searchData?.aNFT && (
                                    <div
                                        className={`flex-1 relative grid grid-cols-4 gap-x-5 gap-y-10 pt-20 xl:grid-cols-3 md:grid-cols-2 sm:flex sm:flex-wrap sm:gap-y-7 xs:pt-16`}
                                    >
                                        {searchData?.aNFT &&
                                            searchData.aNFT.map((item, i) => {
                                                return (
                                                    <div
                                                        key={item._id}
                                                        className='sm:w-full'
                                                    >
                                                        <CardMedium
                                                            data={item}
                                                            chainId={
                                                                item.nChainId
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </section>
                        </div>
                    </section>
                    <section className='bg-darkGray'>
                        <div className='container section-space'>
                            <div className='flex items-center justify-between mb-8 xs:flex-col xs:items-start xs:gap-4'>
                                <h2 className='main-heading-h2'>Users</h2>
                            </div>
                            {searchData && (
                                <Slider {...smallCardSettings}>
                                    {searchData?.aUser?.length &&
                                        searchData?.aUser.map((item, i) => {
                                            return (
                                                <div key={i} className='p-2.5'>
                                                    <CardSmall data={item} />
                                                </div>
                                            );
                                        })}
                                </Slider>
                            )}
                        </div>
                    </section>
                </>
            ) : (
                <section>
                    <div className='container section-space-small'>
                        <div className='text-center'>
                            <h2 className='main-heading-h2 -mt-7 md:-mt-2'>
                                Search results for "{searchQuery}"
                            </h2>
                        </div>
                    </div>
                    <div className='w-full mt-14 mb-20'>
                        <NoDataFound />
                    </div>
                </section>
            )}
        </>
    );
}

export default Asset;
