import { RadioGroup } from '@headlessui/react';
import React from 'react';

function RadioButton(props) {
    const { value, onChange, optionList, onBlur } = props;

    return (
        <RadioGroup
            defaultValue={value}
            onChange={onChange}
            className='flex flex-wrap gap-x-10 gap-y-3'
            onBlur={onBlur}
        >
            {optionList.length &&
                optionList.map((item, i) => {
                    return (
                        <RadioGroup.Option
                            value={item.value ? item.value : item.name}
                            className='flex gap-2 items-center cursor-pointer '
                            key={i}
                        >
                            {({ checked }) => (
                                <>
                                    <span
                                        className={`w-6 h-6 rounded-full border-[3px] border-dark-40 inline-block ${
                                            checked
                                                ? '!border-black bg-black shadow-[inset_0_0px_0px_2px_rgba(255,255,255,1)] '
                                                : 'text-black '
                                        }`}
                                    ></span>
                                    <span className='text-base text-dark-90 sm:text-sm'>
                                        {item.name}
                                    </span>
                                </>
                            )}
                        </RadioGroup.Option>
                    );
                })}
        </RadioGroup>
    );
}

export default RadioButton;
