import { Dialog } from '@headlessui/react';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useAccount,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';

// IMAGES
import CloseIcon from '../../assets/images/close-icon.svg';
import _contracts_web3 from '../../configs/contracts.web3';
import { notify } from '../../utils/common.helper';
import { parseRevertReason } from '../../utils/helper';
import ButtonLoader from '../Loader/ButtonLoader';

// COMPONENTS
import Input from '../FormControl/Input';
import Modal from './Modal';

function TransferNFTPopup(props) {
    const {
        isPopupOpen,
        setIsPopupOpen,
        refetchNft,
        refetchActivity,
        nftChainId,
        nftId,
        nftType,
        nftAddress,
        isRedirect,
        nftQuantity,
    } = props;

    const [inputValue, setInputValue] = useState({
        walletAddress: '',
        nftQuantity: '',
    });
    const [error, setError] = useState({
        walletAddress: '',
        nftQuantity: '',
    });

    const navigate = useNavigate();
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            const args =
                nftType === 'ERC721'
                    ? [address, inputValue.walletAddress, nftId]
                    : [
                        address,
                        inputValue.walletAddress,
                        nftId,
                        inputValue.nftQuantity,
                        '',
                    ];
            write({ args });
        },
    });

    // Transfer NFT to another wallet
    const { data, write, isLoading } = useContractWrite({
        address: nftAddress,
        abi: _contracts_web3.abis?.[nftType],
        functionName: 'safeTransferFrom',
        account: address,
        onError(error) {
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const { isLoading: isTransactionLoading, isSuccess: isTransactionSuccess } =
        useWaitForTransaction({
            hash: data?.hash,
        });

    useEffect(() => {
        if (isTransactionSuccess) {
            setIsPopupOpen(false);
            if (isRedirect) navigate('/my-nft');
            refetchNft();
            refetchActivity?.();
            notify('success', 'NFT Transferred successfully');
        }
    }, [isTransactionSuccess]);

    const validator = () => {
        return new Promise((resolve, reject) => {
            const { walletAddress, nftQuantity: quantity } = inputValue;
            const error = {};

            if (!quantity) {
                error.nftQuantity = 'Please enter quantity';
            } else if (quantity < 1) {
                error.nftQuantity = 'Quantity must be greater than 0';
            } else if (quantity > nftQuantity) {
                error.nftQuantity =
                    `Quantity should be less than or equal to ${nftQuantity} available quantity`;
            }

            if (!walletAddress) {
                error.walletAddress = 'Please enter wallet address';
            } else if (!ethers.utils.isAddress(walletAddress)) {
                error.walletAddress = 'Invalid wallet address';
            } else if (address.toLowerCase() === walletAddress.toLowerCase()) {
                error.walletAddress = 'You cannot transfer NFT to yourself';
            }

            if (Object.keys(error).length > 0) {
                setError(error);
                reject(error);
            } else {
                resolve(true);
                setError({ walletAddress: '', nftQuantity: '' });
            }
        });
    };

    const handleSubmit = async () => {
        try {
            const { nftQuantity, walletAddress } = inputValue;
            await validator();
            const args =
                nftType === 'ERC721'
                    ? [address, walletAddress, nftId]
                    : [address, walletAddress, nftId, nftQuantity, ''];

            if (nftChainId !== chain.id) {
                await switchNetwork(nftChainId);
            } else {
                write({ args });
                setError({ walletAddress: '', nftQuantity: '' });
            }
        } catch (error) { }
    };

    return (
        <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
            <div className='flex justify-between items-start'>
                <Dialog.Title
                    as='h3'
                    className='text-3xl font-black font-darker text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2'
                >
                    Transfer NFT
                </Dialog.Title>
                <button
                    className='focus-visible:outline-none'
                    onClick={() => setIsPopupOpen(false)}
                >
                    <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                </button>
            </div>

            <div className='mt-2'>
                <p className='text-base text-dark-70'>
                    Enter the user’s wallet address to whom you want to transfer
                    the NFT.
                </p>
            </div>
            <div className='mt-7'>
                <Input
                    placeholder='Enter Wallet Address'
                    label='Wallet Address'
                    id='walletAddress'
                    changeHandler={(e) => {
                        setInputValue((prev) => ({
                            ...prev,
                            walletAddress: e.target.value,
                        }));
                    }}
                    handleSubmit={handleSubmit}
                    value={inputValue.walletAddress}
                    type='text'
                    error={error.walletAddress}
                    isWalletAddress={true}
                    isRequired={true}
                />
            </div>

            <div className='mt-7'>
                <Input
                    placeholder='Enter NFT Quantity'
                    label='NFT Quantity'
                    id='nftQuantity'
                    changeHandler={(e) => {
                        setInputValue((prev) => ({
                            ...prev,
                            nftQuantity: e.target.value,
                        }));
                    }}
                    handleSubmit={handleSubmit}
                    value={inputValue.nftQuantity}
                    type='number'
                    error={error.nftQuantity}
                    isRequired={true}
                />
            </div>

            <div className='mt-5'>
                <button
                    className='btn-secondary w-full'
                    onClick={handleSubmit}
                    disabled={isLoading || isTransactionLoading}
                >
                    {(isLoading || isTransactionLoading) && <ButtonLoader />}
                    CONFIRM
                </button>
            </div>
        </Modal>
    );
}

export default TransferNFTPopup;
