import React from 'react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    // Use this function to send error to Error Reporting service
    // componentDidCatch(error, info) {}

    render() {
        return this.state.hasError ? this.props.fallback : this.props.children;
    }
}
