import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CONFIG } from '../../configs/platform.config';
import { notify } from '../../utils/common.helper';
import { getToken } from '../../utils/cookies.helper';
import {
    chainSymbolById,
    formatNumber,
    formatNumberWithK,
} from '../../utils/helper';

// COMPONENTS
import Loader from '../Loader/Loader';

// IMAGES
import BSC from '../../assets/images/Binance-icon.svg';
import BOBA from '../../assets/images/Boba-icon.svg';
import ETH from '../../assets/images/Ethereum-icon.svg';
import MATIC from '../../assets/images/Polygon-icon.svg';
import Arrow from '../../assets/images/arrow.svg';
import CreateIcon from '../../assets/images/create-icon.svg';
import FeaturedIcon from '../../assets/images/featured-icon.svg';
import NextArrow from '../../assets/images/next-arrow.svg';
import OMI from '../../assets/images/omi-icon.svg';
import USDC from '../../assets/images/usd-icon.svg';
function HomeBanner(props) {
    const { images, nTotalArtWorks, totalAuctionNft, totalArtist } = props;
    const navigate = useNavigate();
    const [cardsCount, setCardsCount] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [allCards, setAllCards] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);

    const loader = useSelector((state) => state.loader);
    const price = useSelector((state) => state.price);

    const modelRef = useRef();

    const chainIcon = {
        5: ETH,
        97: BSC,
        137: MATIC,
        80001: MATIC,
        2888: BOBA,
        1442: MATIC,
        ETH: ETH,
        BNB: BSC,
        MATIC: MATIC,
        USDC: USDC,
        BOBA: BOBA,
        OMI: OMI,
    };

    useEffect(() => {
        let cards = document.querySelectorAll('.banner-slide');
        setAllCards(document.querySelectorAll('.banner-slide'));

        let count = cards.length;
        setCardsCount(cards.length);

        for (let i = 0; i < count; i++) {
            cards[i].style.right = `${i * 20}%`; // Spread the cards, last div on the most left
            cards[i].style.top = `-${i * 2}rem`; // Spread the cards, last div on the most left
            cards[i].style.pointerEvents = 'none';
            if (count === i + 1) {
                cards[i].style.pointerEvents = 'auto';
            }
        }

        setCurrentIndex(count - 1); // Last div is the current one

        if (images?.length) {
            const urls = images.map(item =>
                item?.sNftCloudinaryLink || `${CONFIG.sIpfsUri}${item?.sImageHash}`
            );
            setImageUrls(urls);
        }

    }, [images]);

    const handleError = (index) => {
        const newImageUrls = [...imageUrls];
        newImageUrls[index] = `${CONFIG.sEpikoIpfsUri}${images[index]?.sImageHash}`; // Your fallback image URL
        setImageUrls(newImageUrls);
    };

    function nextSlide() {
        let nextIndex;
        let count = cardsCount - 1;

        if (currentIndex === 0) {
            // If current index is 0 , it means first div on the most left now
            // Reset the index and zIndex values
            nextIndex = count; // Next one is the last div

            for (let i = 0; i < cardsCount; i++) {
                allCards[i].style.zIndex = '1';
                allCards[i].style.pointerEvents = 'none';
            }
        } else {
            nextIndex = currentIndex - 1; // Next one is the 1 div before the current div
        }

        // Move next card to top

        allCards[nextIndex].style.right = `${count * 20}%`;
        allCards[nextIndex].style.zIndex = '2';
        allCards[nextIndex].style.pointerEvents = 'auto';
        allCards[nextIndex].style.top = `-${count * 2}rem`;

        // allCardImages[nextIndex].style.transform = 'none';

        // Move current card to end
        allCards[currentIndex].style.right = `0`;
        allCards[currentIndex].style.top = `0`;
        if (currentIndex !== 0) {
            allCards[currentIndex].style.zIndex = '0';
            allCards[currentIndex].style.pointerEvents = 'none';
        } else {
            allCards[currentIndex].style.zIndex = '1';
            allCards[currentIndex].style.pointerEvents = 'none';
        }
        // allCardImages[currentIndex].style.transform = 'rotateY(-20deg)';

        //Move other cards to 5rem left
        for (let i = 0; i < cardsCount; i++) {
            if (i !== currentIndex && i !== nextIndex) {
                let currentPosition = parseInt(allCards[i].style.right); // Get the current right value of a card
                let newPosition = currentPosition + 5;
                allCards[i].style.right = `${newPosition * 4 + 1}%`;
                allCards[i].style.top = `-${newPosition - 3}rem`;
            }
        }

        //Update the current index
        setCurrentIndex(nextIndex);
    }

    return (
        <section>
            <div className='container flex justify-between section-space md:flex-col'>
                <div className='w-1/2 pr-16 3xl:pr-0 md:w-full'>
                    <h3 className='text-4xl 3xl:text-3xl xl:text-2xl font-bold sm:text-xl'>
                        Collect. Trade. Discover
                    </h3>
                    <h1 className='main-heading-h1'>
                        Extraordinary & Exclusive NFTs
                    </h1>
                    <p className='text-base text-gray font-bold mt-4 sm:text-sm'>
                        Welcome to the world of rare digital art. explore the
                        best art from hand-picked digital artist out there and
                        find the hidden gem.
                    </p>
                    <div className='flex my-12 items-center gap-6 md:my-8 sm:gap-5 xs:gap-3'>
                        <Link to='/all-brands' className='btn-primary'>
                            DISCOVER
                            <img src={Arrow} alt='Arrow' className='ml-3' />
                        </Link>
                        <Link
                            to='/create-nft'
                            className='group btn-secondary'
                            onClick={(e) => {
                                e.preventDefault();
                                if (getToken()) navigate('/create-nft');
                                else notify('error', 'Please Connect Wallet!');
                            }}
                        >
                            CREATE
                            <img
                                src={CreateIcon}
                                alt='CreateIcon'
                                className='ml-3 duration-300 group-hover:invert'
                            />
                        </Link>
                    </div>
                    <div className='flex gap-16 xs:gap-8'>
                        <div>
                            <h4 className='text-[48px] xl:text-[32px] font-bold text-darkgreen'>
                                {formatNumberWithK(nTotalArtWorks || 0)}
                                <span className='text-primary'>+</span>
                            </h4>
                            <p className='text-sm text-dark-50 font-bold'>
                                artwork
                            </p>
                        </div>
                        <div>
                            <h4 className='text-[48px] xl:text-[32px] font-bold text-darkgreen'>
                                {formatNumberWithK(totalAuctionNft || 0)}
                                <span className='text-secondary'>+</span>
                            </h4>
                            <p className='text-sm text-dark-50 font-bold'>
                                auction
                            </p>
                        </div>
                        <div>
                            <h4 className='text-[48px] xl:text-[32px] font-bold text-darkgreen'>
                                {formatNumberWithK(totalArtist || 0)}
                                <span className='text-tertiary'>+</span>
                            </h4>
                            <p className='text-sm text-dark-50 font-bold'>
                                artist
                            </p>
                        </div>
                    </div>
                </div>

                {/* BANNER SLIDER */}
                <div className='w-[45%] pr-12 2xl:pr-0 relative md:w-full md:mt-9 xs:w-1/2 xs:ml-auto'>
                    <div className='relative w-full mt-16 md:pt-[80%] sm::pt-[85%] xs:pt-[230%]'>
                        {loader && (
                            <div className='absolute top-0 left-1/2 mt-40 sm:-left-14'>
                                <Loader />
                            </div>
                        )}

                        {images?.length
                            ? images.map((image, index) => {
                                const cryptoPrice = Number(
                                    image?.aTransactions?.nBidPrice ||
                                    image.nBasePrices ||
                                    0
                                );

                                const cryptoSymbol =
                                    image.aTransactions?.sTokenSymbol ||
                                    chainSymbolById(image?.nChainId);

                                const convertedPrice =
                                    cryptoPrice * price?.[cryptoSymbol];


                                return (
                                    <div
                                        key={image.sImageHash}
                                        className='banner-slide w-3/5 lg:w-2/3 md:w-3/5 xs:w-[160%] absolute z-[1] duration-300 bg-white border border-dark-30 rounded-xl p-2 shadow-[25px_25px_50px_0px_rgba(0,0,0,0.15)] pointer-events-none'
                                    >
                                        <Link
                                            to={`/nft/${image._id}`}
                                            className='absolute w-full h-full left-0 top-0 z-10'
                                        ></Link>
                                        <div className='relative pt-[100%]'>
                                            {image.eType === 'image' ? (
                                                <img
                                                    src={imageUrls[index]}
                                                    alt='BannerImage1'
                                                    className='absolute w-full h-full object-cover object-center rounded-xl top-0 left-0'
                                                    onError={() => handleError(index)}
                                                />
                                            ) : image.eType === 'model' ? (
                                                <model-viewer
                                                    alt={image.title}
                                                    src={imageUrls[index]}
                                                    ar
                                                    autoplay
                                                    // environment-image='shared-assets/environments/moon_1k.hdr'
                                                    poster={imageUrls[index]}
                                                    shadow-intensity='1'
                                                    camera-controls={false}
                                                    ref={(ref) => {
                                                        modelRef.current =
                                                            ref;
                                                    }}
                                                    touch-action='pan-y'
                                                    class='absolute w-full h-full object-cover object-center rounded-xl top-0 left-0'
                                                    onError={() => handleError(index)}
                                                ></model-viewer>
                                            ) : (
                                                <video
                                                    src={imageUrls[index]}
                                                    alt='BannerImage1'
                                                    className='absolute w-full h-full object-cover object-center rounded-xl top-0 left-0'
                                                    onError={() => handleError(index)}
                                                />
                                            )}
                                            <div className='text-xs text-white font-bold py-2 px-3 absolute right-5 top-5 bg-black/[0.5] rounded pointer-events-none'>
                                                <img
                                                    src={FeaturedIcon}
                                                    alt='FeaturedIcon'
                                                    className='inline-block align-bottom mr-2'
                                                />
                                                Featured
                                            </div>
                                        </div>
                                        <div className='flex justify-between items-center pt-5 pb-3 px-3 xl:flex-col xl:items-start'>
                                            <div className='flex items-center'>
                                                <div className='w-12 h-12 relative mr-2 xl:w-10 xl:h-10'>
                                                    {image.aOwner
                                                        .sProfilePicUrl ? (
                                                        <img
                                                            src={
                                                                image.aOwner
                                                                    .sProfilePicUrl
                                                            }
                                                            alt='BrandLogo'
                                                            className='absolute rounded-full w-full h-full object-cover object-center'
                                                        />
                                                    ) : (
                                                        <div className='absolute rounded-full w-full h-full object-cover object-center bg-gradient'></div>
                                                    )}
                                                </div>
                                                <div>
                                                    <h6 className='text-lg xl:text-base text-black font-bold'>
                                                        {
                                                            image.aOwner
                                                                .sUserName
                                                        }
                                                    </h6>
                                                    <p className='text-xs text-dark-70'>
                                                        {image?.aTransactions
                                                            ?.nSaleQuantity ||
                                                            image.nQuantity}{' '}
                                                        in Stock
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='text-right xl:text-left xl:pt-2'>
                                                <p className='text-sm text-black font-bold'>
                                                    <img
                                                        src={
                                                            chainIcon[
                                                            image
                                                                ?.aTransactions
                                                                ?.sTokenSymbol ||
                                                            chainSymbolById(
                                                                image?.nChainId
                                                            )
                                                            ]
                                                        }
                                                        alt='OmiIcon'
                                                        className='inline-block align-bottom mr-1 w-[26px]'
                                                    />
                                                    {formatNumber(
                                                        image?.aTransactions
                                                            ?.nBidPrice ||
                                                        image.nBasePrice
                                                    )}{' '}
                                                    {chainSymbolById(
                                                        image.nChainId
                                                    )}
                                                </p>
                                                <p className='text-xs text-dark-70'>
                                                    ~
                                                    {convertedPrice.toFixed(
                                                        2
                                                    )}{' '}
                                                    USD
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                            : null}
                    </div>
                    {images?.length ? (
                        <div
                            onClick={() => nextSlide()}
                            className='p-4 top-1/2 left-2/3 -translate-x-[200%] -translate-y-full 2xl:-translate-x-[120%] bg-black absolute z-10 rounded-full inline-block cursor-pointer xs:-translate-x-[92%]'
                        >
                            <img src={NextArrow} alt='NextArrow' />
                        </div>
                    ) : null}
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
