import { apiClient } from '../client/api-client';

export const getCollection = async () => {
    let url = `/user/get-collection-details`;
    return await apiClient({ url, method: 'POST' });
};

export const addCollection = async (data) => {
    let url = `/user/add-collection`;
    return await apiClient({ url, method: 'POST', data });
};

export const editCollection = async (data) => {
    let url = `/user/edit-collection`;
    return await apiClient({ url, method: 'PATCH', data });
};

export const getUserCollection = async (id) => {
    let url = `/user/get-user-collection/${id}`;
    return await apiClient({ url, method: 'GET' });
};

export const getAllCollections = async (data) => {
    let url = `/user/get-all-collection`;
    return await apiClient({ url, method: 'POST', data });
};

export const getSingleCollection = async (id) => {
    let url = `/user/get-single-collection/${id}`;
    return await apiClient({ url, method: 'GET' });
};

export const getCollectionDetail = async (data) => {
    let url = `/user/get-collection-nfts`;
    return await apiClient({ url, method: 'POST', data });
};
