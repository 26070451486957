import { Disclosure, Transition } from '@headlessui/react';
import React from 'react';

// IMAGES
import CollapseIcon from '../../assets/images/collapse-arrow.svg';

function FaqsList(props) {
    const { data } = props;
    return (
        <Disclosure
            as='div'
            className='bg-dark-10 border border-dark-30 rounded-md mb-5'
        >
            {({ open }) => (
                <>
                    <Disclosure.Button className='text-md font-bold text-left text-black w-full px-5 py-4 flex justify-between items-center lg:text-base sm:text-sm'>
                        {data.sQuestion}
                        <div
                            className={`duration-300 
                                ${open ? 'rotate-180 transform ' : ''}
                            `}
                        >
                            <img src={CollapseIcon} alt='CollapseIcon' />
                        </div>
                    </Disclosure.Button>

                    <Transition
                        show={open}
                        enter='transition duration-100 ease-out'
                        enterFrom='transform scale-95 opacity-0'
                        enterTo='transform scale-100 opacity-100'
                        leave='transition duration-75 ease-out'
                        leaveFrom='transform scale-100 opacity-100'
                        leaveTo='transform scale-95 opacity-0'
                        className='px-5 pb-5'
                    >
                        <Disclosure.Panel className='text-dark-70 text-base sm:text-sm'>
                            <p>{data.sAnswer}</p>
                        </Disclosure.Panel>
                    </Transition>
                </>
            )}
        </Disclosure>
    );
}

export default FaqsList;
