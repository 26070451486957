import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// COMPONENTS

// IMAGES
import { getCmsPage } from '../../api/adapters/asset-common';
import ArrowIcon from '../../assets/images/next-arrow.svg';
import { goBack } from '../../utils/helper';

function AboutUs() {
    const navigate = useNavigate();
    const location = useNavigate();

    const { data: aboutUs } = useQuery({
        queryKey: ['aboutUs'],
        queryFn: () =>
            getCmsPage({ sPageName: 'About Us' }).then((res) => res.data.data),
    });

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center relative'>
                    <h1 className='main-heading-h2 sm:mt-3'>About us</h1>
                    <button
                        className='group btn-border absolute top-6 left-0 lg:top-5 md:top-3 sm:-top-8 sm:py-2'
                        onClick={(e) => goBack(navigate, location)}
                    >
                        <img
                            src={ArrowIcon}
                            alt='ArrowIcon'
                            className='rotate-180 invert mr-2 group-hover:invert-0 sm:w-5'
                        />
                        Back
                    </button>
                </div>
                <div className='mt-16 md:mt-10 sm:mt-8'>
                    <div className='text-base text-dark-70 flex flex-col gap-y-4 mt-14 md:text-sm sm:mt-10'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: aboutUs?.sDescription,
                            }}
                        />
                    </div>
                </div>

                {/* <div className='mt-16 md:mt-10 sm:mt-8'>
                    <div className='rounded-xl overflow-hidden border border-dark-20 relative pt-[30.5%] sm:pt-[50%]'>
                        <img
                            src={AboutBanner}
                            alt='AboutBanner'
                            className='absolute left-0 top-0 w-full h-full object-cover object-center'
                        />
                    </div>
                    <div className='flex items-center gap-5 mt-20 md:flex-col md:mt-12 sm:mt-10'>
                        <h4 className='text-3xl font-black text-black font-darker w-1/2 lg:text-2.5xl md:w-full sm:text-xl'>
                            Create, Sell well & Collect your Wonderful NFTs at E
                            Very Fast
                        </h4>
                        <div className='border border-dark-20 rounded-xl p-10 w-1/2 lg:p-5 md:w-full'>
                            <p className='uppercase text-xl font-medium text-dark-90 font-darker 2xl:text-lg lg:text-md sm:text-sm'>
                                The NFTs is a one-trick pony that climbed the
                                ladders of success in recent years. The growth
                                of NFTs is tremendous, and according to
                                Pymnts.com, the total sales volume of NFTs has
                                nearly crossed $2.5 billion in the last six
                                months of . Surprisingly, the total sales volume
                                of NFTs was $13.7 million in 2020. On comparing
                                both the values,
                            </p>
                        </div>
                    </div>
                    <div className='text-base text-dark-70 flex flex-col gap-y-4 mt-14 md:text-sm sm:mt-10'>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including version.
                        </p>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including version.
                        </p>
                        <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock, a Latin
                        </p>
                        <p>
                            Tomorrowland NFTs go beyond merely being digital
                            art. Giving the festival’s devotees a strong sense
                            of ownership, holders of these digital treasures now
                            have the power to access exclusive shows, secure
                            pre-sale tickets and partake in incomparable
                            interactions. On top of that, the virtual assets
                            automatically grant access to numerous partnerships
                            — a win-win all year round.
                        </p>
                        <p className='mt-10'>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including version.
                        </p>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including version.
                        </p>
                        <p>
                            Contrary to popular belief, Lorem Ipsum is not
                            simply random text. It has roots in a piece of
                            classical Latin literature from 45 BC, making it
                            over 2000 years old. Richard McClintock, a Latin
                        </p>
                        <p>
                            Tomorrowland NFTs go beyond merely being digital
                            art. Giving the festival’s devotees a strong sense
                            of ownership, holders of these digital treasures now
                            have the power to access exclusive shows, secure
                            pre-sale tickets and partake in incomparable
                            interactions. On top of that, the virtual assets
                            automatically grant access to numerous partnerships
                            — a win-win all year round.
                        </p>
                    </div>
                </div> */}
            </div>
        </section>
    );
}

export default AboutUs;
