import { UPDATE_PRICE } from '../../constants/redux-events';

const initialState = {
    BOBA: 0,
    BNB: 0,
    ETH: 0,
    MATIC: 0,
};

const loader = (state = initialState, { type, payload }) => {
    switch (type) {
        case UPDATE_PRICE:
            return payload;
        default:
            return state;
    }
};

export default loader;
