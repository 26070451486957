import { Listbox, Transition } from '@headlessui/react';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// IMAGES
import CollapseIcon from '../../assets/images/collapse-arrow.svg';

const DateCalculator = (props) => {
    const { setInputValue, error } = props;
    const todayDate = new Date();

    const defaultDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
    const [selectedDate, setSelectedDate] = useState(defaultDate);
    const [selectedInterval, setSelectedInterval] = useState('1d');
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [dateDifference, setDateDifference] = useState(null);
    const calenderRef = useRef();

    const dateOptions = [
        { value: '1d', label: '1 Day' },
        { value: '7d', label: '7 Days' },
        { value: '1m', label: '1 Month' },
        { value: '3m', label: '3 Months' },
        { value: 'custom', label: 'Custom Date' },
    ];

    useEffect(() => {
        const epochTime = moment(
            selectedDate,
            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).valueOf();

        setInputValue((prev) => ({
            ...prev,
            auctionTime: Math.round(epochTime / 1000),
        }));
    }, []);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        calculateDateDifference(date);

        const epochTime = moment(
            date,
            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).valueOf();

        setInputValue((prev) => ({
            ...prev,
            auctionTime: Math.round(epochTime / 1000),
        }));
    };

    const calculateDateDifference = (selectedDate) => {
        if (selectedDate) {
            const currentDate = new Date();
            const differenceInMilliseconds = selectedDate - currentDate;
            const differenceInHours =
                differenceInMilliseconds / (60 * 60 * 1000);
            const differenceInDays = Math.floor(
                differenceInMilliseconds / (24 * 60 * 60 * 1000)
            );
            const differenceInMonths = Math.floor(differenceInDays / 30);

            if (
                differenceInHours < 0 ||
                differenceInDays < 0 ||
                differenceInMonths < 0
            ) {
                setDateDifference();
            } else if (differenceInHours < 24) {
                setDateDifference(`${Math.floor(differenceInHours)} hours`);
            } else if (differenceInDays < 30) {
                setDateDifference(`${differenceInDays} days`);
            } else {
                setDateDifference(`${differenceInMonths} months`);
            }
        }
    };

    useEffect(() => {
        calculateDateDifference(selectedDate);
    }, []);

    const handleIntervalChange = (event) => {
        const interval = event;

        // If the selected option is 'custom', open the date picker
        if (interval.value === 'custom') {
            setIsDatePickerOpen(true);
        }

        // Update the selected date based on the selected interval
        const currentDate = new Date();
        let newDate;

        switch (interval.value) {
            case '1d':
                newDate = new Date(
                    currentDate.setDate(currentDate.getDate() + 1)
                );
                break;
            case '7d':
                newDate = new Date(
                    currentDate.setDate(currentDate.getDate() + 7)
                );
                break;
            case '1m':
                newDate = new Date(
                    currentDate.setMonth(currentDate.getMonth() + 1)
                );
                break;
            case '3m':
                newDate = new Date(
                    currentDate.setMonth(currentDate.getMonth() + 3)
                );
                break;
            default:
                newDate = selectedDate;
        }

        setSelectedDate(newDate);
        calculateDateDifference(newDate);
        setSelectedInterval(interval);
        const epochTime = moment(
            newDate,
            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)'
        ).valueOf();

        setInputValue((prev) => ({
            ...prev,
            auctionTime: Math.round(epochTime / 1000),
        }));
    };

    return (
        <div className='mt-2'>
            <Listbox
                defaultValue={selectedInterval}
                name='selectedInterval'
                onChange={handleIntervalChange}
            >
                <div className='relative'>
                    <div className='h-0'>
                        <DatePicker
                            ref={calenderRef}
                            selected={selectedDate}
                            onChange={handleDateChange}
                            timeFormat='HH:mm'
                            timeIntervals={15}
                            dateFormat='MM.dd.yyyy h:mm aa'
                            open={isDatePickerOpen}
                            timeInputLabel='Select time:'
                            showTimeInput
                            minDate={new Date()}
                            onClickOutside={() => setIsDatePickerOpen(false)}
                            className='border-none p-0 h-4 translate-x-5 translate-y-4 relative z-10 text-left text-base bg-transparent font-medium text-black focus:outline-none focus:ring-0 focus:!shadow-none'
                        >
                            <button
                                className='btn-secondary btn-sm'
                                onClick={() => {
                                    setIsDatePickerOpen(false);
                                }}
                            >
                                Apply
                            </button>
                            <button
                                className='btn-border btn-sm'
                                onClick={() => {
                                    setSelectedDate(defaultDate);
                                    setIsDatePickerOpen(false);
                                }}
                            >
                                Cancel
                            </button>
                        </DatePicker>
                    </div>
                    <Listbox.Button className='w-full flex justify-end items-center relative cursor-pointer rounded-xl bg-dark-10 border border-dark-30 py-3.5 px-4 text-left text-sm font-bold text-dark-60 focus:outline-none xs:w-full'>
                        <div className='flex flex-shrink-0'>
                            <span className='block truncate mr-3'>
                                {selectedInterval.label === dateDifference
                                    ? selectedInterval.label
                                    : dateDifference}
                            </span>
                            <span className='pointer-events-none inset-y-0 pr-0'>
                                <img
                                    src={CollapseIcon}
                                    alt='CollapseIcon'
                                    className='mr-0'
                                ></img>
                            </span>
                        </div>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                        enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                        enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 translate-y-0'
                        leaveTo='transform opacity-0 translate-y-2'
                    >
                        <Listbox.Options className='dropdown mt-1 w-full text-base max-h-44 overflow-auto z-10 focus:outline-none sm:text-sm'>
                            {dateOptions?.map((option, optionIdx) => (
                                <Listbox.Option
                                    key={optionIdx}
                                    className={({ active }) =>
                                        `relative text-base font-medium cursor-pointer py-3 pl-8 pr-4 hover:bg-primary/20 ${
                                            active ? 'bg-primary/20' : ''
                                        }`
                                    }
                                    value={option}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span className='block truncate'>
                                                {option.label}
                                            </span>
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
            {/* 
            {dateDifference !== null && (
                <p>
                    Selected date is approximately {dateDifference} away from
                    the current date.
                </p>
            )} */}
            {error && <label className='text-red-600'>{error}</label>}
        </div>
    );
};

export default DateCalculator;
