import { apiClient } from '../client/api-client';

export const getStats = async (data) => {
    let url = `/user/get-all-nft-activities`;
    return await apiClient({ url, method: 'POST', data });
};

export const getOmiBurnStats = async (data) => {
    let url = `/user/omi-burned-transactions/get`;
    return await apiClient({ url, method: 'GET' });
};
