import { Dialog } from '@headlessui/react';
import React from 'react';
import { Link } from 'react-router-dom';

// COMPONENTS
import Modal from './Modal';

// IMAGES
import CloseIcon from '../../assets/images/close-icon.svg';

function CollaboratorsPopup(props) {
    const {
        isPopupOpen,
        setIsPopupOpen,
        nftDetail
    } = props;

    return (
        <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
            <div className='flex justify-between items-start border-b border-dark-30 pb-5'>
                <Dialog.Title
                    as='h3'
                    className='text-xl font-black text-black lg:text-2.5xl sm:text-2xl sm:-mt-2'
                >
                    Collaborators
                </Dialog.Title>
                <button
                    className='focus-visible:outline-none'
                    onClick={() => setIsPopupOpen(false)}
                >
                    <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                </button>
            </div>
            <div className='mt-10 flex flex-col gap-8'>
                {
                    nftDetail?.aCollaboratorData?.map((collaborator, index) => {
                        const matchingCollaborator = nftDetail.aCollaborator.find(
                            (c) => c.sWalletAddress === collaborator.sWalletAddress
                        );
                        return (
                            <div className='flex gap-5 items-center w-full' key={index}>
                                <div className='flex-shrink-0 relative w-14 h-14 lg:w-12 lg:h-12'>
                                    {collaborator.sProfilePicUrl ? <img
                                        src={collaborator.sProfilePicUrl}
                                        alt='UserImg'
                                        className='absolute left-0 top-0 rounded-full w-full h-full object-cover object-center'
                                        onError={(e) => {
                                            e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                                            e.target.style.display =
                                                'block'; // Adjust as needed
                                            e.target.src =
                                                'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                        }}
                                    /> : <div className='absolute left-0 top-0 rounded-full w-full h-full object-cover object-center bg-gradient'></div>}
                                </div>
                                <div className='flex-auto'>
                                    <div className='flex justify-between mb-3'>
                                        <Link
                                            to={`/user/${collaborator.sUserName}`}
                                            className='group'
                                        >
                                            <h5 className='text-xl text-black font-extrabold'>
                                                {collaborator.sUserName}
                                            </h5>
                                        </Link>
                                        <h5 className='text-xl text-black font-extrabold'>
                                            {matchingCollaborator.nPercentage / 100}%
                                        </h5>
                                    </div>
                                    <div className='bg-dark-30 w-full h-0.5 relative'>
                                        <div
                                            className='bg-dark-90 h-0.5'
                                            style={{ width: `${matchingCollaborator.nPercentage / 100}%` }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </Modal >
    );
}

export default CollaboratorsPopup;
