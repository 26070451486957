import React from 'react';
import { Link } from 'react-router-dom';

// IMAGES
import EditIcon from '../../assets/images/edit-icon.svg';

function CollectionCard(props) {
    const { data, setIsEditModal, setEditDetail, isOwner } = props;

    return (
        <div className='group relative bg-black h-full rounded-xl p-2 duration-300 hover:bg-white hover:shadow-lg hover:-translate-y-2.5'>
            <Link
                to={`/collections-detail/${data._id}`}
                className='absolute w-full h-full top-0 left-0 z-[2]'
            ></Link>
            {isOwner && (
                <button
                    className='group edit-icon hidden absolute right-7 top-7 border-0 rounded-full p-3 cursor-pointer bg-white z-[2] hover:bg-black group-hover:block'
                    onClick={(e) => {
                        setIsEditModal(true);
                        setEditDetail({
                            title: data.sName,
                            collectionId: data._id,
                            image: data.sCollectionPicUrl,
                        });
                    }}
                >
                    <img
                        src={EditIcon}
                        alt='EditIcon'
                        className='group-[.edit-icon:hover]:invert'
                    />
                </button>
            )}
            <div className='relative border border-dark-10 rounded pt-[50%]'>
                <img
                    src={data.sCollectionPicUrl}
                    alt={data.sName}
                    className='rounded absolute top-0 left-0 w-full h-full object-cover object-center'
                />
                <div className='absolute left-2/4 bottom-0 rounded-full bg-white/30 backdrop-blur p-2 -translate-x-2/4 translate-y-2/4 lg:p-1.5'>
                    <div className='relative w-28 pt-[100%] rounded-full leading-none lg:w-20'>
                        {data.profileImage ? (
                            <img
                                src={data.profileImage}
                                alt='profileImage'
                                className='absolute rounded-full w-full h-full left-0 top-0 object-cover object-center'
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                                    e.target.style.display = 'block'; // Adjust as needed
                                    e.target.src =
                                        'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                                }}
                            />
                        ) : (
                            <div className='absolute rounded-full w-full h-full left-0 top-0 object-cover object-center bg-gradient'></div>
                        )}
                    </div>
                </div>
            </div>
            <div className='px-4 pt-20 pb-5 text-center lg:pt-14 lg:pb-3'>
                <h6 className='text-2xl inline-block text-white font-bold group-hover:bg-gradient group-hover:text-transparent group-hover:bg-clip-text lg:text-xl sm:text-lg'>
                    {data.sName}
                </h6>
                <div className='flex items-center mt-2 text-dark-70 group-hover:text-dark-40'>
                    {/* <img
                        src={data.brand_logo}
                        alt={data.brand_name}
                        className='rounded-full w-9 h-9 object-cover object-center mr-2'
                    />
                    {data.brand_name} */}
                </div>
            </div>
        </div>
    );
}

export default CollectionCard;
