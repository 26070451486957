import { apiClient } from '../client/api-client';

export const addCollab = async (data) => {
    let url = `/user/add-collaborator`;
    return await apiClient({ url, method: 'POST', data });
};

export const editCollab = async (data) => {
    let url = `/user/edit-collaborator`;
    return await apiClient({ url, method: 'PATCH', data });
};

export const getCollab = async (search = '') => {
    let url = `/user/collaborator/get?search=${search}`;
    return await apiClient({ url, method: 'GET' });
};

export const deleteCollab = async (data) => {
    let url = `/user/delete-collaborator`;
    return await apiClient({ url, method: 'DELETE', data });
};
