import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCmsPage } from '../../api/adapters/asset-common';

// COMPONENTS

// IMAGES
import ArrowIcon from '../../assets/images/next-arrow.svg';
import { goBack } from '../../utils/helper';

function TermsConditions() {
    const navigate = useNavigate();
    const location = useNavigate();

    const { data: terms } = useQuery({
        queryKey: ['terms'],
        queryFn: () =>
            getCmsPage({ sPageName: 'Terms And Conditions' }).then(
                (res) => res.data.data
            ),
    });

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center relative'>
                    <h1 className='main-heading-h2 sm:mt-3'>
                        Terms & Conditions
                    </h1>
                    <button
                        className='group btn-border absolute top-6 left-0 lg:top-5 md:top-3 sm:-top-8 sm:py-2'
                        onClick={(e) => goBack(navigate, location)}
                    >
                        <img
                            src={ArrowIcon}
                            alt='ArrowIcon'
                            className='rotate-180 invert mr-2 group-hover:invert-0 sm:w-5'
                        />
                        Back
                    </button>
                </div>
                <div className='mt-16 md:mt-10 sm:mt-8'>
                    <div className='text-base text-dark-70 flex flex-col gap-y-4 mt-14 md:text-sm sm:mt-10'>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: terms?.sDescription,
                            }}
                        />
                    </div>
                </div>
                {/* <div className='mt-16 md:mt-10 sm:mt-8'>
                    <div className='text-base text-dark-70 flex flex-col gap-y-4 mt-14 md:text-sm sm:mt-10'>
                        <h6 className='text-base text-dark-90 font-medium'>
                            General Site Usage
                        </h6>
                        <p>Last Revised: December 16, 2013</p>
                        <p>
                            Welcome to www.lorem-ipsum.info. This site is
                            provided as a service to our visitors and may be
                            used for informational purposes only. Because the
                            Terms and Conditions contain legal obligations,
                            please read them carefully.
                        </p>

                        <h6 className='text-base text-dark-90 font-medium'>
                            Your agreement
                        </h6>
                        <p>
                            By using this Site, you agree to be bound by, and to
                            comply with, these Terms and Conditions. If you do
                            not agree to these Terms and Conditions, please do
                            not use this site.
                        </p>
                        <p>
                            PLEASE NOTE: We reserve the right, at our sole
                            discretion, to change, modify or otherwise alter
                            these Terms and Conditions at any time. Unless
                            otherwise indicated, amendments will become
                            effective immediately. Please review these Terms and
                            Conditions periodically. Your continued use of the
                            Site following the posting of changes and/or
                            modifications will constitute your acceptance of the
                            revised Terms and Conditions and the reasonableness
                            of these standards for notice of changes. For your
                            information, this page was last updated as of the
                            date at the top of these terms and conditions.
                        </p>
                        <h6 className='text-base text-dark-90 font-medium'>
                            Privacy
                        </h6>
                        <p>
                            Please review our Privacy Policy, which also governs
                            your visit to this Site, to understand our
                            practices.
                        </p>
                        <h6 className='text-base text-dark-90 font-medium'>
                            Linked sites
                        </h6>
                        <p>
                            This Site may contain links to other independent
                            third-party Web sites ("Linked Sites”). These Linked
                            Sites are provided solely as a convenience to our
                            visitors. Such Linked Sites are not under our
                            control, and we are not responsible for and does not
                            endorse the content of such Linked Sites, including
                            any information or materials contained on such
                            Linked Sites. You will need to make your own
                            independent judgment regarding your interaction with
                            these Linked Sites.
                        </p>
                        <h6 className='text-base text-dark-90 font-medium'>
                            forward looking statement
                        </h6>
                        <p>
                            All materials reproduced on this site speak as of
                            the original date of publication or filing. The fact
                            that a document is available on this site does not
                            mean that the information contained in such document
                            has not been modified or superseded by events or by
                            a subsequent document or filing. We have no duty or
                            policy to update any information or statements
                            contained on this site and, therefore, such
                            information or statements should not be relied upon
                            as being current as of the date you access this
                            site.
                        </p>
                    </div>
                </div> */}
            </div>
        </section>
    );
}

export default TermsConditions;
