import React from 'react';

function Input(props) {
    const {
        label,
        placeholder,
        id,
        handleSubmit,
        value = '',
        error,
        onBlur,
        disabled,
        isWalletAddress = false,
        isBio = false,
        changeHandler,
        isRequired,
        textLimit = 25,
    } = props;

    return (
        <div className=''>
            <label
                htmlFor={id}
                className='block text-sm font-semibold text-dark-60'
            >
                {label}
                {isRequired && <span className='text-red-600 ml-0.5'>*</span>}
            </label>
            <div className='mt-2'>
                <input
                    type='text'
                    name={id}
                    id={id}
                    autoComplete='off'
                    disabled={disabled}
                    maxLength={isWalletAddress ? 42 : isBio ? 100 : textLimit}
                    onChange={changeHandler}
                    placeholder={placeholder}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit?.();
                        }
                    }}
                    onBlur={onBlur}
                    value={value}
                    className={
                        'block w-full text-base font-medium rounded-xl bg-dark-10 border-1 border-dark-30 py-3.5 px-5 text-gray-900 placeholder:text-dark-60 focus:ring-0 focus:border-dark-70 sm:text-sm' +
                        `${error ? ' border-red-600' : ''}`
                    }
                />
            </div>
            {error && <label className='text-red-600'>{error}</label>}
        </div>
    );
}

export default Input;
