import { apiClient } from '../client/api-client';

export const getCmsPage = async (data) => {
    let url = `/user/get-cms-pages`;
    return await apiClient({ url, method: 'POST', data });
};

export const getFaq = async () => {
    let url = `/user/get-faqs`;
    return await apiClient({ url, method: 'GET' });
};

export const getCategory = async () => {
    let url = `/user/get-categories`;
    return await apiClient({ url, method: 'GET' });
};

export const getBrand = async () => {
    let url = `/user/get-brands`;
    return await apiClient({ url, method: 'GET' });
};

export const getSearch = async (search, data) => {
    let url = `/user/search/nft/${search}`;
    return await apiClient({ url, method: 'POST', data });
};

export const getContactDetails = async () => {
    let url = `/user/get-contact-details`;
    return await apiClient({ url, method: 'GET' });
};

export const sendQuery = async (data) => {
    let url = `/user/add-enquiry`;
    return await apiClient({ url, method: 'POST', data });
};

export const getPrice = async (data) => {
    let url = `/user/getPrice`;
    return await apiClient({ url, method: 'GET' });
};
