import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../../redux/actions/loader';

// IMAGES
import ErrorIcon from '../../assets/images/404-error-icon.svg';

function NotFound() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleLoader(false));
    }, []);

    return (
        <div className='section-space-small'>
            <div className='container text-center'>
                <div className='flex items-center justify-center text-6xl font-black'>
                    4<img src={ErrorIcon} alt='404' className='w-20' />4
                </div>
                <div>
                    <h2 className='text-3xl py-2 font-black'>Not Found</h2>
                    <p className='text-md my-3'>
                        the page you are looking for not available!
                    </p>
                    <Link to='/' className='btn-primary uppercase'>
                        Go to Home
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
