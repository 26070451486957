import { RadioGroup } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import Slider from 'react-slick';
import { getTopCollectors, getTopCreators } from '../../api/adapters/home-page';
import { smallCardSettings } from '../../configs/slider.config';
import CardSmall from '../CardSmall/CardSmall';

const TopCreatorCollector = () => {
    const [collectorsDays, setCollectorsDays] = useState('30');
    const [creatorsDays, setCreatorsDays] = useState('30');

    const { data: topCreator } = useQuery({
        queryKey: ['topCreator', creatorsDays],
        queryFn: () =>
            getTopCreators(creatorsDays).then((res) => {
                return res.data.data;
            }),
    });

    const { data: topCollector } = useQuery({
        queryKey: ['topCollector', collectorsDays],
        queryFn: () =>
            getTopCollectors(collectorsDays).then((res) => {
                return res.data.data;
            }),
    });

    return (
        <section className='bg-darkGray'>
            <div className='container section-space'>
                <div className='flex items-center justify-between mb-8 xs:flex-col xs:items-start xs:gap-4'>
                    <h2 className='main-heading-h2'>Top Creators</h2>
                    <RadioGroup
                        value={creatorsDays}
                        onChange={setCreatorsDays}
                        className='flex bg-black p-2 rounded'
                    >
                        <RadioGroup.Option value='1'>
                            {({ checked }) => (
                                <span
                                    className={`p-2.5 text-xs font-bold rounded cursor-pointer block text-center w-24 md:p-1 md:px-3 md:w-auto ${
                                        checked
                                            ? 'bg-white text-black'
                                            : 'text-dark-70'
                                    }`}
                                >
                                    1 DAYS
                                </span>
                            )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value='7'>
                            {({ checked }) => (
                                <span
                                    className={`p-2.5 text-xs font-bold rounded cursor-pointer block text-center w-24 md:p-1 md:px-3 md:w-auto ${
                                        checked
                                            ? 'bg-white text-black'
                                            : 'text-dark-70'
                                    }`}
                                >
                                    7 DAYS
                                </span>
                            )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value='30'>
                            {({ checked }) => (
                                <span
                                    className={`p-2.5 text-xs font-bold rounded cursor-pointer block text-center w-24 md:p-1 md:px-3 md:w-auto ${
                                        checked
                                            ? 'bg-white text-black'
                                            : 'text-dark-70'
                                    }`}
                                >
                                    30 DAYS
                                </span>
                            )}
                        </RadioGroup.Option>
                    </RadioGroup>
                </div>
                {topCreator && (
                    <Slider {...smallCardSettings}>
                        {topCreator?.length &&
                            topCreator.map((item, i) => {
                                return (
                                    <div key={i} className='p-2.5'>
                                        <CardSmall data={item} index={i + 1} />
                                    </div>
                                );
                            })}
                    </Slider>
                )}
                <div className='flex items-center justify-between mb-8 section-space pb-0  xs:flex-col xs:items-start xs:gap-4'>
                    <h2 className='main-heading-h2'>Top Collectors</h2>
                    <RadioGroup
                        value={collectorsDays}
                        onChange={setCollectorsDays}
                        className='flex bg-black p-2 rounded'
                    >
                        <RadioGroup.Option value='1'>
                            {({ checked }) => (
                                <span
                                    className={`p-2.5 text-xs font-bold rounded cursor-pointer block text-center w-24 md:p-1 md:px-3 md:w-auto ${
                                        checked
                                            ? 'bg-white text-black'
                                            : 'text-dark-70'
                                    }`}
                                >
                                    1 DAYS
                                </span>
                            )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value='7'>
                            {({ checked }) => (
                                <span
                                    className={`p-2.5 text-xs font-bold rounded cursor-pointer block text-center w-24 md:p-1 md:px-3 md:w-auto ${
                                        checked
                                            ? 'bg-white text-black'
                                            : 'text-dark-70'
                                    }`}
                                >
                                    7 DAYS
                                </span>
                            )}
                        </RadioGroup.Option>
                        <RadioGroup.Option value='30'>
                            {({ checked }) => (
                                <span
                                    className={`p-2.5 text-xs font-bold rounded cursor-pointer block text-center w-24 md:p-1 md:px-3 md:w-auto ${
                                        checked
                                            ? 'bg-white text-black'
                                            : 'text-dark-70'
                                    }`}
                                >
                                    30 DAYS
                                </span>
                            )}
                        </RadioGroup.Option>
                    </RadioGroup>
                </div>
                {topCollector && (
                    <Slider {...smallCardSettings}>
                        {topCollector?.length &&
                            topCollector.map((item, i) => {
                                return (
                                    <div key={i} className='p-2.5'>
                                        <CardSmall data={item} index={i + 1} />
                                    </div>
                                );
                            })}
                    </Slider>
                )}
            </div>
        </section>
    );
};

export default TopCreatorCollector;
