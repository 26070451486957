import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCollab, getCollab } from '../../api/adapters/collaborator';
import { toggleLoader } from '../../redux/actions/loader';
import { notify } from '../../utils/common.helper';
// COMPONENTS
import Input from '../../components/FormControl/Input';
import AddCollaborators from '../../components/Modal/Collaborator/AddCollaborators';
import EditCollaborators from '../../components/Modal/Collaborator/EditCollaborators';

// IMAGES
import DeleteIcon from '../../assets/images/delete-icon.svg';
import EditIcon from '../../assets/images/edit-icon-2.svg';
import SearchIcon from '../../assets/images/search-icon.svg';
import NoDataFound from '../../components/NoDataFound/NoDataFound';

function ManageCollaborators() {
    const [inputValue, setInputValue] = useState({
        search: '',
    });
    const [editDetail, setEditDetail] = useState({
        name: '',
        walletAddress: '',
        collaboratorId: '',
    });
    const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
    const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);

    const dispatch = useDispatch();

    const { data: collaborators, refetch } = useQuery({
        queryKey: ['collaborators'],
        queryFn: () =>
            getCollab(inputValue.search).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                return res.data.data;
            }),
    });

    const { mutate: mutateDelete } = useMutation({
        mutationFn: (collabId) =>
            deleteCollab({
                sCollaboratorId: collabId,
            }),
        onSuccess: (data) => {
            notify('success', data.data.message);
            refetch();
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong');
        },
    });

    return (
        <section className=''>
            <div className='container section-space-small'>
                <div className='text-center'>
                    <h1 className='main-heading-h2 inline-block'>
                        Manage Collaborators
                    </h1>
                </div>
                <div className='mt-14'>
                    <div className='flex gap-5 sm:flex-col sm:gap-2'>
                        <div className='flex-1 relative'>
                            <Input
                                placeholder='Search by collaborators'
                                id='search'
                                value={inputValue.search}
                                changeHandler={(e) =>
                                    setInputValue({ search: e.target.value })
                                }
                                handleSubmit={refetch}
                                isWalletAddress={true}
                            />
                            <span
                                className='absolute right-4 top-6 cursor-pointer'
                                onClick={(e) => {
                                    e.preventDefault();
                                    refetch();
                                }}
                            >
                                <img src={SearchIcon} alt='SearchIcon' />
                            </span>
                        </div>
                        <div className='mt-2'>
                            <button
                                className='btn-secondary'
                                onClick={() => setIsAddPopupOpen(true)}
                            >
                                Add collaborator
                            </button>
                        </div>
                    </div>

                    <div className='flex flex-col gap-y-2.5 mt-8'>
                        {collaborators?.length ? (
                            <div className='flex gap-4 px-4 pb-3 text-md text-dark-60 font-medium xl:hidden'>
                                <div className='w-1/3'>
                                    <p>Collaborator Name</p>
                                </div>
                                <div className='w-1/2'>
                                    <p>Collaborator Address</p>
                                </div>
                                <div className='w-1/6 text-right'>
                                    <p>Actions</p>
                                </div>
                            </div>
                        ) : (
                            <div className='w-full mt-24 -mr-10 h-[430px] flex items-center justify-center sm:h-60'>
                                <NoDataFound />
                            </div>
                        )}
                        {collaborators?.length
                            ? collaborators.map((collaborator) => {
                                  return (
                                      <div
                                          key={collaborator._id}
                                          className='flex items-center gap-4 px-4 py-8  border rounded-xl duration-300 border-dark-30 text-black font-bold hover:bg-dark-10 lg:flex-col lg:gap-0 lg:p-0'
                                      >
                                          <div className='w-1/3 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                              <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                                  <p>Collaborator Name</p>
                                              </div>
                                              <div className=''>
                                                  <p>{collaborator.sName}</p>
                                              </div>
                                          </div>
                                          <div className='w-1/2 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                              <div className='hidden w-5/12 text-sm text-dark-60 font-medium flex-shrink-0 lg:block'>
                                                  <p>Collaborator Address</p>
                                              </div>
                                              <div className=''>
                                                  <p className='break-all'>
                                                      {
                                                          collaborator.sWalletAddress
                                                      }
                                                  </p>
                                              </div>
                                          </div>

                                          <div className='w-1/6 lg:w-full lg:flex lg:items-center lg:border-b lg:last:border-b-0 lg:p-2.5 lg:border-dark-30'>
                                              <div className='hidden w-5/12 text-sm text-dark-60 font-medium lg:block'>
                                                  <p>Actions</p>
                                              </div>
                                              <div className='text-right'>
                                                  <button
                                                      className='border-0 inline-flex items-center hover:text-dark-80'
                                                      onClick={() => {
                                                          setEditDetail({
                                                              name: collaborator.sName,
                                                              walletAddress:
                                                                  collaborator.sWalletAddress,
                                                              collaboratorId:
                                                                  collaborator._id,
                                                          });
                                                          setIsEditPopupOpen(
                                                              true
                                                          );
                                                      }}
                                                  >
                                                      Edit
                                                      <img
                                                          src={EditIcon}
                                                          alt='EditIcon'
                                                          className='ml-2'
                                                      />
                                                  </button>
                                                  <button
                                                      className='border-0 inline-flex items-center ml-3 hover:text-dark-80'
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          mutateDelete(
                                                              collaborator._id
                                                          );
                                                      }}
                                                  >
                                                      Delete
                                                      <img
                                                          src={DeleteIcon}
                                                          alt='DeleteIcon'
                                                          className='ml-2'
                                                      />
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
                <AddCollaborators
                    isPopupOpen={isAddPopupOpen}
                    setIsPopupOpen={setIsAddPopupOpen}
                    refetch={refetch}
                />
                <EditCollaborators
                    isPopupOpen={isEditPopupOpen}
                    setIsPopupOpen={setIsEditPopupOpen}
                    refetch={refetch}
                    editDetail={editDetail}
                    setEditDetail={setEditDetail}
                />
            </div>
        </section>
    );
}

export default ManageCollaborators;
