import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getCmsPage } from '../../api/adapters/asset-common';

// IMAGES
import ArrowIcon from '../../assets/images/next-arrow.svg';
import { goBack } from '../../utils/helper';

function PrivacyPolicy() {
    const navigate = useNavigate();
    const location = useNavigate();

    const { data: privacyPolicy } = useQuery({
        queryKey: ['privacyPolicy'],
        queryFn: () =>
            getCmsPage({ sPageName: 'Privacy Policy' }).then(
                (res) => res.data.data
            ),
    });

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center relative'>
                    <h1 className='main-heading-h2 sm:mt-3'>Privacy Policy</h1>
                    <button
                        className='group btn-border absolute top-6 left-0 lg:top-5 md:top-3 sm:-top-8 sm:py-2'
                        onClick={(e) => goBack(navigate, location)}
                    >
                        <img
                            src={ArrowIcon}
                            alt='ArrowIcon'
                            className='rotate-180 invert mr-2 group-hover:invert-0 sm:w-5'
                        />
                        Back
                    </button>
                </div>
                <div className='mt-16 md:mt-10 sm:mt-8'>
                    <div className='text-base text-dark-70 flex flex-col gap-y-4 mt-14 md:text-sm sm:mt-10'>
                        <h6 className='text-base text-dark-90 font-medium'>
                            {privacyPolicy?.sTitle}
                        </h6>

                        <div
                            className='flex flex-col gap-y-4'
                            dangerouslySetInnerHTML={{
                                __html: privacyPolicy?.sDescription,
                            }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PrivacyPolicy;
