import axios from 'axios';

import { getToken } from '../../utils/cookies.helper';

export const apiClient = async ({ ...options }) => {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const client = axios.create({ baseURL });

    let sToken = getToken();

    client.defaults.headers.common.Authorization = `${sToken}`;

    try {
        const response = await client(options);

        if (!response.data) throw new Error('API Request Was Not Successful');

        if (!response) throw new Error('API Request Was Not Successful');
        return response;
        // return response.data;
    } catch (error) {
        throw error.response;
    }
};
