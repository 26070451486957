import { Listbox, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

// COMPONENTS

// IMAGES
import { getBids } from '../../api/adapters/manageBid';
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import CardMedium from '../../components/CardMedium/CardMedium';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { toggleLoader } from '../../redux/actions/loader';

const shortBy = [
    { name: 'All' },
    { name: 'Pending' },
    { name: 'Accepted' },
    { name: 'Rejected' },
];

function ManageBid() {
    const [selectedShortBy, setSelectedShortBy] = useState({ name: 'All' });

    const dispatch = useDispatch();

    const { data: bids } = useQuery({
        queryKey: ['bids', selectedShortBy],
        queryFn: () =>
            getBids({
                start: 0,
                length: 1000,
                sStatus: selectedShortBy.name,
            }).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                return res.data.data;
            }),
    });

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center'>
                    <h1 className='main-heading-h2'>Manage Bid</h1>
                </div>
                <div className='relative'>
                    <div className='absolute -top-16 right-0 z-10 md:relative md:top-0 md:mt-10 xs:w-full'>
                        <Listbox
                            value={selectedShortBy}
                            onChange={setSelectedShortBy}
                        >
                            <div className='relative'>
                                <Listbox.Button className='w-72 flex justify-between items-center relative cursor-pointer rounded-xl bg-dark-10 border border-dark-30 py-3.5 px-4 text-left text-sm font-bold text-dark-60 focus:outline-none lg:w-60 xs:w-full aria-[expanded=true]:border-dark-70'>
                                    <span className='block truncate'>
                                        {selectedShortBy?.name
                                            ? selectedShortBy?.name
                                            : 'Sort by'}
                                    </span>
                                    <span className='pointer-events-none inset-y-0 pr-0'>
                                        <img
                                            src={CollapseIcon}
                                            alt='CollapseIcon'
                                            className='mr-0'
                                        ></img>
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    as={Fragment}
                                    enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                                    enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                                    enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 translate-y-0'
                                    leaveTo='transform opacity-0 translate-y-2'
                                >
                                    <Listbox.Options className='dropdown mt-1 w-full text-base focus:outline-none sm:text-sm'>
                                        {shortBy.map(
                                            (shortItem, shortItemIdx) => (
                                                <Listbox.Option
                                                    key={shortItemIdx}
                                                    className={({ active }) =>
                                                        `relative text-base font-medium cursor-pointer py-3 pl-8 pr-4 hover:bg-primary/20 ${active
                                                            ? 'bg-primary/20'
                                                            : ''
                                                        }`
                                                    }
                                                    value={shortItem}
                                                >
                                                    {({ selected }) => (
                                                        <>
                                                            <span className='block truncate'>
                                                                {shortItem.name}
                                                            </span>
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            )
                                        )}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </Listbox>
                    </div>
                    <div
                        className={`flex-1 relative grid grid-cols-4 gap-x-5 gap-y-10 pt-16 2xl:pt-12 xl:grid-cols-3 md:grid-cols-2 sm:flex sm:flex-wrap sm:gap-y-7 xs:pt-10 ${bids?.length ? '' : '!grid-cols-1'
                            }`}
                    >
                        {bids?.length ? (
                            bids.map((item, i) => {
                                return (
                                    <div key={i} className='sm:w-full'>
                                        {item?.aNFT?.nChainId ? <CardMedium
                                            data={item}
                                            ManageBid={true}
                                            chainId={item.aNFT.nChainId}
                                        /> : null}
                                    </div>
                                );
                            })
                        ) : (
                            <NoDataFound />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ManageBid;
