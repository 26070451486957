import { apiClient } from '../client/api-client';

export const getHomePage = async () => {
    let url = `/user/home-page/get`;
    return await apiClient({ url, method: 'GET' });
};

export const getTopCreators = async (days) => {
    let url = `/user/home-page/${days}/get-top-creators`;
    return await apiClient({ url, method: 'GET' });
};

export const getTopCollectors = async (days) => {
    let url = `/user/home-page/${days}/get-top-collectors`;
    return await apiClient({ url, method: 'GET' });
};
