import { Dialog } from '@headlessui/react';
import React from 'react';
import { useSelector } from 'react-redux';

// COMPONENTS
import Modal from './Modal';

// IMAGES
import CloseIcon from '../../assets/images/close-icon.svg';
import InstagramIcon from '../../assets/images/instagram-icon2.svg';
import TwitterIcon from '../../assets/images/twitter-icon2.svg';
import Loader from '../Loader/Loader';

function VerifyAccountPopup(props) {
    const { isPopupOpen, setIsPopupOpen } = props;
    const loader = useSelector((state) => state.loader);
    return (
        <>
            <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
                <div className='flex justify-between items-start'>
                    <Dialog.Title
                        as='h3'
                        className='text-3xl font-black font-darker text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2'
                    >
                        Verify Your Account
                    </Dialog.Title>
                    <button
                        className='focus-visible:outline-none'
                        onClick={() => setIsPopupOpen(false)}
                    >
                        <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                    </button>
                </div>
                <div className='mt-2'>
                    <p className='text-base font-bold text-dark-70'>
                        Choose how you want to verify.
                    </p>
                </div>

                <div className='flex gap-7 justify-center mt-6 xs:flex-col'>
                    {loader ? (
                        <Loader />
                    ) : (
                        <>
                            <div className='bg-dark-10 rounded-2xl text-center cursor-pointer px-7 py-10 xs:p-5 md:w-2/5 xs:mx-auto xs:w-2/3'>
                                <img
                                    src={InstagramIcon}
                                    alt='InstagramIcon'
                                    className='inline-block md:w-28'
                                />
                                <h4 className='text-2xl font-darker font-bold text-black sm:text-xl'>
                                    Instagram
                                </h4>
                            </div>
                            <div className='bg-dark-10 rounded-2xl text-center cursor-pointer px-7 py-10 xs:p-5 md:w-2/5 xs:mx-auto xs:w-2/3'>
                                <img
                                    src={TwitterIcon}
                                    alt='TwitterIcon'
                                    className='inline-block md:w-28'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(
                                            'http://localhost:3002/api/v1/user/twitter',
                                            '_self'
                                        );
                                    }}
                                />
                                <h4 className='text-2xl font-darker font-bold text-black sm:text-xl'>
                                    Twitter
                                </h4>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
}

export default VerifyAccountPopup;
