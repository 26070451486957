import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';

// IMAGES
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import { notify } from '../../utils/common.helper';

function Navigation(props) {
    const { setMobileMenuOpen, setIsAddPopupOpen } = props;
    const navigate = useNavigate();
    const { isConnected } = useAccount();

    const mainNav = [
        {
            title: 'Home',
            url: '/',
        },
        {
            title: 'Discover',
            url: '#',
            submenu: [
                {
                    title: 'All Brands',
                    url: '/all-brands',
                },
                {
                    title: 'All Collection',
                    url: '/collections',
                },
                {
                    title: 'All NFT',
                    url: '/all-nfts',
                },
            ],
        },
        {
            title: 'Create',
            url: '#',
            submenu: [
                {
                    title: 'Create NFT',
                    url: '/create-nft',
                },
                {
                    title: 'Create Collection',
                    url: '#',
                },
            ],
        },
        {
            title: 'Stats',
            url: '/stats',
        },
        // {
        //     title: 'Blog',
        //     url: '/blog',
        // },
    ];

    function closeMobileMenu() {
        if (setMobileMenuOpen) {
            setMobileMenuOpen(false);
        }
    }

    return (
        <ul className='flex gap-8 group-[.mobile-active]:flex-col group-[.mobile-active]:gap-3 2xl:gap-6'>
            {mainNav.length &&
                mainNav.map((item, i) => {
                    if (item.title === 'Create' && !isConnected) return null;
                    return (
                        <li
                            className='relative group-[.mobile-active]:bg-dark-10 group-[.mobile-active]:rounded-md '
                            key={i}
                        >
                            <Menu>
                                {({ open }) => (
                                    <>
                                        {item.submenu ? (
                                            <Menu.Button
                                                className={`font-bold flex justify-between cursor-pointer text-dark-60 text-base py-1 duration-300 focus-visible:outline-none hover:text-black group-[.mobile-active]:px-5 group-[.mobile-active]:py-3 ${
                                                    open ? '!text-black' : ''
                                                }`}
                                                as='a'
                                            >
                                                {item.title}
                                                <img
                                                    src={CollapseIcon}
                                                    alt='CollapseIcon'
                                                    className='hidden group-[.mobile-active]:block -mr-2'
                                                />
                                            </Menu.Button>
                                        ) : (
                                            <NavLink
                                                to={item.url}
                                                className='font-bold block text-dark-60 text-base py-1 duration-300 hover:text-black group-[.mobile-active]:px-5 group-[.mobile-active]:py-3 [&.active]:text-black'
                                                onClick={closeMobileMenu}
                                            >
                                                {item.title}
                                            </NavLink>
                                        )}
                                        <Transition
                                            as={Fragment}
                                            enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                                            enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                                            enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                                            leave='transition ease-in duration-75'
                                            leaveFrom='transform opacity-100 translate-y-0'
                                            leaveTo='transform opacity-0 translate-y-2'
                                        >
                                            <Menu.Items
                                                as='div'
                                                className='w-60 dropdown top-10 group-[.mobile-active]:relative group-[.mobile-active]:top-0 group-[.mobile-active]:bg-transparent group-[.mobile-active]:w-full group-[.mobile-active]:shadow-none group-[.mobile-active]:rounded-none group-[.mobile-active]:pb-4'
                                            >
                                                <ul>
                                                    {item?.submenu?.length &&
                                                        item.submenu.map(
                                                            (item, i) => {
                                                                return (
                                                                    <li key={i}>
                                                                        <Menu.Item
                                                                        // as='li'
                                                                        >
                                                                            {({
                                                                                active,
                                                                            }) => (
                                                                                <NavLink
                                                                                    className={`font-medium text-black text-base px-10 py-4 block duration-300 hover:bg-primary/20 group-[.mobile-active]:text-sm group-[.mobile-active]:py-2 [&.active]:bg-primary/20' ${
                                                                                        active &&
                                                                                        'bg-primary/20'
                                                                                    }`}
                                                                                    to={
                                                                                        item.url
                                                                                    }
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        // e.preventDefault();
                                                                                        if (
                                                                                            (item.url ===
                                                                                                '/create-nft' ||
                                                                                                item.url ===
                                                                                                    '#') &&
                                                                                            !isConnected
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                            return notify(
                                                                                                'error',
                                                                                                'Please connect your wallet!'
                                                                                            );
                                                                                        } else {
                                                                                            if (
                                                                                                item.url ===
                                                                                                '#'
                                                                                            ) {
                                                                                                closeMobileMenu();
                                                                                                return setIsAddPopupOpen(
                                                                                                    true
                                                                                                );
                                                                                            }

                                                                                            navigate(
                                                                                                item.url
                                                                                            );
                                                                                        }
                                                                                        closeMobileMenu();
                                                                                    }}
                                                                                >
                                                                                    <span className='hidden rounded align-middle mb-1 -ml-4 mr-2 w-5 h-1 bg-gradient group-[.mobile-active]:inline-block '></span>
                                                                                    {
                                                                                        item.title
                                                                                    }
                                                                                </NavLink>
                                                                            )}
                                                                        </Menu.Item>
                                                                    </li>
                                                                );
                                                            }
                                                        )}
                                                </ul>
                                            </Menu.Items>
                                        </Transition>
                                    </>
                                )}
                            </Menu>
                        </li>
                    );
                })}
        </ul>
    );
}

export default Navigation;
