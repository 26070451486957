import { Listbox, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    getCollectionDetail,
    getSingleCollection,
} from '../../api/adapters/collection';
import { shortBy } from '../../constants/platform-constants';
import { toggleLoader } from '../../redux/actions/loader';
import {
    copyToClipboard,
    formatNumber,
    trimEthereumAddress,
} from '../../utils/helper';

// COMPONENTS
import Filter from '../../components/Filter/Filter';
import ProfileBanner from '../../components/ProfileBanner/ProfileBanner';

// IMAGES
import { getCategory } from '../../api/adapters/asset-common';
import ClearIcon from '../../assets/images/clear-icon.svg';
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import DiscordIcon from '../../assets/images/discord-icon-2.svg';
import EmailIcon from '../../assets/images/email-icon-2.svg';
import FilterIcon from '../../assets/images/filter-icon.svg';
import InstagramIcon from '../../assets/images/instagram-icon-2.svg';
import LinkIcon from '../../assets/images/link-icon.svg';
import TwitterIcon from '../../assets/images/twitter-icon.svg';
import WebsiteIcon from '../../assets/images/website-icon.svg';
import CardMedium from '../../components/CardMedium/CardMedium';
import ButtonLoader from '../../components/Loader/ButtonLoader';
import Loader from '../../components/Loader/Loader';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { notify } from '../../utils/common.helper';

function CollectionDetail() {
    const [showFilter, seShowFilter] = useState(false);
    const [selectedShortBy, setSelectedShortBy] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [filters, setFilters] = useState({
        status: 'All',
        blockChain: 'All',
        type: 'All',
        categories: [],
        token: 'All',
        price: {
            min: '',
            max: '',
        },
    });
    const [collectionData, setCollectionData] = useState([]);
    const [pagination, setPagination] = useState({
        start: 0,
        length: 8,
    });
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector((state) => state.loader);
    const user = useSelector((state) => state.user);

    const { data: collectionDetail, error } = useQuery({
        queryKey: ['collectionDetail'],
        queryFn: () =>
            getSingleCollection(id).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);

                return res.data.data;
            }),
    });

    const { data: category } = useQuery({
        queryKey: ['category'],
        queryFn: () =>
            getCategory().then((res) => {
                res.data.data.forEach((element) => {
                    if (!categoryList.includes(element._id))
                        setCategoryList((prev) => [...prev, element._id]);
                });
                return res.data.data;
            }),
    });

    const {
        data: collectionNfts,
        refetch: refetchCollectionNft,
        isFetching,
    } = useQuery({
        queryKey: [
            'collectionNfts',
            selectedShortBy.value,
            filters.categories.length,
            filters.status,
            filters.blockChain,
            filters.type,
            filters.token,
            pagination.start,
        ],
        queryFn: () =>
            getCollectionDetail({
                sCollectionId: id,
                sUserName:
                    filters.status === 'Owned' ||
                    filters.status === 'Created' ||
                    filters.status === 'Liked'
                        ? user.userName
                        : undefined,
                sSortType: selectedShortBy.value || 'ALL',
                oFilters: {
                    ...filters,
                    categories: filters.categories.length
                        ? filters.categories
                        : categoryList,
                    // status: 'Fixed Sale',
                },
                start: pagination.start,
                length: pagination.length,
            }).then((res) => {
                const isFiltersDefault =
                    filters.status === 'All' &&
                    filters.blockChain === 'All' &&
                    filters.type === 'All' &&
                    filters.token === 'All' &&
                    !filters.categories.length &&
                    filters.price.min === '' &&
                    filters.price.max === '' &&
                    !selectedShortBy.value;

                const newData = [...res.data.data];

                if (isFiltersDefault && isButtonClicked) {
                    setCollectionData((prev) => [...prev, ...newData]);
                    setIsButtonClicked(false);
                } else {
                    setCollectionData(newData);
                }

                return res.data.data;
            }),

        enabled: !!collectionDetail?.[0].collection[0]._id,
    });

    if (error) return navigate('/404');

    const handleClear = () => {
        setFilters({
            status: 'All',
            blockChain: 'All',
            type: 'All',
            categories: [],
            price: {
                min: '',
                max: '',
            },
        });
        setSelectedShortBy({});
    };

    const handlePagination = () => {
        setIsButtonClicked(true);

        setPagination((prev) => ({
            ...prev,
            start: prev.start + prev.length,
        }));
    };
    return (
        <section>
            <ProfileBanner
                bannerImage={collectionDetail?.[0].collection[0].sCoverPicUrl}
                profileImage={
                    collectionDetail?.[0].collection[0].sCollectionPicUrl
                }
            />
            <div className='container pt-20 pb-20 sm:pt-14'>
                <div className='flex gap-10 justify-between border-b-2 border-b-dark-20 pb-14 md:flex-col sm:pb-10'>
                    <div className='max-w-3xl md:max-w-none'>
                        <h1 className='main-heading-h1 text-darkGray'>
                            {collectionDetail?.[0].collection[0].sName}
                        </h1>
                        {collectionDetail?.[0].collection[0]
                            ?.sCollectionAddress && (
                            <div className='my-8'>
                                <button className='btn-secondary bg-purple border-purple text-black text-base md:text-sm'>
                                    {trimEthereumAddress(
                                        collectionDetail?.[0]?.collection[0]
                                            ?.sCollectionAddress,
                                        6
                                    )}
                                    <img
                                        src={LinkIcon}
                                        alt='LinkIcon'
                                        className='ml-2'
                                    />
                                </button>
                            </div>
                        )}
                        {collectionDetail?.[0]?.collection[0]?.sDescription && (
                            <p className='text-sm text-dark-90'>
                                {
                                    collectionDetail?.[0]?.collection[0]
                                        ?.sDescription
                                }
                            </p>
                        )}
                    </div>
                    <div>
                        <div className='flex gap-4 justify-end sm:flex-wrap sm:justify-start'>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={TwitterIcon}
                                        alt='TwitterIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Twitter
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={InstagramIcon}
                                        alt='InstagramIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Instagram
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={DiscordIcon}
                                        alt='DiscordIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Discord
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={WebsiteIcon}
                                        alt='WebsiteIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Website
                                </span>
                            </Link>
                            <Link
                                to=''
                                target='_blank'
                                className='group text-center xs:basis-[29%]'
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={EmailIcon}
                                        alt='EmailIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Email
                                </span>
                            </Link>
                            <button
                                className='group text-center xs:basis-[29%]'
                                onClick={() => {
                                    const data = copyToClipboard(
                                        window.location.href
                                    );
                                    if (data)
                                        notify(
                                            'success',
                                            'Link copied to clipboard'
                                        );
                                }}
                            >
                                <span className='bg-dark-10 p-2 rounded-xl inline-block duration-300 group-hover:bg-black'>
                                    <img
                                        src={LinkIcon}
                                        alt='LinkIcon'
                                        className='duration-300 group-hover:invert'
                                    />
                                </span>
                                <span className='block text-xxs text-dark-70 duration-300 group-hover:text-black'>
                                    Copy
                                </span>
                            </button>
                        </div>
                        <div className='border border-dark-30 rounded-xl p-4 px-7 mt-10 min-w-[420px] md:min-w-full'>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Floor
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    {formatNumber(
                                        collectionDetail?.[0]?.floorPrice?.[0]
                                            ?.floorPrice
                                    )}{' '}
                                    USD
                                </p>
                            </div>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Volume
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    {formatNumber(
                                        collectionDetail?.[0]?.totalvolume?.[0]
                                            ?.totalvolume
                                    )}{' '}
                                    USD
                                </p>
                            </div>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Items
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    {formatNumber(
                                        collectionDetail?.[0]?.totalNFTs?.[0]
                                            ?.nfts
                                    )}
                                </p>
                            </div>
                            <div className='flex justify-between py-2'>
                                <p className='text-base text-dark-60 font-medium sm:text-sm'>
                                    Owners
                                </p>
                                <p className='text-base text-black font-bold sm:text-sm'>
                                    {formatNumber(
                                        collectionDetail?.[0]
                                            ?.totalCurrentOwners?.[0]?.total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='py-14 lg:py-12 sm:py-10'>
                    <ul className='flex justify-center flex-wrap gap-8 md:gap-5 md:gap-y-2'>
                        <li>
                            <span
                                className={`text-base inline-block font-bold text-dark-60 cursor-pointer duration-300 relative after:h-[3px] after:absolute after:-bottom-2 after:left-0 after:duration-300 after:bg-gradient after:opacity-0 after:w-full hover:text-black hover:after:opacity-100 md:text-sm !text-black after:opacity-100`}
                            >
                                NFTs
                                <span className='ml-2 px-2 py-1.5 text-xs text-white inline-block bg-black rounded-lg font-bold sm:text-xxs sm:py-1 sm:px-1.5 sm:align-bottom'>
                                    {
                                        collectionDetail?.[0]?.totalNFTs?.[0]
                                            ?.nfts
                                    }
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>

                <div className='flex items-start md:flex-wrap'>
                    <div className='relative pt-16 z-10 md:w-full md:pt-0'>
                        <div className='flex gap-5 absolute top-0'>
                            <button
                                onClick={() => seShowFilter(!showFilter)}
                                className={`group flex-shrink-0  p-3.5 rounded duration-300 hover:bg-black xs:p-2.5 ${
                                    showFilter ? 'bg-black' : 'bg-dark-10'
                                }`}
                            >
                                <img
                                    src={FilterIcon}
                                    alt='FilterIcon'
                                    className={`duration-300 group-hover:invert ${
                                        showFilter ? 'invert' : ''
                                    }`}
                                />
                            </button>
                            <button
                                className='group flex-shrink-0 bg-dark-10 p-3.5 rounded duration-300 hover:bg-black xs:p-2.5'
                                onClick={handleClear}
                            >
                                <img
                                    src={ClearIcon}
                                    alt='ClearIcon'
                                    className='duration-300 group-hover:invert'
                                />
                            </button>
                        </div>
                        {showFilter && (
                            <Filter
                                category={category}
                                filters={filters}
                                setFilters={setFilters}
                                categoryIdList={categoryList}
                                refetch={refetchCollectionNft}
                            />
                        )}
                    </div>
                    {!collectionData?.length && !loader && (
                        <div className='w-full mt-24 -mr-10 h-[430px] flex items-center justify-center sm:h-60'>
                            <NoDataFound />
                        </div>
                    )}
                    {loader && !collectionData?.length && (
                        <div className='mt-20 w-full'>
                            <Loader />
                        </div>
                    )}
                    <div
                        className={`flex-1 relative grid grid-cols-4 gap-x-5 gap-y-10 pt-20 xl:grid-cols-3 md:grid-cols-2 sm:flex sm:flex-wrap sm:gap-y-7 xs:pt-16 ${
                            showFilter
                                ? '!grid-cols-3 lg:!grid-cols-2 sm:lg:!grid-cols-1'
                                : ''
                        } `}
                    >
                        <div className='absolute top-0 right-0 z-10 xs:relative xs:w-full'>
                            <Listbox
                                value={selectedShortBy}
                                onChange={(e) => {
                                    setSelectedShortBy({
                                        name: e.name,
                                        value: e.value,
                                    });
                                }}
                            >
                                <div className='relative'>
                                    <Listbox.Button className='w-72 flex justify-between items-center relative cursor-pointer rounded-xl bg-dark-10 border border-dark-30 py-3.5 px-4 text-left text-sm font-bold text-dark-60 focus:outline-none xs:w-full aria-[expanded=true]:border-dark-70'>
                                        <span className='block truncate'>
                                            {selectedShortBy?.name
                                                ? selectedShortBy?.name
                                                : 'Sort by'}
                                        </span>
                                        <span className='pointer-events-none inset-y-0 pr-0'>
                                            <img
                                                src={CollapseIcon}
                                                alt='CollapseIcon'
                                                className='mr-0'
                                            ></img>
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                                        enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                                        enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 translate-y-0'
                                        leaveTo='transform opacity-0 translate-y-2'
                                    >
                                        <Listbox.Options className='dropdown mt-1 w-full text-base focus:outline-none sm:text-sm'>
                                            {shortBy.map(
                                                (shortItem, shortItemIdx) => (
                                                    <Listbox.Option
                                                        key={shortItemIdx}
                                                        className={({
                                                            active,
                                                        }) =>
                                                            `relative text-base font-medium cursor-pointer py-3 pl-8 pr-4 hover:bg-primary/20 ${
                                                                active
                                                                    ? 'bg-primary/20'
                                                                    : ''
                                                            }`
                                                        }
                                                        value={shortItem}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span className='block truncate'>
                                                                    {
                                                                        shortItem.name
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                )
                                            )}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        {collectionData?.length
                            ? collectionData.map((item, i) => {
                                  return (
                                      <div key={i} className='sm:w-full'>
                                          <CardMedium
                                              data={item}
                                              chainId={item.nChainId}
                                          />
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
            {collectionNfts?.length >= 8 && (
                <div className='w-full -mr-10 h-[100px] flex items-center justify-center sm:h-60'>
                    <button
                        className='btn-secondary'
                        onClick={handlePagination}
                    >
                        {isFetching && <ButtonLoader />} Load More
                    </button>
                </div>
            )}
        </section>
    );
}

export default CollectionDetail;
