import { Dialog } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editProfile } from '../../api/adapters/user';
import CloseIcon from '../../assets/images/close-icon.svg';
import EditIcon from '../../assets/images/edit-icon-2.svg';
import OtherProfileBanner from '../../assets/images/other-profile-banner.jpg';
import ProfileImg from '../../assets/images/user-profile-img.jpg';
import { updateUser } from '../../redux/actions/user';
import { notify } from '../../utils/common.helper';
import { isValidName, isValidStringLength } from '../../utils/helper';
import Input from '../FormControl/Input';
import Modal from './Modal';

function EditProfilePopup(props) {
    const { isPopupOpen, setIsPopupOpen, refetch } = props;
    let formData;

    const user = useSelector((state) => state.user);
    const [coverImageFile, setCoverImageFile] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const dispatch = useDispatch();

    function fileOnChangeCover(event) {
        if (event.target.files.length > 0) {
            setInputValue((prev) => ({
                ...prev,
                sCoverPicUrl: event.target.files[0],
            }));
            setCoverImageFile(event.target.files[0]);
        }
    }

    function fileOnChangeProfile(event) {
        if (event.target.files.length > 0) {
            setInputValue((prev) => ({
                ...prev,
                sProfilePicUrl: event.target.files[0],
            }));
            setProfileImage(event.target.files[0]);
        }
    }

    const [inputValue, setInputValue] = useState({
        sProfilePicUrl: '',
        sCoverPicUrl: '',
        sFirstName: '',
        sLastName: '',
        sBio: '',
    });

    const [errorMessage, setErrorMessage] = useState({
        sProfilePicUrl: '',
        sCoverPicUrl: '',
        sFirstName: '',
        sLastName: '',
        sBio: '',
    });

    useEffect(() => {
        if (user) {
            setInputValue((prev) => ({
                ...prev,
                sProfilePicUrl: user?.profilePicUrl,
                sCoverPicUrl: user?.coverPicUrl,
                sFirstName: user?.firstName,
                sLastName: user?.lastName,
                sBio: user?.bio,
            }));
        }
    }, [user]);

    let error = false;
    const errorObj = {};

    function checkValidation() {
        const fieldsToValidate = [
            {
                field: 'sFirstName',
                validation: (value) =>
                    value?.trim() === ''
                        ? 'First name is required'
                        : !isValidName(value)
                            ? 'First name must be less than 25 characters'
                            : '',
            },
            {
                field: 'sLastName',
                validation: (value) =>
                    value?.trim() === ''
                        ? 'Last name is required'
                        : !isValidName(value)
                            ? 'Last name must be less than 25 characters'
                            : '',
            },
            {
                field: 'sBio',
                validation: (value) =>
                    value?.trim() === ''
                        ? 'Bio is required'
                        : !isValidStringLength(value)
                            ? 'Bio must be less than 100 characters'
                            : '',
            },
        ];

        fieldsToValidate.forEach(({ field, validation }) => {
            const errorMessage = validation(inputValue[field] || '');
            errorObj[field] = errorMessage;
            if (errorMessage !== '') {
                error = true;
            }
        });

        return error;
    }

    const { mutate } = useMutation({
        mutationFn: () => editProfile(formData),
        onSuccess: (data) => {
            notify('success', data.data.message);

            const updatedUserData = {
                ...user,
                firstName: data?.data?.data?.sFirstName,
                lastName: data?.data?.data?.sLastName,
                bio: data?.data?.data?.sBio,
                profilePicUrl: data?.data?.data?.sProfilePicUrl,
                coverPicUrl: data?.data?.data?.sCoverPicUrl,
            };

            dispatch(updateUser({ ...updatedUserData }));
            setInputValue({ name: '', walletAddress: '' });
            refetch();
            setIsPopupOpen(false);
        },
        onError: (error) => {
            notify('error', error);
            setInputValue({ name: '', walletAddress: '' });
            setIsPopupOpen(false);
        },
    });

    const handleSubmit = async () => {
        try {
            // checkValidation();
            // let isError = checkValidation();
            // if (isError) {
            //     setErrorMessage(errorObj);
            //     return;
            // } else {
            addFormData();
            return mutate();
            // }
        } catch (error) { }
    };

    function addFormData() {
        formData = new FormData();
        formData.append('sFirstName', inputValue.sFirstName || '');
        formData.append('sLastName', inputValue.sLastName || '');
        formData.append('sBio', inputValue.sBio || '');
        formData.append('sProfilePicUrl', inputValue.sProfilePicUrl);
        formData.append('sCoverPicUrl', inputValue.sCoverPicUrl);
    }
    return (
        <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
            <div className='flex justify-between items-start'>
                <Dialog.Title
                    as='h3'
                    className='text-3xl font-black font-darker text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2'
                >
                    Edit Profile
                </Dialog.Title>
                <button
                    className='focus-visible:outline-none'
                    onClick={() => setIsPopupOpen(false)}
                >
                    <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                </button>
            </div>
            <div className='mt-5'>
                <div className='rounded-t-xl overflow-hidden h-80 relative md:h-72 sm:h-56 bg-dark-10'>
                    {(coverImageFile || inputValue.sCoverPicUrl) && <img
                        src={
                            coverImageFile
                                ? URL.createObjectURL(coverImageFile)
                                : inputValue.sCoverPicUrl + `?${Date.now()}`
                        }
                        alt='OtherProfileBanner'
                        className='absolute w-full h-full object-cover object-center left-0 top-0'
                    />}

                    <button className='bg-white rounded-full p-2.5 absolute right-5 top-5 z-10  shadow-xl'>
                        <img src={EditIcon} alt='EditIcon' />
                        <input
                            type='file'
                            onChange={fileOnChangeCover}
                            accept='image/png, image/jpg, image/jpeg'
                            className='absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer'
                        />
                    </button>
                </div>
                <div className='h-full'>
                    <div className='relative h-full ml-5'>
                        <div className='absolute left-0 bottom-0 rounded-full bg-dark-10/40 backdrop-blur p-3 translate-y-1/3'>
                            <div className='relative w-56 pt-[100%] rounded-full leading-none lg:w-48 sm:w-32 bg-gradient'>
                                {(inputValue.sProfilePicUrl || profileImage) &&
                                    <img
                                        src={
                                            profileImage
                                                ? URL.createObjectURL(
                                                    profileImage
                                                )
                                                : inputValue.sProfilePicUrl +
                                                `?${Date.now()}`
                                        }
                                        alt='ProfileImg'
                                        className='absolute rounded-full w-full h-full left-0 top-0 object-cover object-center'
                                    />
                                }
                                <button className='bg-white rounded-full p-2.5 absolute right-0 bottom-3 z-10 shadow-xl sm:-right-4'>
                                    <img src={EditIcon} alt='EditIcon' />
                                    <input
                                        type='file'
                                        onChange={fileOnChangeProfile}
                                        accept='image/png, image/jpg, image/jpeg'
                                        className='absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer'
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-24 sm:mt-16'>
                <div className='mt-6'>
                    <Input
                        placeholder='Enter first name'
                        label='First Name'
                        id='sFirstName'
                        type={'text'}
                        error={error.sFirstName}
                        value={inputValue.sFirstName}
                        setInputValue={setInputValue}
                        changeHandler={(e) => {
                            setInputValue((prev) => {
                                return {
                                    ...prev,
                                    sFirstName: e.target.value,
                                };
                            });
                        }}
                    />
                    {errorMessage.sFirstName && (
                        <label className='text-red-600'>
                            {errorMessage.sFirstName}
                        </label>
                    )}
                </div>
                <div className='mt-6'>
                    <Input
                        placeholder='Enter last name'
                        label='Last Name'
                        id='sLastName'
                        type={'text'}
                        error={error.sLastName}
                        value={inputValue.sLastName}
                        setInputValue={setInputValue}
                        changeHandler={(e) => {
                            setInputValue((prev) => {
                                return {
                                    ...prev,
                                    sLastName: e.target.value,
                                };
                            });
                        }}
                    />
                    {errorMessage.sLastName && (
                        <label className='text-red-600'>
                            {errorMessage.sLastName}
                        </label>
                    )}
                </div>
                <div className='mt-6'>
                    <Input
                        placeholder='Enter Bio'
                        label='BIO'
                        id='sBio'
                        error={error.sBio}
                        type={'text'}
                        value={inputValue.sBio}
                        setInputValue={setInputValue}
                        changeHandler={(e) => {
                            setInputValue((prev) => {
                                return {
                                    ...prev,
                                    sBio: e.target.value,
                                };
                            });
                        }}
                        isBio={true}
                    />
                    {errorMessage.sBio && (
                        <label className='text-red-600'>
                            {errorMessage.sBio}
                        </label>
                    )}
                </div>
                <div className='mt-6'>
                    <button
                        className='btn-secondary w-full'
                        onClick={handleSubmit}
                    >
                        SAVE
                    </button>
                    <button
                        className='btn-border w-full mt-4'
                        onClick={() => setIsPopupOpen(false)}
                    >
                        CANCEL
                    </button>
                </div>
            </div>
        </Modal>
    );
}

export default EditProfilePopup;
