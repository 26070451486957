import { Listbox, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import Filter from '../../components/Filter/Filter';

// IMAGES
import { getCategory } from '../../api/adapters/asset-common';
import { getAllNfts } from '../../api/adapters/nft';
import ClearIcon from '../../assets/images/clear-icon.svg';
import CollapseIcon from '../../assets/images/collapse-arrow.svg';
import FilterIcon from '../../assets/images/filter-icon.svg';
import CardMedium from '../../components/CardMedium/CardMedium';
import ButtonLoader from '../../components/Loader/ButtonLoader';
import Loader from '../../components/Loader/Loader';
import NoDataFound from '../../components/NoDataFound/NoDataFound';
import { shortBy } from '../../constants/platform-constants';
import { toggleLoader } from '../../redux/actions/loader';

function AllNfts() {
    const [showFilter, setShowFilter] = useState(false);
    const [selectedShortBy, setSelectedShortBy] = useState({});
    const [filters, setFilters] = useState({
        status: 'All',
        blockChain: 'All',
        type: 'All',
        token: 'All',
        categories: [],
        price: {
            min: '',
            max: '',
        },
    });
    const [categoryList, setCategoryList] = useState([]);
    const [nftData, setNftData] = useState([]);
    const [pagination, setPagination] = useState({
        start: 0,
        length: 8,
    });
    const [isButtonClicked, setIsButtonClicked] = useState(false);

    const loader = useSelector((state) => state.loader);

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const status = ['Created', 'Owned', 'Liked'];

    const {
        data: nfts,
        error,
        refetch: refetchNFTs,
        isFetching,
    } = useQuery({
        queryKey: [
            'nfts',
            selectedShortBy.value,
            filters.categories.length,
            filters.status,
            filters.blockChain,
            filters.type,
            filters.token,
            pagination.start,
        ],
        queryFn: () =>
            getAllNfts({
                sSortType: selectedShortBy.value || '',
                sUserName: status.includes(filters.status)
                    ? user?.userName || undefined
                    : undefined,
                oFilters: {
                    ...filters,
                    categories: filters.categories.length
                        ? filters.categories
                        : categoryList,
                    // status: 'Fixed Sale',
                },
                start: pagination.start,
                length: pagination.length,
            }).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);

                const isFiltersDefault =
                    filters.status === 'All' &&
                    filters.blockChain === 'All' &&
                    filters.type === 'All' &&
                    filters.token === 'All' &&
                    !filters.categories.length &&
                    filters.price.min === '' &&
                    filters.price.max === '' &&
                    !selectedShortBy.value;

                const newData = [...res.data.data];

                if (isFiltersDefault && isButtonClicked) {
                    setNftData((prev) => [...prev, ...newData]);
                    setIsButtonClicked(false);
                } else {
                    setNftData(newData);
                }

                return res.data.data;
            }),
    });

    const { data: category } = useQuery({
        queryKey: ['category'],
        queryFn: () =>
            getCategory().then((res) => {
                res.data.data.forEach((element) => {
                    if (!categoryList.includes(element._id))
                        setCategoryList((prev) => [...prev, element._id]);
                });
                return res.data.data;
            }),
    });

    const handleClear = () => {
        setFilters({
            status: 'All',
            blockChain: 'All',
            type: 'All',
            categories: [],
            price: {
                min: '',
                max: '',
            },
        });
        setSelectedShortBy({});
    };

    const handlePagination = () => {
        setIsButtonClicked(true);

        setPagination((prev) => ({
            ...prev,
            start: prev.start + prev.length,
        }));
    };

    if (error) return;

    return (
        <section>
            <div className='container  section-space-small'>
                <div className='text-center'>
                    <h2 className='main-heading-h2 -mt-7 md:-mt-2'>NFTs</h2>
                </div>
                <div className='flex mt-9 items-start md:flex-wrap sm:mt-6'>
                    <div className='relative pt-16 z-10 md:w-full md:pt-0'>
                        <div className='flex gap-5 absolute top-0'>
                            <button
                                onClick={() => setShowFilter(!showFilter)}
                                className={`group flex-shrink-0  p-3.5 rounded duration-300 hover:bg-black xs:p-2.5 ${
                                    showFilter ? 'bg-black' : 'bg-dark-10'
                                }`}
                            >
                                <img
                                    src={FilterIcon}
                                    alt='FilterIcon'
                                    className={`duration-300 group-hover:invert ${
                                        showFilter ? 'invert' : ''
                                    }`}
                                />
                            </button>
                            <button
                                className='group flex-shrink-0 bg-dark-10 p-3.5 rounded duration-300 hover:bg-black xs:p-2.5'
                                onClick={handleClear}
                            >
                                <img
                                    src={ClearIcon}
                                    alt='ClearIcon'
                                    className='duration-300 group-hover:invert'
                                />
                            </button>
                        </div>
                        {showFilter && (
                            <Filter
                                category={category}
                                filters={filters}
                                setFilters={setFilters}
                                categoryIdList={categoryList}
                                refetch={refetchNFTs}
                            />
                        )}
                    </div>
                    {!nftData?.length && !loader && (
                        <div className='w-full mt-24 -mr-10 h-[430px] flex items-center justify-center sm:h-60'>
                            <NoDataFound />
                        </div>
                    )}
                    {loader && !nftData?.length && (
                        <div className='mt-20 w-full'>
                            <Loader />
                        </div>
                    )}
                    <div
                        className={`flex-1 relative grid grid-cols-4 gap-x-5 gap-y-10 pt-20 xl:grid-cols-3 md:grid-cols-2 sm:flex sm:flex-wrap sm:gap-y-7 xs:pt-16 ${
                            showFilter
                                ? '!grid-cols-3 lg:!grid-cols-2 sm:lg:!grid-cols-1'
                                : ''
                        }`}
                    >
                        <div className='absolute top-0 right-0 z-10 xs:relative xs:w-full'>
                            <Listbox
                                value={selectedShortBy}
                                onChange={setSelectedShortBy}
                            >
                                <div className='relative'>
                                    <Listbox.Button className='w-72 flex justify-between items-center relative cursor-pointer rounded-xl bg-dark-10 border border-dark-30 py-3.5 px-4 text-left text-sm font-bold text-dark-60 focus:outline-none xs:w-full aria-[expanded=true]:border-dark-70'>
                                        <span className='block truncate'>
                                            {selectedShortBy?.name
                                                ? selectedShortBy?.name
                                                : 'Sort by'}
                                        </span>
                                        <span className='pointer-events-none inset-y-0 pr-0'>
                                            <img
                                                src={CollapseIcon}
                                                alt='CollapseIcon'
                                                className='mr-0'
                                            ></img>
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                                        enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                                        enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                                        leave='transition ease-in duration-75'
                                        leaveFrom='transform opacity-100 translate-y-0'
                                        leaveTo='transform opacity-0 translate-y-2'
                                    >
                                        <Listbox.Options className='dropdown mt-1 w-full text-base focus:outline-none sm:text-sm'>
                                            {shortBy.map(
                                                (shortItem, shortItemIdx) => (
                                                    <Listbox.Option
                                                        key={shortItemIdx}
                                                        className={({
                                                            active,
                                                        }) =>
                                                            `relative text-base font-medium cursor-pointer py-3 pl-8 pr-4 hover:bg-primary/20 ${
                                                                active
                                                                    ? 'bg-primary/20'
                                                                    : ''
                                                            }`
                                                        }
                                                        value={shortItem}
                                                    >
                                                        {({ selected }) => (
                                                            <>
                                                                <span className='block truncate'>
                                                                    {
                                                                        shortItem.name
                                                                    }
                                                                </span>
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                )
                                            )}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        {nftData?.length
                            ? nftData.map((item, i) => {
                                  return (
                                      <div key={i} className='sm:w-full'>
                                          <CardMedium
                                              data={item}
                                              chainId={item?.nChainId}
                                          />
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
            {nfts?.length >= 8 && (
                <div className='w-full -mr-10 h-[100px] flex items-center justify-center sm:h-60'>
                    <button
                        className='btn-secondary'
                        onClick={handlePagination}
                    >
                        {isFetching && <ButtonLoader />} Load More
                    </button>
                </div>
            )}
        </section>
    );
}

export default AllNfts;
