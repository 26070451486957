import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCollections } from '../../api/adapters/collection';
import { toggleLoader } from '../../redux/actions/loader';

// COMPONENTS
import CardLarge from '../../components/CardLarge/CardLarge';
import Loader from '../../components/Loader/Loader';
import NoDataFound from '../../components/NoDataFound/NoDataFound';

function Collections() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loader = useSelector((state) => state.loader);

    const { data: collections, error } = useQuery({
        queryKey: ['collections'],
        queryFn: () =>
            getAllCollections({ start: 0, length: 1000 }).then((res) => {
                setTimeout(() => {
                    dispatch(toggleLoader(false));
                }, 300);
                return res.data.data;
            }),
    });
    if (error) return navigate('/404');
    // if (!collections) return navigate('/404');

    return (
        <section>
            <div className='container section-space-small'>
                <div className='text-center'>
                    <h2 className='main-heading-h2 -mt-7 md:-mt-2'>
                        Collections
                    </h2>
                </div>
                <div
                    className={`grid grid-cols-4 gap-x-5 gap-y-10 mt-14 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 md:mt-12 sm:mt-10 ${collections?.length ? '' : '!grid-cols-1'
                        }`}
                >
                    {collections?.length
                        ? collections.map((item, i) => {
                            return item.aNfts?.length ? (
                                <div key={i} className=''>
                                    <CardLarge
                                        data={item}
                                        redirect={`/collections-detail/${item._id}`}
                                    />
                                </div>
                            ) : null;
                        })
                        : !loader && <NoDataFound />}
                </div>
                {loader && !collections?.length && <Loader />}
            </div>
        </section>
    );
}

export default Collections;
