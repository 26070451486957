import React from 'react';

function TextArea(props) {
    const {
        label,
        placeholder,
        id,
        error,
        changeHandler,
        onBlur,
        isRequired,
        value,
    } = props;

    return (
        <div className=''>
            <label
                htmlFor={id}
                className='block text-sm font-semibold text-dark-60'
            >
                {label}
                {isRequired && <span className='text-red-600 ml-0.5'>*</span>}
            </label>
            <div className='mt-2'>
                <textarea
                    id={id}
                    name={id}
                    rows={4}
                    placeholder={placeholder}
                    maxLength={250}
                    onChange={changeHandler}
                    className={
                        'resize-none text-base font-medium block w-full rounded-xl bg-dark-10 border-1 border-dark-30 py-3.5 px-5 text-gray-900 placeholder:text-dark-60 focus:ring-0 focus:border-dark-70 sm:text-sm' +
                        `${error ? ' border-red-600' : ''}`
                    }
                    value={value}
                    onBlur={onBlur}
                />
            </div>
            {error && <span className='text-red-600'>{error}</span>}
        </div>
    );
}

export default TextArea;
