import React from 'react';

function QuantityControl(props) {
    const {
        quantity,
        darkBg,
        index,
        increaseQuantity,
        decreaseQuantity,
        editPending = false,
    } = props;

    return (
        <div
            className={`bg-dark-10 text-black rounded-xl flex items-center ${
                darkBg ? '!bg-black text-white' : ''
            }`}
        >
            <span
                className='text-xl font-bold  py-2 px-4 inline-block cursor-pointer'
                onClick={() => {
                    if (!editPending) decreaseQuantity(quantity - 1, index);
                }}
            >
                -
            </span>
            <span className='text-xl font-bold inline-block text-center min-w-[28px]'>
                {quantity}
            </span>
            <span
                className='text-xl font-bold py-2 px-4 inline-block cursor-pointer'
                onClick={() => {
                    if (!editPending) {
                        increaseQuantity(quantity + 1, index);
                    }
                }}
            >
                +
            </span>
        </div>
    );
}

export default QuantityControl;
