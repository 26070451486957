import { Dialog } from '@headlessui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import {
    useAccount,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';
import { hideNFT } from '../../api/adapters/nft';

// COMPONENTS
import Modal from './Modal';

// IMAGES
import CloseIcon from '../../assets/images/close-icon.svg';
import _contracts_web3 from '../../configs/contracts.web3';
import { notify } from '../../utils/common.helper';
import { parseRevertReason } from '../../utils/helper';
import ButtonLoader from '../Loader/ButtonLoader';

function RemoveFromSalePopup(props) {
    const {
        isPopupOpen,
        setIsPopupOpen,
        isHide,
        nftAddress,
        refetchNft,
        refetchActivity,
        nftChainId,
        nftId,
        method,
    } = props;

    const { address } = useAccount();
    const { chain } = useNetwork();
    const { status, switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            write({ args: [nftAddress, nftId] });
        },
    });

    const { data, write, isLoading } = useContractWrite({
        address: _contracts_web3.addresses[nftChainId]?.media,
        abi: _contracts_web3.abis.media,
        functionName: method,
        account: address,
        onError(error) {
            setIsPopupOpen(false);
            refetchNft();
            refetchActivity?.();
            console.log(error);
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const { isLoading: isLoadingTransaction, isSuccess: isSuccessTransaction } =
        useWaitForTransaction({
            hash: data?.hash,
        });

    useEffect(() => {
        if (isSuccessTransaction) {
            setIsPopupOpen(false);
            refetchNft();
            refetchActivity?.();
            notify('success', 'NFT removed from Sale successfully');
        }
    }, [isSuccessTransaction]);

    const { mutate: mutateHide } = useMutation({
        mutationFn: (id) => hideNFT(id, { bHideStatus: true }),
        onSuccess: (data) => {
            notify('success', 'NFT hidden successfully');
            setIsPopupOpen(false);
            refetchNft();
        },
        onError: (error) => {
            notify('error', error.data.message || 'Something went wrong!');
        },
    });

    const handleSubmit = async () => {
        if (nftChainId !== chain.id) {
            await switchNetwork(nftChainId);
        } else {
            write({ args: [nftAddress, nftId] });
        }
    };

    return (
        <>
            <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
                <div className='flex justify-between items-start'>
                    <Dialog.Title
                        as='h3'
                        className='text-3xl font-black font-darker text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2'
                    >
                        {isHide ? 'Hide NFT' : 'Remove From Sale'}
                    </Dialog.Title>
                    <button
                        className='focus-visible:outline-none'
                        onClick={() => setIsPopupOpen(false)}
                    >
                        <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                    </button>
                </div>
                <div className='mt-5'>
                    <p className='text-base text-dark-90'>
                        {isHide
                            ? 'Other users will not be able to view your NFT on the platform. Are you sure you want to hide the NFT?'
                            : 'Do you want to put this NFT to Not on Sale?'}
                    </p>
                </div>
                <div className='mt-10'>
                    {isHide ? (
                        <button
                            className='btn-secondary w-full'
                            onClick={(e) => {
                                e.preventDefault();
                                mutateHide(nftId);
                            }}
                        >
                            HIDE
                        </button>
                    ) : (
                        <button
                            className='btn-secondary w-full'
                            onClick={handleSubmit}
                            disabled={
                                isLoading || isLoadingTransaction ? true : false
                            }
                        >
                            {(isLoading || isLoadingTransaction) && (
                                <ButtonLoader />
                            )}
                            YES
                        </button>
                    )}
                    <button
                        className='btn-border w-full mt-4'
                        onClick={() => {
                            setIsPopupOpen(false);
                        }}
                    >
                        CANCEL
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default RemoveFromSalePopup;
