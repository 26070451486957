import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAccount } from "wagmi";

//Component
import FacebookChatPlugin from "../components/FacebookPlugin/FacebookPlugin";
import FooterMain from "../components/Footer/Footer";
import Header from "../components/Header/Header";

//Pages
import AboutUs from "../pages/about-us/AboutUs";
import AllBrands from "../pages/all-brands/AllBrands";
import AllNfts from "../pages/all-nfts/AllNfts";
import Asset from "../pages/asset/Asset";
import CollectionDetail from "../pages/collection-detail/CollectionDetail";
import Collections from "../pages/collections/Collections";
import ContactUs from "../pages/contact-us/ContactUs";
import CreateNFT from "../pages/create-nft/CreateNFT";
import NotFound from "../pages/error/404NotFound";
import Faqs from "../pages/faqs/Faqs";
import Home from "../pages/home/Home";
import ManageBid from "../pages/manage-bid/ManageBid";
import ManageCollaborators from "../pages/manage-collaborators/ManageCollaborators";
import MyCart from "../pages/my-cart/MyCart";
import MyNft from "../pages/my-nft/MyNft";
import NftDetail from "../pages/nft-detail/NftDetail";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import MyProfile from "../pages/profile/MyProfile";
import OtherProfile from "../pages/profile/OtherProfile";
import Stats from "../pages/stats/Stats";
import TermsConditions from "../pages/terms-conditions/TermsConditions";
import { toggleLoader } from "../redux/actions/loader";
import { getToken } from "../utils/cookies.helper";
import ArticlePage from "../pages/article-page/ArticlePage";

const ProtectedComponent = ({ component }) => {
  const { isConnected } = useAccount();
  return getToken() && isConnected ? component : <Navigate to="/" replace />;
};

const Routing = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname !== "/404") dispatch(toggleLoader(true));
  }, [location.pathname]);

  return (
    <div className="App antialiased">
      <Header />
      {/* {loader && <Loader />} */}
      <div className="pt-40 xl:pt-36 lg:pt-24 md:pt-20">
        {/* ROUTES */}
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/all-brands" Component={AllBrands} />
          <Route path="/collections" Component={Collections} />
          <Route path="/all-nfts" Component={AllNfts} />
          <Route path="/user/:username" Component={OtherProfile} />
          <Route path="/nft-detail" Component={NftDetail} />
          <Route path="/stats" Component={Stats} />
          {/* <Route path='/blog' Component={Blog} /> */}
          <Route path="/blog/:id" Component={ArticlePage} />
          <Route path="/nft/:id" Component={NftDetail} />
          <Route path="/about-us" Component={AboutUs} />
          <Route path="/privacy-policy" Component={PrivacyPolicy} />
          <Route path="/faqs" Component={Faqs} />
          <Route path="/contact-us" Component={ContactUs} />
          <Route path="/search" Component={Asset} />
          <Route path="/terms-conditions" Component={TermsConditions} />
          <Route path="/collections-detail/:id" Component={CollectionDetail} />

          {/* Protected Routes */}
          <Route
            path="/manage-bid"
            element={<ProtectedComponent component={<ManageBid />} />}
          />
          <Route
            path="/create-nft"
            element={<ProtectedComponent component={<CreateNFT />} />}
          />
          <Route
            path="/manage-collaborators"
            element={<ProtectedComponent component={<ManageCollaborators />} />}
          />
          <Route
            path="/my-cart"
            element={<ProtectedComponent component={<MyCart />} />}
          />
          <Route
            path="/my-profile"
            element={<ProtectedComponent component={<MyProfile />} />}
          />
          <Route
            path="/my-nft"
            element={<ProtectedComponent component={<MyNft />} />}
          />

          {/* Error Routes */}
          <Route path="/404" Component={NotFound} />
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </div>

      <FooterMain />
      {/* <Chat /> */}
      <FacebookChatPlugin />
    </div>
  );
};

export default Routing;
