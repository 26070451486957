import { apiClient } from '../client/api-client';

export const getPendingRoyalty = async (data) => {
    let url = `/user/royalty/get-pending`;
    return await apiClient({ url, method: 'POST', data });
};

export const getWithdrawnRoyalty = async (data) => {
    let url = `/user/royalty/get-withdrew`;
    return await apiClient({ url, method: 'POST', data });
};
