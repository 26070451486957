import { Dialog } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import {
    useAccount,
    useBalance,
    useContractRead,
    useContractWrite,
    useNetwork,
    useSwitchNetwork,
    useWaitForTransaction,
} from 'wagmi';
import { CONFIG } from '../../configs/platform.config';
import { notify } from '../../utils/common.helper';
import {
    chainSymbolById,
    formatNumber,
    parseRevertReason,
    toWei,
} from '../../utils/helper';

// COMPONENTS
import Input from '../FormControl/Input';
import ButtonLoader from '../Loader/ButtonLoader';
import Modal from './Modal';

// IMAGES
import CloseIcon from '../../assets/images/close-icon.svg';
import _contracts_web3 from '../../configs/contracts.web3';

function BidNowPopup(props) {
    const {
        isPopupOpen,
        setIsPopupOpen,
        currentOwner,
        nftAddress,
        nftId,
        nftChainId,
        nftName,
        nftPrice,
        nftImage,
        refetchNft,
        refetchActivity,
        refetchBidActivity,
        eType,
        sTokenSymbol,
        sTokenAddress,
    } = props;

    const [inputValue, setInputValue] = useState();
    const [error, setError] = useState();
    const [isEnabledBalance, setIsEnabledBalance] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isEnoughOmiBalance, setIsEnoughOmiBalance] = useState(false);

    const { address } = useAccount();
    const { data: balanceOfUser } = useBalance({
        address,
        chainId: nftChainId,
    });

    const { chain } = useNetwork();
    const { switchNetwork } = useSwitchNetwork({
        onSuccess: () => {
            handleBid();
        },
    });
    const modelRef = useRef();

    // Contract call bid on NFT
    const {
        data,
        write: bidWrite,
        isLoading,
    } = useContractWrite({
        address: _contracts_web3.addresses[nftChainId]?.media,
        abi: _contracts_web3.abis.media,
        functionName: 'bid',
        account: address,
        value:
            CONFIG.supportedTokens[0].value === sTokenAddress
                ? toWei(inputValue || 0, CONFIG.token[sTokenSymbol]?.decimals)
                : 0,
        onError(error) {
            setIsEnoughOmiBalance(false);
            setIsApproved(false);
            setIsEnabledBalance(false);
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const { isLoading: isBidLoading, isSuccess: isBidSuccess } =
        useWaitForTransaction({
            hash: data?.hash,
        });

    useEffect(() => {
        if (isBidSuccess) {
            setIsPopupOpen(false);
            setInputValue();
            refetchNft?.();
            refetchActivity?.();
            refetchBidActivity?.();
            notify('success', 'Bid Placed successfully');
        }
    }, [isBidSuccess]);

    // Check if ERC20 token is approved for Bid NFT on marketplace
    const { isLoading: isGetApproveLoading, isFetching } = useContractRead({
        address: sTokenAddress,
        abi: _contracts_web3.abis.ERC20,
        functionName: 'allowance',
        enabled: isEnoughOmiBalance,
        chainId: nftChainId,
        args: [address, _contracts_web3.addresses[nftChainId]?.media],
        onSuccess(data) {
            if (data >= toWei(nftPrice, CONFIG.token[sTokenSymbol]?.decimals)) {
                handleBid();
            } else {
                approveWrite();
            }

            setIsEnabledBalance(false);
        },
        onError(error) {
            setIsEnabledBalance(false);
            setIsApproved(false);
            setIsEnoughOmiBalance(false);
        },
    });

    // Check ERC20 Token Balance
    const { isLoading: fetchBalanceLoading, isFetching: balanceFetching } =
        useContractRead({
            address: sTokenAddress,
            abi: _contracts_web3.abis.ERC20,
            functionName: 'balanceOf',
            enabled: isEnabledBalance,
            chainId: nftChainId,
            args: [address],
            onSuccess(data) {
                if (
                    data >=
                    toWei(nftPrice, CONFIG.token[sTokenSymbol]?.decimals)
                ) {
                    setIsEnoughOmiBalance(true);
                } else {
                    setIsEnoughOmiBalance(false);
                    setIsApproved(false);
                    setIsEnabledBalance(false);

                    return notify(
                        'error',
                        `Not enough ${sTokenSymbol} token to buy this NFT`
                    );
                }
            },
            onError(error) {
                setIsEnabledBalance(false);
                setIsApproved(false);
                setIsEnoughOmiBalance(false);
            },
        });

    // Approve ERC20 token to Bid for NFT on marketplace
    const {
        data: approveData,
        write: approveWrite,
        isLoading: isApproveLoading,
    } = useContractWrite({
        address: sTokenAddress,
        abi: _contracts_web3.abis.ERC20,
        functionName: 'approve',
        account: address,
        chainId: nftChainId,
        args: [
            _contracts_web3.addresses[nftChainId]?.media,
            toWei(nftPrice, CONFIG.token[sTokenSymbol]?.decimals),
        ],
        onError(error) {
            setIsEnabledBalance(false);
            setIsApproved(false);
            setIsEnoughOmiBalance(false);
            notify('error', parseRevertReason(error.shortMessage));
        },
    });

    const {
        isLoading: isApproveTransactionLoading,
        isSuccess: isApproveTransactionSuccess,
    } = useWaitForTransaction({
        hash: approveData?.hash,
    });

    useEffect(() => {
        if (isApproveTransactionSuccess) {
            handleBid();
        }
    }, [isApproveTransactionSuccess]);

    const validate = () => {
        let isValid = true;
        if (!inputValue) {
            isValid = false;
            setError('Please enter price');
        } else if (inputValue <= nftPrice) {
            isValid = false;
            setError('Bid price should be greater than minimum bid price');
        } else {
            setError('');
        }
        return isValid;
    };

    const handleSubmit = async () => {
        if (validate()) {
            if (chain.id !== nftChainId) {
                await switchNetwork(nftChainId);
            } else if (sTokenAddress === CONFIG.supportedTokens[0].value) {
                handleBid();
            } else setIsEnabledBalance(true);
        }
    };

    const handleBid = async () => {
        if (inputValue > balanceOfUser.formatted)
            return setError('Not enough balance to bid on this NFT!');
        bidWrite({
            args: [
                nftAddress,
                nftId,
                currentOwner,
                toWei(inputValue, CONFIG.token[sTokenSymbol]?.decimals),
            ],
        });
    };

    return (
        <Modal isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen}>
            <div className='flex justify-between items-start'>
                <Dialog.Title
                    as='h3'
                    className='text-3xl font-black font-darker text-black -mt-5 lg:text-2.5xl sm:text-2xl sm:-mt-2'
                >
                    Place Your Bid
                </Dialog.Title>
                <button
                    className='focus-visible:outline-none'
                    onClick={() => setIsPopupOpen(false)}
                >
                    <img src={CloseIcon} alt='CloseIcon' className='w-7' />
                </button>
            </div>
            <div className='mt-10'>
                <div className='flex items-center gap-4 border-b border-dark-20 pb-6 xs:flex-wrap'>
                    <div className='relative w-24 h-24 rounded-2xl overflow-hidden sm:w-16 sm:h-16'>
                        {eType === 'image' ? (
                            <img
                                src={`${nftImage}`}
                                alt='NftDetailImg'
                                className='absolute left-0 top-0 w-full h-full object-cover object-center'
                            />
                        ) : eType === 'model' ? (
                            <model-viewer
                                alt={nftName}
                                ar
                                src={`${nftImage}`}
                                poster={`${nftImage}`}
                                shadow-intensity='1'
                                ref={(ref) => {
                                    modelRef.current = ref;
                                }}
                                touch-action='pan-y'
                                class='absolute w-full h-full left-0 top-0 object-cover object-center'
                                autoplay
                            ></model-viewer>
                        ) : eType === 'video' ? (
                            <video
                                src={`${nftImage}`}
                                className='absolute left-0 top-0 object-cover object-center h-full'
                            />
                        ) : null}
                    </div>
                    <div className='flex-1'>
                        <h5 className='text-2xl font-bold text-black sm:text-lg'>
                            {nftName}
                        </h5>
                        <h6 className='text-xl text-dark-70 font-bold mt-2 sm:text-md'>
                            Minimum Bid:{' '}
                            <span className='text-black'>
                                {formatNumber(nftPrice)}{' '}
                                {sTokenSymbol || chainSymbolById(nftChainId)}
                            </span>
                        </h6>
                    </div>
                </div>
                <div className='flex justify-between items-center py-6'>
                    <h5 className='text-2xl font-bold text-black sm:text-lg'>
                        Place Your Bid
                    </h5>
                    {/* <span className='text-xl font-bold text-black bg-dark-10 rounded-xl py-1.5 px-3 sm:text-lg'>
                        0.297 ETH
                    </span> */}
                    <Input
                        placeholder='Enter Bid Amount'
                        id='price'
                        changeHandler={(e) => {
                            if (e.target.value >= 0)
                                setInputValue(e.target.value);
                        }}
                        type={'number'}
                        value={inputValue}
                        error={error}
                        textLimit={20}
                    />
                </div>
                <button
                    className='btn-secondary w-full'
                    onClick={handleSubmit}
                    disabled={
                        isLoading ||
                        isGetApproveLoading ||
                        isApproveLoading ||
                        isApproveTransactionLoading ||
                        isFetching ||
                        balanceFetching ||
                        fetchBalanceLoading
                    }
                >
                    {(isLoading ||
                        isBidLoading ||
                        isGetApproveLoading ||
                        isApproveLoading ||
                        isApproveTransactionLoading ||
                        isFetching ||
                        balanceFetching ||
                        fetchBalanceLoading) && <ButtonLoader />}
                    BID NOW
                </button>
            </div>
        </Modal>
    );
}

export default BidNowPopup;
