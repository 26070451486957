import { TOGGLE_LOADER } from '../../constants/redux-events';

const initialState = true;

const loader = (state = initialState, { type, payload }) => {
    switch (type) {
        case TOGGLE_LOADER:
            return payload;
        default:
            return state;
    }
};

export default loader;
