export const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerPadding: '60px',
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1399,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

export const smallCardSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    centerPadding: '60px',
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 1399,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            },  
        },
    ],
};
