import { apiClient } from '../client/api-client';

export const getNotification = async () => {
    let url = `/user/notification/get`;
    return await apiClient({ url, method: 'GET' });
};
export const markNotificationAsRead = async () => {
    let url = `/user/notification/mark-as-read`;
    return await apiClient({ url, method: 'PATCH' });
};
