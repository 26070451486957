import { combineReducers } from '@reduxjs/toolkit';

import cart from './cart';
import loader from './loader';
import price from './price';
import user from './user';

const rootReducer = combineReducers({
    loader,
    user,
    cart,
    price,
});

export default rootReducer;
