export const errorMessage = {
    nftImage: 'Please select supported NFT art',
    nftTitle: 'Please enter NFT title',
    brand: 'Please select brand',
    category: 'Please select category',
    collection: 'Please select collection',
    description: 'Please enter description',
    quantity: 'Please enter valid quantity',
    royalty: 'Please enter royalty',
    blockChian: 'Please select blockchain',
    propertyTitle: 'Please enter property title',
    propertyValue: 'Please enter property value',
    collaboratorName: 'Please select collaborator name',
    collaboratorValue: 'Please select collaborator royalty',
};
