import { apiClient } from '../client/api-client';

export const getNfts = async (data) => {
    let url = `/user/get-user-nfts`;
    return await apiClient({ url, method: 'POST', data });
};

export const getAllNfts = async (data) => {
    let url = `/user/get-all-nfts`;
    return await apiClient({ url, method: 'POST', data });
};

export const getMyNfts = async (data) => {
    let url = `/user/get-my-nfts`;
    return await apiClient({ url, method: 'POST', data });
};

export const getNFTDetails = async (id) => {
    let url = `/user/nft/${id}`;
    return await apiClient({ url, method: 'GET' });
};

export const getNFTActivity = async (id) => {
    let url = `/user/get-nft-activity/${id}`;
    return await apiClient({ url, method: 'GET' });
};
export const getBidActivity = async (id) => {
    let url = `/user/get-bid-activities/${id}`;
    return await apiClient({ url, method: 'GET' });
};

export const likeNft = async (id) => {
    let url = `/user/like/${id}`;
    return await apiClient({ url, method: 'POST' });
};

export const unLikeNft = async (id) => {
    let url = `/user/unlike/${id}`;
    return await apiClient({ url, method: 'POST' });
};

export const getCategoryNFTs = async (id, data) => {
    let url = `user/category/${id}/get-nfts`;
    return await apiClient({ url, method: 'POST', data });
};

export const hideNFT = async (id, data) => {
    let url = `/user/nft/${id}/toggle-hide-status`;
    return await apiClient({ url, method: 'PATCH', data });
};

export const getHiddenNFTs = async (data) => {
    let url = `/user/nft/get-hidden`;
    return await apiClient({ url, method: 'POST', data });
};

export const getUserActivity = async (data) => {
    let url = `/user/get-user-activities`;
    return await apiClient({ url, method: 'POST', data });
};
// user/get-user-activities
