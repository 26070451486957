import React from 'react';
import { Link } from 'react-router-dom';

// IMAGES
import ErrorIcon from '../../assets/images/404-error-icon.svg';

const SomeThingWentWrong = () => {
    return (
        <div className='section-space-small'>
            <div className='container text-center'>
                <div className='flex items-center justify-center text-6xl font-black'>
                    <img src={ErrorIcon} alt='404' className='w-20' />
                    <img src={ErrorIcon} alt='404' className='w-20' />
                    PS!
                </div>
                <div>
                    <h2 className='text-3xl py-2 font-black'>
                        Something Went Wrong
                    </h2>
                    <p className='text-md my-3'>please try again later!</p>
                    <Link
                        to='/'
                        className='btn-primary uppercase'
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.replace('/');
                        }}
                    >
                        Go to Home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default SomeThingWentWrong;
