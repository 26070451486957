import { apiClient } from '../client/api-client';

export const addToCart = async (data) => {
    let url = `/user/add-to-cart`;
    return await apiClient({ url, method: 'POST', data });
};

export const getCart = async () => {
    let url = `/user/get-my-cart`;
    return await apiClient({ url, method: 'GET' });
};

export const removeFromCart = async (id) => {
    let url = `/user/delete-cart/${id}`;
    return await apiClient({ url, method: 'DELETE' });
};

export const editCart = async (data) => {
    let url = `/user/edit-cart`;
    return await apiClient({ url, method: 'PATCH', data });
};
