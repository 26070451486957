import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef } from 'react';

import CloseIcon from '../../assets/images/close-icon.svg';

function VideoModel(props) {
    const { isPopupOpen, setIsPopupOpen, videoUrl } = props;
    const videoRef = useRef(null);

    const handleContextMenu = (e) => {
        e.preventDefault(); // Prevent the default context menu
        // Implement your custom context menu here
    };

    return (
        <Transition appear show={isPopupOpen} as={Fragment}>
            <Dialog as='div' className='relative z-20' onClose={setIsPopupOpen}>
                <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                >
                    <div className='fixed inset-0 bg-black bg-opacity-80' />
                </Transition.Child>

                <div className='fixed inset-0 overflow-y-auto'>
                    <div className='flex min-h-full items-center justify-center p-4 text-center'>
                        <Transition.Child
                            as={Fragment}
                            enter='ease-out duration-300'
                            enterFrom='opacity-0 scale-95'
                            enterTo='opacity-100 scale-100'
                            leave='ease-in duration-200'
                            leaveFrom='opacity-100 scale-100'
                            leaveTo='opacity-0 scale-95'
                        >
                            <Dialog.Panel className='w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                                <button
                                    className='focus-visible:outline-none absolute right-4 top-4 z-20'
                                    onClick={() => setIsPopupOpen(false)}
                                >
                                    <img
                                        src={CloseIcon}
                                        alt='CloseIcon'
                                        className='w-7 invert'
                                    />
                                </button>
                                <div onContextMenu={handleContextMenu}>
                                    <video
                                        width='320'
                                        height='240'
                                        className='w-full custom-video'
                                        controls
                                        autoPlay
                                        ref={videoRef}
                                    >
                                        <source
                                            src={videoUrl}
                                            type='video/mp4'
                                        />
                                    </video>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}

export default VideoModel;
