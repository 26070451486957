import React from 'react';

import NoDataFoundIcon from '../../assets/images/no-data-found-icon.svg';

const NoDataFound = () => {
    return (
        <div className='w-full text-center min-h-[450px] flex items-center justify-center md:min-h-[150px]'>
            <div>
                <div className='mx-auto inline-block'>
                    <img
                        src={NoDataFoundIcon}
                        className='w-40'
                        alt='NoDataFound'
                    />
                </div>
                <div className='text-xl font-bold'>No Data Found</div>
            </div>
        </div>
    );
};

export default NoDataFound;
