import React from 'react';

// IMAGES
import { Link } from 'react-router-dom';
import Arrow from '../../assets/images/arrow.svg';
import PolygonBg from '../../assets/images/polygon-bg.svg';

function CardSmall(props) {
    const { data, index } = props;
    return (
        <div className='group relative pt-[100%] duration-300 hover:border-dark-20'>
            <Link
                to={`/user/${data?.user?.[0]?.sUserName || data?.sUserName}`}
                className='absolute top-0 left-0 w-full h-full z-10'
            ></Link>
            <div className='absolute w-full h-full rounded-xl overflow-hidden  top-0 duration-300 group-hover:-top-2.5'>
                <div className='rounded '>
                    {data?.user?.[0]?.sProfilePicUrl || data?.sProfilePicUrl ? (
                        <img
                            src={
                                data?.user?.[0]?.sProfilePicUrl ||
                                data?.sProfilePicUrl
                            }
                            onError={(e) => {
                                e.target.onerror = null; // Prevents infinite loop in case of continuous errors
                                e.target.style.display = 'block'; // Adjust as needed
                                e.target.src =
                                    'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='; // Transparent GIF
                            }}
                            alt={data?.user?.[0]?.sUserName || data?.sUserName}
                            className='rounded-xl absolute top-0 left-0 w-full h-full object-cover object-center'
                        />
                    ) : (
                        <div className='rounded-xl absolute top-0 left-0 w-full h-full object-cover object-center bg-gradient'></div>
                    )}
                </div>
                {index && (
                    <div
                        className='w-11 h-11 absolute top-5 left-5 flex items-center justify-center bg-[image:var(--image-url)] bg-no-repeat text-sm font-bold text-white'
                        style={{ '--image-url': `url(${PolygonBg})` }}
                    >
                        {`#${index}`}
                    </div>
                )}
                <div className='absolute bottom-0 left-0 p-4 w-full flex justify-between items-center bg-black/40 backdrop-blur-md'>
                    <div>
                        <h6 className='text-base font-bold uppercase text-white'>
                            {data?.user?.[0]?.sUserName || data?.sUserName}
                        </h6>
                        {data?.user && (
                            <p className='text-sm text-white pt-1'>
                                Volume: {data?.volume.toFixed(5)}
                            </p>
                        )}
                    </div>
                    <div>
                        <img src={Arrow} alt='Arrow' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardSmall;
