import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';

// IMAGES
import CollapseIcon from '../../assets/images/collapse-arrow.svg';

function SelectDropdown(props) {
    const {
        label,
        setInputValue,
        selectedOption,
        id,
        selectOption,
        placeholder,
        error,
        inputValue,
        index,
        isRequired,
        isCreateCollection,
        setIsPopupOpen,
    } = props;
    return (
        <div className=''>
            <label
                htmlFor={id}
                className='block text-sm font-semibold text-dark-60'
            >
                {label}
                {isRequired && <span className='text-red-600 ml-0.5'>*</span>}
            </label>
            <div className='mt-2'>
                <Listbox
                    defaultValue={selectedOption}
                    name={id}
                    onChange={(e) => {
                        if (id === 'collaboratorName') {
                            if (
                                !Object.values(
                                    inputValue.collaboratorName
                                ).includes(e)
                            ) {
                                const arr = inputValue.collaboratorName;
                                arr[index] = e;
                                setInputValue({
                                    ...inputValue,
                                    collaboratorName: arr,
                                });
                            }
                        } else {
                            setInputValue((prev) => ({
                                ...prev,
                                [id]: e,
                            }));
                        }
                    }}
                    // onBlur={onBlur}
                >
                    <div className='relative'>
                        <Listbox.Button
                            className={
                                'w-full flex justify-between items-center relative cursor-pointer rounded-xl bg-dark-10 border border-dark-30 py-3.5 px-4 text-left text-sm font-bold text-dark-60 focus:outline-none xs:w-full aria-[expanded=true]:border-dark-70' +
                                `${error ? ' border-red-600' : ''}`
                            }
                        >
                            <span className='block truncate'>
                                {selectedOption ? (
                                    <span className='text-black'>
                                        {selectedOption}
                                    </span>
                                ) : (
                                    placeholder
                                )}
                            </span>
                            <span className='pointer-events-none inset-y-0 pr-0'>
                                <img
                                    src={CollapseIcon}
                                    alt='CollapseIcon'
                                    className='mr-0'
                                ></img>
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            enter='transition ease-out duration-100 group-[.mobile-active]:h-0'
                            enterFrom='transform opacity-0 translate-y-2 group-[.mobile-active]:h-0'
                            enterTo='transform opacity-100 translate-y-0  group-[.mobile-active]:h-auto'
                            leave='transition ease-in duration-75'
                            leaveFrom='transform opacity-100 translate-y-0'
                            leaveTo='transform opacity-0 translate-y-2'
                        >
                            <Listbox.Options className='dropdown mt-1 w-full text-base max-h-48 overflow-auto z-10 focus:outline-none sm:text-sm'>
                                {isCreateCollection && !selectOption?.length ? (
                                    <Listbox.Option
                                        className={({ active }) =>
                                            `relative text-base font-medium cursor-pointer py-3 pl-8 pr-4 hover:bg-primary/20 ${
                                                active ? 'bg-primary/20' : ''
                                            }`
                                        }
                                        onClick={() => {
                                            setIsPopupOpen?.(true);
                                        }}
                                    >
                                        {({ selected }) => (
                                            <>
                                                <span className='block truncate'>
                                                    Create Collection
                                                </span>
                                            </>
                                        )}
                                    </Listbox.Option>
                                ) : (
                                    selectOption?.map((option, optionIdx) => (
                                        <Listbox.Option
                                            key={optionIdx}
                                            className={({ active }) =>
                                                `relative text-base font-medium cursor-pointer py-3 pl-8 pr-4 hover:bg-primary/20 ${
                                                    active
                                                        ? 'bg-primary/20'
                                                        : ''
                                                }`
                                            }
                                            value={optionIdx}
                                        >
                                            {({ selected }) => (
                                                <>
                                                    <span className='block truncate'>
                                                        {option.sName}
                                                    </span>
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))
                                )}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </Listbox>
            </div>
            {error && <span className='text-red-600'>{error}</span>}
        </div>
    );
}

export default SelectDropdown;
