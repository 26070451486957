import React, { useEffect, useState } from 'react';
import TimerIcon from '../../assets/images/timer-icon.svg';

function Timer({ epochTime, setStopTimer }) {
    const [remainingTime, setRemainingTime] = useState(0);

    useEffect(() => {
        // Update the timer every second
        const interval = setInterval(() => {
            const currentTime = Math.floor(Date.now() / 1000);
            const timeLeft = epochTime - currentTime;

            if (timeLeft >= 0) {
                setRemainingTime(timeLeft);
            } else {
                setStopTimer && setStopTimer(true);
                clearInterval(interval);
            }
        }, 1000);

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, [epochTime]);

    // Format the remaining time
    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600)
            .toString()
            .padStart(2, '0');
        const minutes = Math.floor((seconds % 3600) / 60)
            .toString()
            .padStart(2, '0');
        const remainingSeconds = (seconds % 60).toString().padStart(2, '0');

        return `${hours}h:${minutes}m:${remainingSeconds}s`;
    };

    return remainingTime ? (
        <div className='absolute left-6 top-6 flex items-center text-lg font-bold text-white rounded-lg bg-black/60 backdrop-blur-md py-1.5 px-3 lg:text-sm'>
            <img
                src={TimerIcon}
                alt='TimerIcon'
                className='w-7 h-7 mr-1.5 sm:w-5'
            />
            {formatTime(remainingTime)}
        </div>
    ) : null;
}

export default Timer;
