/* eslint-disable no-unreachable */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import { MDY } from "../../utils/helper";
import CreateSellNfts from "../../components/CreateSellNfts/CreateSellNfts";
import MoreBlogs from "../../components/BlogComponents/MoreBlogs";

const ArticlePage = () => {
  const [articleInfo, setArticleInfo] = useState();
  const { id } = useParams();

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        `https://whitelistapi.epiko.io/api/v1/blog/article/${id}`
      );
      const details = response?.data?.data;

      console.log("server-details", details);

      if (details) {
        setArticleInfo(details);
      }
    } catch (error) {
      console.log("blog error", error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [id]);

  return (
    <div>
      <div className="w-8/12 xs:sm:w-full md:w-full container max-w-7xl mx-auto pt-7 overflow-hidden">
        <div className="flex items-center justify-center py-7">
          <p className="main-heading-h2">Blogs</p>
        </div>
        <div className="py-5 flex flex-col gap-10">
          <div className="flex flex-col items-start gap-4">
            <p className="text-2xl xs:sm:text-xl font-sans font-semibold">
              {articleInfo?.sTitle}
            </p>
            <p className="text-gray text-base">{MDY(articleInfo?.createdAt)}</p>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={articleInfo?.sImage}
              alt={articleInfo?.sTitle}
              className="w-full rounded-xl"
            />
          </div>
          <div className="w-full flex flex-col font-outfit gap-10 pt-14 md:pt-7 xs:sm:pt-0 font-change-css overflow-hidden flex-wrap">
            <ReactQuill
              value={articleInfo?.sBody}
              readOnly
              theme="bubble"
              className="pt-10 xs:sm:pt-0 md:pt-0 w-full bubble"
            />
          </div>
        </div>
      </div>
      <MoreBlogs />
      <CreateSellNfts />
    </div>
  );
};

export default ArticlePage;
