import React, { useRef, useState } from 'react';

function OTPInput({ length = 4, onComplete, setOTPValue }) {
    const [otp, setOTP] = useState(['', '', '', '']);
    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    const handleInputChange = (e, index) => {
        const newValue = e.target.value;

        if (!isNaN(newValue) && newValue.length === 1) {
            const newOTP = [...otp];
            newOTP[index] = newValue;
            setOTP(newOTP);

            if (index < length - 1) {
                inputRefs[index + 1].current.focus();
            } else if (newOTP.every((digit) => digit !== '')) {
                // Call a validation function here if needed.
                // If validation passes, then you can call onComplete.
                onComplete(newOTP.join(''));
            }
        } else if (newValue === '' && index > 0) {
            // Allow deletion of the current digit by Backspace key
            const newOTP = [...otp];
            newOTP[index] = ''; // Clear the current digit
            setOTP(newOTP);
            setOTPValue(newOTP);
            inputRefs[index - 1].current.focus();
        } else if (newValue === '' && index === 0 && e.key === 'Backspace') {
            // Handle Backspace key on the first input to clear and keep focus on it
            const newOTP = [...otp];
            newOTP[index] = '';
            setOTP(newOTP);
        } else if (index > 0 && e.key === 'Backspace') {
        } else {
            const newOTP = [...otp];
            newOTP[index] = '';
            setOTP(newOTP);
            setOTPValue(newOTP);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedOTP = e.clipboardData
            .getData('text/plain')
            .slice(0, length);

        if (pastedOTP.length === length && !isNaN(pastedOTP)) {
            const newOTP = pastedOTP.split('');
            setOTP(newOTP);

            for (let i = 0; i < length; i++) {
                inputRefs[i].current.value = newOTP[i];
            }

            // Call onComplete with the pasted OTP
            onComplete(newOTP.join(''));
        }
    };

    return (
        <div className='otp-field flex gap-5'>
            {otp.map((value, index) => (
                <input
                    key={index}
                    type='number'
                    maxLength='1'
                    className='border-0 border-b-2 !border-b-dark-60 focus:ring-0 w-full text-center text-dark-90'
                    value={value}
                    onChange={(e) => handleInputChange(e, index)}
                    ref={inputRefs[index]}
                    onPaste={handlePaste}
                />
            ))}
        </div>
    );
}

export default OTPInput;
