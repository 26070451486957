import React from 'react';

import EpikoIcon from '../../assets/images/epiko-icon.svg';

function Loader() {
    return (
        // <div className='fixed w-full h-full z-40 top-0 left-0 flex items-center justify-center bg-black/80 backdrop-blur-md'>
        <div className='w-full h-full z-10  flex items-center justify-center'>
            <div className='spinner-box relative'>
                <div className='configure-border-1'>
                    <div className='configure-core'></div>
                </div>
                <div className='configure-border-2'>
                    <div className='configure-core'></div>
                </div>
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-5xl font-medium text-white'>
                    <img src={EpikoIcon} alt='EpikoIcon' className='w-14' />
                </div>
            </div>
        </div>
    );
}

export default Loader;
